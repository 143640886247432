import http from "./httpService";
import config from "./config.json";

export function GetUsersAddedToDepartment(departmentId) {
  return {
    url: `${config.apiUrl}/DepartmentAdmin/GetUsersAddedToDepartment/${departmentId}`,
    method: "GET",
  };
}

export function GetUsersForAllDepartments() {
  return {
    url: `${config.apiUrl}/DepartmentAdmin/GetUsersForAllDepartments/`,
    method: "GET",
  };
}

export function GetUsersNotAddedToDepartment(departmentId) {
  return {
    url: `${config.apiUrl}/DepartmentAdmin/GetUsersNotAddedToDepartment/${departmentId}`,
    method: "GET",
  };
}

export function UpdateUsersInDepartment() {
  return {
    method: "POST",
    url: `${config.apiUrl}/DepartmentAdmin/UpdateUsersInDepartment`,
  };
}

export function getAllEmployees() {
  return http.get(`${config.apiUrl}/DepartmentAdmin/GetAllEmployees`);
}

export function GetAllEmployeeRoles() {
  return { url: `${config.apiUrl}/UserAdmin/GetAllEmployeeRoles`, method: "GET" };
}

export function InviteEmployee() {
  return { url: `${config.apiUrl}/CreateUser/InviteEmployee`, method: "POST" };
}

export function IsEmailAvailable(email) {
  return { url: `${config.apiUrl}/CreateUser/IsEmailAvailable/${email}`, method: "POST" };
}

export function IsUserNameAvailable(username) {
  return {
    url: `${config.apiUrl}/CreateUser/IsUsernameAvailable/${username}`,
    method: "POST",
  };
}
export function GetAdminsUserLibrary() {
  return {
    url: `${config.apiUrl}/UserAdmin/GetAdminsUserLibrary`,
    method: "GET",
  };
}

export function AdminResetPassword() {
  return { url: `${config.apiUrl}/UserAdmin/AdminResetPassword`, method: "POST" };
}

export function getDepartmentsNotAddedToUser(userId) {
  return http.get(`${config.apiUrl}/UserAdmin/GetDepartmentsNotAddedToUser/${userId}`);
}

export function GetAllDepartmentsForCustomer() {
  return {
    url: `${config.apiUrl}/Department/GetAllDepartmentsForCustomer`,
    method: "GET",
  };
}

export function UpdateDepartmentAccessForUser() {
  return { url: `${config.apiUrl}/UserAdmin/UpdateDepartmentAccessForUser`, method: "POST" };
}

export function UpdateUserRoles() {
  return { url: `${config.apiUrl}/UserAdmin/UpdateUserRoles`, method: "POST" };
}

export function DisableUser(userId) {
  return { url: `${config.apiUrl}/UserAdmin/DisableUser/${userId}`, method: "POST" };
}

export function ReEnableUser(userId) {
  return { url: `${config.apiUrl}/UserAdmin/ReEnableUser/${userId}`, method: "POST" };
}
