import React from "react";
import { Dropdown, Typography, Button } from "antd";
import { DownOutlined, EllipsisOutlined, CloseOutlined } from "@ant-design/icons";

const MainCardHeader = (props) => {
  const {
    setIsMinimized,
    isMinimized,
    title,
    description,
    minimizable = null,
    headerColor,
    underline = true,
    icon,
    onClose = null,
    buttonMenuIconAdd = null,
    buttonMenu = null,
    addLeft,
    addRight,
  } = props;

  const closeable = typeof onClose === "function" ? true : false;
  const buttonMenuIsVisible = buttonMenu || buttonMenuIconAdd || onClose ? true : false;
  const leftSideIsThere = !!addLeft || title || description || !!icon;
  const rightSideIsThere = !!addRight;
  const headerIsThere = rightSideIsThere || leftSideIsThere;

  const handleMinimized = () => {
    if (minimizable) {
      setIsMinimized(!isMinimized);
    }
  };

  const getEffectiveIcon = (iconStyle = {}) => {
    if (!icon && !minimizable) return <div style={{ width: 24 }} />; //set to 40 if you want vertical alignment

    const effectiveIcon = icon ? icon : <DownOutlined />;

    return (
      <div
        onClick={minimizable ? handleMinimized : null}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 54,
          width: 40,
          fontSize: 18,
        }}
      >
        {effectiveIcon}
      </div>
    );
  };

  const effectiveButtonMenu = (
    <div>
      {buttonMenuIconAdd}
      {buttonMenu && (
        <Dropdown position="bottomRight" overlay={buttonMenu} trigger={["click"]}>
          <Button type="text">
            <EllipsisOutlined />
          </Button>
        </Dropdown>
      )}
      {closeable && (
        <Button type="text" onClick={onClose}>
          <CloseOutlined />
        </Button>
      )}
    </div>
  );

  return (
    <div
      style={{
        position: "relative",
        borderRadius: "4px 4px 0 0",
        borderBottom: underline && headerIsThere ? "1px solid lightgrey" : null,
        backgroundColor: headerColor,
      }}
    >
      {headerIsThere && (
        <div
          style={{
            minHeight: 54,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                alignItems: description ? null : "center",
                height: "100%",
              }}
            >
              <div style={{ flexShrink: 0 }}>
                {getEffectiveIcon({
                  fontSize: description ? 24 : 20,
                  padding: description ? "16px 12px 6px 12px" : "10px 6px 10px 12px",
                })}
              </div>
              <div
                style={{
                  textAlign: "left",
                  lineHeight: "20px",
                  marginTop: description ? 14 : 0,
                }}
                onClick={minimizable ? handleMinimized : null}
                className={minimizable ? "clickable" : ""}
              >
                {title && (
                  <Typography.Text
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    {title}
                  </Typography.Text>
                )}
                {description && (
                  <Typography.Paragraph type="secondary">{description}</Typography.Paragraph>
                )}
              </div>

              {leftSideIsThere && (
                <div
                  style={{
                    marginTop: description ? 24 : "inherit",
                    marginLeft: 12,
                  }}
                >
                  {addLeft}
                </div>
              )}
            </div>
          </div>

          <div
            style={{
              margin: `${buttonMenuIsVisible ? "28px" : "6px"} 12px 6px 12px`,
              alignSelf: "flex-end",
            }}
          >
            {addRight}
          </div>
        </div>
      )}
      <div style={{ position: "absolute", top: 10, right: 6, zIndex: 10 }}>
        {effectiveButtonMenu}
      </div>
    </div>
  );
};

export default MainCardHeader;
