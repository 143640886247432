import React, { useContext, useState, useEffect } from "react";
import { Route } from "react-router-dom";
import http from "./../../services/httpService";
import { AuthContext } from "../../contexts/authContext";
import Loadscreen from "./../common/layout/defaultPages/loadscreen";

export const PrivateRoute = ({ component, ...rest }) => {
  const userService = useContext(AuthContext);
  const [authState, setAuthState] = useState({ ready: false, authenticated: false });
  const [subscriptionId, setSubscriptionId] = useState(false);

  useEffect(() => {
    populateAuthState();
    const newSubId = userService.subscribe(() => populateAuthState());
    setSubscriptionId(newSubId);
    return () => userService.unsubscribe(subscriptionId);
  }, []);

  //sets the headers and checks authentication
  const populateAuthState = async () => {
    const promises = Promise.all([userService.isAuthenticated(), userService.getAccessToken()]);
    const answers = await promises;
    if (answers[1]) {
      http.setAxiosHeaders(answers[1]);
    }

    setAuthState({ ready: true, authenticated: answers[0] });
  };

  const renderFn = (Component) => (props) => {
    if (!authState.ready) {
      return <div />;
    }
    if (!!Component && authState.authenticated) {
      return <Component {...props} />;
    } else {
      userService.signinRedirect();
      return <Loadscreen loading={true} message="Checking credentials"></Loadscreen>;
    }
  };

  return <Route {...rest} render={renderFn(component)} />;
};
