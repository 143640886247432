import React from "react";
import { default as ListComponent } from "../inputComponents/list/index";
import FormRowWrapper from "./../formRowWrapper";

const List = (props) => {
  const {
    formProps,
    onBlur,
    allowNulls,
    dataIndex,
    changeDefaultValue,
    autoFocus = false,
    isUnlockedForEditing,
    fieldMetaData,
    fieldRenderData,
  } = props;
  const { disabled } = formProps;

  //todo implement min and maximum rows
  const { min, max } = fieldMetaData || {};

  const effectiveRules = [{ required: !allowNulls }];
  const propsWithoutCopy = { ...props, formProps: { ...formProps, allowCopy: false } };

  return (
    <FormRowWrapper {...propsWithoutCopy} effectiveRules={effectiveRules}>
      <ListComponent
        formProps={formProps}
        onBlur={(value) => onBlur(value, dataIndex)}
        fieldMetaData={fieldMetaData}
        fieldRenderData={fieldRenderData}
        autoFocus={!disabled && autoFocus}
      />
    </FormRowWrapper>
  );
};
export default List;
