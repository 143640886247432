import React from "react";
import AForm from "./../../index";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, Typography, Menu, Dropdown } from "antd";
import { DeleteOutlined, EnterOutlined } from "@ant-design/icons";
import { reorder } from "./../../../../../utils/dndOrderfunctions";
const { Text, Paragraph } = Typography;

const TableColumnsBuilder = (props) => {
  //note this is a common component also used outside AForm
  const { dataIndex, handleMetaField, columns, okButton, style = {} } = props;

  const addColumn = (newColumn) => {
    let effectiveColumns = [...columns];
    effectiveColumns.push({ column: newColumn });
    const payload = { columns: effectiveColumns };
    handleMetaField(dataIndex, payload);
  };

  const sortColumns = (newOrder) => {
    handleMetaField(dataIndex, { columns: newOrder });
  };

  const deleteColumn = (column) => {
    let oldColumns = [...columns];
    const effectiveColumns = oldColumns.filter((c) => c.column !== column);
    handleMetaField(dataIndex, { columns: effectiveColumns });
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list and catches impossible drags due to lag in browser
    if (!destination) {
      return;
    }

    switch (source.droppableId) {
      case destination.droppableId:
        sortColumns(reorder(columns, source.index, destination.index));
    }
  };

  const handleNewColumn = (values, reset) => {
    addColumn(values.newColumnName);
    reset();
  };

  const getColumnsNameList = () => {
    const nameList = columns ? columns.map((column) => column.column) : [];
    return nameList;
  };

  const columnsMenu = (
    <Menu onClick={(e) => deleteColumn(e.key)}>
      <div style={{ borderBottom: "1px solid lightgrey", padding: 10 }}>
        <Typography.Text type="danger">Delete Column:</Typography.Text>
      </div>
      {columns &&
        columns.map((columns) => <Menu.Item key={columns.column}>{columns.column}</Menu.Item>)}
    </Menu>
  );

  return (
    <div style={{ ...style }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Text
            style={{
              fontWeight: "bold",
            }}
          >
            Columns
          </Text>
        </div>
        <div style={{ display: "flex" }}>
          <AForm style={{ width: 160 }} onFinish={handleNewColumn}>
            <AForm.Text
              placeholder="+add new column"
              dataIndex="newColumnName"
              suffix={<EnterOutlined />}
              enumCheck={[getColumnsNameList(), "notInList"]}
            />
          </AForm>
          <Dropdown overlay={columnsMenu} overlayStyle={{ minWidth: 300 }}>
            <Button style={{ marginLeft: 4 }} icon={<DeleteOutlined />}></Button>
          </Dropdown>
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="COLUMNS" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={{
                display: "flex",
                borderTop: "1px solid lightgrey",
                borderBottom: "1px solid lightgrey",
              }}
            >
              {columns &&
                columns.map((column, index) => (
                  <Draggable
                    isDragDisabled={false}
                    key={index + 1 + ""}
                    draggableId={index + 1 + ""}
                    index={index}
                  >
                    {(provided, snapshot) => {
                      return (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            border: "1px solid lightgrey",
                            backgroundColor: "#fff",
                            padding: "4px 15px",
                            ...provided.draggableProps.style,
                          }}
                          key={column.column}
                        >
                          {column.column}
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div style={{ textAlign: "center" }}>
        <Paragraph type="secondary" style={{ fontStyle: "italic", fontSize: 12 }}>
          {columns && columns.length > 0 ? `drag and drop columns to sort them.` : "Add a Column"}
        </Paragraph>
      </div>
      {okButton && typeof okButton.onClick === "function" && (
        <div style={{ textAlign: "right", padding: 24 }}>
          <Button onClick={okButton.onClick}>{okButton.text}</Button>
        </div>
      )}
    </div>
  );
};

export default TableColumnsBuilder;
