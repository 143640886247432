import React from "react";

import FormRowWrapper from "./../formRowWrapper";
import SelectDropdown from "../inputComponents/select/selectDropdown";

const CountryPicker = (props) => {
  const {
    formProps,
    onBlur,
    masterType,
    apiCheck,
    allowNulls,
    dataIndex,
    fieldName,
    type = "countries",
    autoFocus = false,
    isUnlockedForEditing,
    fieldMetaData,
    fieldRenderData,
    dependencies = [],
    showIfTrue = null,
  } = props;

  const { disabled } = formProps;

  const { options = [] } = fieldMetaData || {};
  const {} = fieldRenderData || {};

  let effectiveRules = [{ required: !allowNulls }];

  const handleBlur = (newValue) => {
    onBlur(newValue, dataIndex);
  };

  const getInputComponent = () => {
    switch (type) {
      case "countries":
        return (
          <SelectDropdown
            allowNulls={allowNulls}
            disabled={disabled}
            onBlur={(e) => handleBlur(e)}
            options={options}
            optionFilterProp={"title"}
          />
        );
    }
  };

  return (
    <FormRowWrapper validateTrigger="onSelect" {...props} effectiveRules={effectiveRules}>
      {getInputComponent()}
    </FormRowWrapper>
  );
};

export default CountryPicker;
