import React from "react";
import { Typography } from "antd";

const IconTitle = (props) => {
  const { icon, title, description, style = {} } = props;
  const { Paragraph, Text } = Typography;

  return (
    <div style={{ ...style, overflow: "hidden", display: "flex" }}>
      {icon && <div style={{ marginRight: 4 }}>{icon}</div>}

      <div>
        {title && (
          <Text
            style={{
              fontWeight: "bold",
              fontSize: 14,
            }}
          >
            {title}
          </Text>
        )}
        {description && (
          <div style={{ width: "100%" }} className="wrapword">
            <Paragraph style={{ margin: 0 }} type="secondary" ellipsis={{ rows: 2 }}>
              {description}
            </Paragraph>
          </div>
        )}
      </div>
    </div>
  );
};

export default IconTitle;
