import { useCallback } from "react";
import { useHttp } from "./useHttp";

import { GetUserLibrary, GetUserDetails } from "./../services/userService";
import { GetAdminsUserLibrary } from "../services/adminService";

export function useUserDatabase(isAdmin) {
  const [{ data: userLibrary = [], loading, error }, refetchDB] = useHttp(GetUserLibrary);

  const [{ data: currentUser = { notLoadedYet: true } }, refetchCurrentUser] =
    useHttp(GetUserDetails);

  const [{ data: adminUserLibrary = [], loading: loadingAdmin, error: errorAdmin }, executeAdmin] =
    useHttp(GetAdminsUserLibrary, { manual: true });

  const getUser = useCallback(
    (id) => {
      const userObject = userLibrary.find((user) => user.userId === id);
      return userObject !== undefined ? userObject : null;
    },
    [userLibrary]
  );

  const getUserAdmin = useCallback(
    (id) => {
      const userObject = adminUserLibrary.find((user) => user.userId === id);
      return userObject !== undefined ? userObject : null;
    },
    [adminUserLibrary]
  );

  const checkIfHasMates = useCallback(() => {
    if (isAdmin) {
      const foundAdmin = adminUserLibrary.filter(
        (employee) => employee.userType.toLowerCase() === "bot"
      );

      return foundAdmin.length > 0;
    } else {
      const found = userLibrary.filter((employee) => employee.userType.toLowerCase() === "bot");

      return found.length > 0;
    }
  }, [isAdmin, adminUserLibrary, userLibrary]);

  const getCurrentUser = useCallback(() => {
    const userObject = userLibrary.find((user) => user.userId === currentUser.userId);
    return userObject !== undefined ? userObject : null;
  }, [userLibrary, currentUser.userId]);

  return {
    currentUser,
    userLibrary,
    currentUser,
    checkIfHasMates,
    adminUserLibrary,
    getUser,
    getCurrentUser,
    getUserAdmin,
    executeAdmin,
    refetchCurrentUser,
    refetchDB,
    loaders: { loadingAdmin, loading },
  };
}
