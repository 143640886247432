import React, { useContext, useEffect, useState, Suspense, lazy } from "react";
import { useLocalStorage } from "@rehooks/local-storage";
import { frozen } from "../../utils/subscriptionDefinitions";
import { Modal, Alert } from "antd";
import dayjs from "dayjs";

import NewTask from "./newTaskModal/newTask";

import TaskCard from "./../taskView/taskCard/taskCard";

import LongSinceModal from "./infoModals/longSinceModal";
import IntroModal from "./infoModals/introModal";
import NeededInformation from "./neededInformation";

import { UserDB } from "./../../contexts/userDB";
import SuspenseLoader from "./../common/loaders/suspenseLoader";
import { CustomerSettingsContext } from "./../../contexts/customerSettingsContext";

const ExcelUploader = lazy(() => import("./excelUploader/excelUploader"));
const FrozenModal = lazy(() => import("./infoModals/frozenModal"));
const NewUser = lazy(() => import("./newUser"));
const NewMate = lazy(() => import("./newMate"));
const NewProcessGroup = lazy(() => import("./newProcessGroup"));
const NewProcess = lazy(() => import("./newProcess/newProcess"));
const NewKeyModal = lazy(() => import("./newKeyModal"));
const Profile = lazy(() => import("./profileModal/profile"));
const CreateDepartments = lazy(() => import("./newDepartment/createDepartments"));

const SuspenseWrapper = ({ children }) => (
  <Suspense fallback={<SuspenseLoader />}>{children}</Suspense>
);

const Modals = ({ getStateModal, visibleModal, dispatchModal }) => {
  const { currentUser } = useContext(UserDB);

  const { lastSeenDatetime, userId, notLoadedYet, fullName } = currentUser || {};
  const customerSettingsObject = useContext(CustomerSettingsContext);

  const { data: customerSettings } = customerSettingsObject[0];
  const { subscriptionStatus } = customerSettings ? customerSettings.object || {} : {};

  const [newTaskWidth, setNewTaskWidth] = useState(600);
  const [newTaskPrompt, setNewTaskPrompt] = useState(false);
  const [showNewTaskPrompt, setShowNewTaskPrompt] = useState(false);
  const [frozenModalAccepted, setFrozenModalAccepted] = useLocalStorage(
    "frozenModalAccepted",
    false
  );

  const defaultModalStyle = { padding: "24px" };
  const defaultProps = { footer: null, destroyOnClose: true };

  /*Decides automatic popup-modals. most important on top.*/
  useEffect(() => {
    if (!notLoadedYet && subscriptionStatus) {
      let infoThatNeedsAttention = [];

      if (fullName === "" || !fullName) {
        infoThatNeedsAttention.push("fullName");
      }

      if (
        frozen.includes(subscriptionStatus) &&
        !(frozenModalAccepted && dayjs().isSame(dayjs(frozenModalAccepted), "day"))
      ) {
        dispatchModal({
          type: "TOGGLE_VISIBILITY",
          visible: { Frozen: true },
        });
      } else if (infoThatNeedsAttention.length > 0) {
        dispatchModal({
          type: "SET_STATE",
          payload: {
            NeededInfo: {
              info: infoThatNeedsAttention,
            },
          },
        });
        dispatchModal({
          type: "TOGGLE_VISIBILITY",
          visible: { NeededInfo: true },
        });
      } else if (!lastSeenDatetime) {
        dispatchModal({
          type: "TOGGLE_VISIBILITY",
          visible: { Intro: true },
        });
      } else if (
        lastSeenDatetime &&
        dayjs(lastSeenDatetime).isBefore(dayjs().subtract(30, "days"))
      ) {
        dispatchModal({
          type: "TOGGLE_VISIBILITY",
          visible: { LongSince: true },
        });
      }
    }
  }, [userId, subscriptionStatus, frozenModalAccepted, lastSeenDatetime, notLoadedYet]);

  const visibilityHandle = (id) => ({
    visible: visibleModal(id),
    onCancel: () => dispatchModal({ type: "TOGGLE_VISIBILITY", visible: { [id]: false } }),
  });

  return (
    <React.Fragment>
      <Modal zIndex={903} width={500} {...visibilityHandle("NewDepartment")} {...defaultProps}>
        <SuspenseWrapper>
          <CreateDepartments style={{ ...defaultModalStyle }} />
        </SuspenseWrapper>
      </Modal>
      <Modal zIndex={900} width={500} {...visibilityHandle("Profile")} {...defaultProps}>
        <SuspenseWrapper>
          <Profile style={{ ...defaultModalStyle }} />
        </SuspenseWrapper>
      </Modal>
      <Modal
        zIndex={903}
        width={500}
        title="Create New Key"
        {...visibilityHandle("NewKey")}
        {...defaultProps}
      >
        <SuspenseWrapper>
          <NewKeyModal style={{ ...defaultModalStyle }} getStateModal={getStateModal} />
        </SuspenseWrapper>
      </Modal>
      <Modal
        zIndex={901}
        width={800}
        {...visibilityHandle("NewProcess")}
        {...defaultProps}
        destroyOnClose={true}
      >
        <SuspenseWrapper>
          <NewProcess style={{ ...defaultModalStyle }} />
        </SuspenseWrapper>
      </Modal>
      <Modal
        zIndex={902}
        width={500}
        title="Create New Group"
        {...visibilityHandle("NewGroup")}
        {...defaultProps}
      >
        <SuspenseWrapper>
          <NewProcessGroup style={{ ...defaultModalStyle }} />
        </SuspenseWrapper>
      </Modal>

      <Modal
        style={{ transition: "width 0.4s", top: 40 }}
        zIndex={900}
        title="Create New Task"
        destroyOnClose={true}
        width={newTaskWidth}
        {...visibilityHandle("NewTask")}
        {...defaultProps}
        onCancel={() => {
          if (newTaskPrompt) {
            setShowNewTaskPrompt(true);
            setNewTaskPrompt(false);
          } else {
            dispatchModal({
              type: "TOGGLE_VISIBILITY",
              visible: { NewTask: false },
            });
            setShowNewTaskPrompt(false);
          }
        }}
        maskClosable={false}
      >
        {showNewTaskPrompt && (
          <Alert
            message="There are tasks in the basket that has not been created. Are you sure you want to leave?"
            type="info"
            showIcon
          />
        )}
        <NewTask
          style={{ ...defaultModalStyle }}
          setNewTaskWidth={setNewTaskWidth}
          setNewTaskPrompt={setNewTaskPrompt}
          setShowNewTaskPrompt={setShowNewTaskPrompt}
          getStateModal={getStateModal}
          dispatchModal={dispatchModal}
        />
      </Modal>

      <Modal
        zIndex={901}
        title="Excel Uploader"
        width={1200}
        {...visibilityHandle("ExcelUploader")}
        {...defaultProps}
        onCancel={() => {
          dispatchModal({
            type: "SET_STATE",
            payload: { ExcelUploader: { fileList: [] } },
          });
          dispatchModal({
            type: "TOGGLE_VISIBILITY",
            visible: { ExcelUploader: false },
          });
        }}
        maskClosable={false}
      >
        <SuspenseWrapper>
          <ExcelUploader style={{ ...defaultModalStyle }} getStateModal={getStateModal} />
        </SuspenseWrapper>
      </Modal>
      <Modal
        zIndex={900}
        width={500}
        title="Invite New User"
        {...visibilityHandle("NewUser")}
        {...defaultProps}
        maskClosable={false}
        destroyOnClose={true}
      >
        <SuspenseWrapper>
          <NewUser style={{ ...defaultModalStyle }} />
        </SuspenseWrapper>
      </Modal>
      <Modal
        zIndex={900}
        width={500}
        title="Create New Mate"
        destroyOnClose={true}
        style={{ top: 200 }}
        {...visibilityHandle("NewMate")}
        {...defaultProps}
        maskClosable={false}
      >
        <SuspenseWrapper>
          <NewMate style={{ ...defaultModalStyle }} />
        </SuspenseWrapper>
      </Modal>

      <Modal
        {...visibilityHandle("TaskCard")}
        closable={false}
        width={600}
        zIndex={900}
        onCancel={null}
        {...defaultProps}
      >
        <TaskCard drawBorder={false} />
      </Modal>
      <Modal
        {...visibilityHandle("NeededInfo")}
        closable={false}
        width={600}
        zIndex={900}
        onCancel={null}
        {...defaultProps}
      >
        <NeededInformation getStateModal={getStateModal} dispatchModal={dispatchModal} />
      </Modal>
      <IntroModal
        zIndex={1000}
        getStateModal={getStateModal}
        dispatchModal={dispatchModal}
        visibleModal={visibleModal}
      />
      <LongSinceModal
        zIndex={1000}
        getStateModal={getStateModal}
        dispatchModal={dispatchModal}
        visibleModal={visibleModal}
      />
      <Modal
        {...visibilityHandle("Frozen")}
        closable={true}
        width={600}
        zIndex={1100}
        onCancel={null}
        {...defaultProps}
      >
        <SuspenseWrapper>
          <FrozenModal
            dispatchModal={dispatchModal}
            setFrozenModalAccepted={setFrozenModalAccepted}
          />
        </SuspenseWrapper>
      </Modal>
    </React.Fragment>
  );
};

export default Modals;
