import React from "react";
import { Collapse, Typography } from "antd";
import { getPrimaryPalette } from "../../../../../../utils/colorpalette";
import { DesktopOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";

const { Panel } = Collapse;
const palette = getPrimaryPalette();

const SelectList = (props) => {
  const {
    options,
    onChange,
    onBlur,
    value,
    style = {},
    allowClear,
    disabled,
    renderNoOptions,
    border,
  } = props;

  const handlePanelChange = (newKey) => {
    if (!disabled) {
      if (newKey !== undefined) {
        onChange(newKey);
        onBlur(newKey);
      }
    }
  };
  /*style={{
    maxHeight: "65vh",
    overflow: "auto",
    marginBottom: 24,
    borderBottom: "1px solid lightgrey",
    borderTop: "1px solid lightgrey",
  }}*/
  const drawHeader = (title, description, icon, selectValue, alreadySelected) => {
    const isSelected = selectValue === value;

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>
          {typeof icon === "function"
            ? icon(null, { color: isSelected ? palette.main : "" })
            : icon}
        </div>
        <div>
          <div>
            <Typography.Text strong style={{ color: isSelected ? palette.main : "" }}>
              {title}
              {alreadySelected && (
                <Typography.Text type="secondary" style={{ marginLeft: 8 }}>
                  (already selected)
                </Typography.Text>
              )}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text type="secondary">{description}</Typography.Text>
          </div>
        </div>
      </div>
    );
  };

  const panelBorderOption = (selectValue) => {
    let borderStyle = {};
    if (value === selectValue) {
      borderStyle.border = `1px solid ${palette.main}`;
    } else if (!border) {
      borderStyle = { border: "none" };
    } else {
      borderStyle.borderBottom = `1px solid ${palette.lightGrey}`;
    }
    return borderStyle;
  };

  return (
    <Scrollbars autoHeight={true} autoHeightMin={600}>
      <Collapse
        bordered={false}
        accordion={true}
        activeKey={value}
        onChange={handlePanelChange}
        className={`site-collapse-custom-collapse`}
        style={{
          borderRadius: "2px",
          //border: border ? "1px solid lightgrey" : "",
          width: "100%",
          ...style,
        }}
      >
        {options.map((option) => {
          const {
            selectValue,
            alreadySelected,
            icon,
            title,
            description,
            focusDescription,
          } = option;

          return (
            <Panel
              showArrow={false}
              header={drawHeader(title, description, icon, selectValue, alreadySelected)}
              key={selectValue}
              collapsible={(disabled || alreadySelected) && "disabled"}
              style={{
                ...panelBorderOption(selectValue),
                position: "relative",
                margin: 12,
              }}
            >
              <div style={{ padding: "0px 16px 0px 16px" }}>{focusDescription}</div>

              <div style={{ position: "absolute", bottom: 2, right: 2 }}>
                <CheckOutlined style={{ padding: 6, color: palette.main, fontSize: 24 }} />
              </div>

              {allowClear && (
                <div style={{ position: "absolute", top: 2, right: 2 }}>
                  <CloseOutlined
                    onClick={() => handlePanelChange(null)}
                    style={{ padding: 6 }}
                    className="hovergrey"
                  />
                </div>
              )}
            </Panel>
          );
        })}
      </Collapse>
    </Scrollbars>
  );
};

export default SelectList;
