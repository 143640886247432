import React from "react";
import { CirclePicker } from "react-color";

const ColorPicker = (props) => {
  const { value, onChange, disabled, onBlur } = props;

  if (disabled) {
    return <div />;
  }

  return (
    <div style={{ padding: 8 }}>
      <CirclePicker
        width={"100%"}
        disabled={disabled}
        color={value || undefined}
        onChangeComplete={(colorObject) => {
          onChange(colorObject.hex);
          typeof onBlur === "function" && onBlur(colorObject.hex);
        }}
      />
    </div>
  );
};

export default ColorPicker;
