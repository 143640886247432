import React from "react";
import FormRowWrapper from "./../formRowWrapper";
import Quill from "./../inputComponents/quill/quill";

const RichText = (props) => {
  const {
    formProps,
    onBlur,
    style,
    allowNulls,
    autoFocus = false,
    isUnlockedForEditing,
    fieldMetaData,
    fieldRenderData,
  } = props;

  const { disabled } = formProps;
  const { min } = fieldMetaData || {};
  const { collapsible } = fieldRenderData || {};

  const effectiveRules = [
    (formProps) => ({
      validator: async (rule, value) => {
        //quill sets value to this when blank
        if (value && value !== "<p><br></p>") {
          return Promise.resolve("Field is Good");
        } else {
          return Promise.reject("Field is Required");
        }
      },
    }),
  ];

  return (
    <FormRowWrapper
      {...props}
      effectiveRules={allowNulls ? [] : [...effectiveRules]}
      overrideAllowCopy={false}
    >
      <Quill disabled={disabled} style={{ marginBottom: 12, ...style }} />
    </FormRowWrapper>
  );
};

export default RichText;
