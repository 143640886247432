import XLSX from "xlsx";
import { encodeFieldToExcel, fitToColumn } from "./excelHelpers";

export const getExcelQueueExport = (
  fileName = "Anymate excel export.xlsx",
  filteredTableData,
  tableColumns
) => {
  const columnOrder = tableColumns.map((column) =>
    column.title ? column.title : column.fieldType
  );

  const encodedData = filteredTableData.map((row) => {
    let encodedRow = {};
    for (const [key, value] of Object.entries(row)) {
      const foundHeader = tableColumns.find((header) => {
        return header.dataIndex === key;
      });
      //make values according to customFieldType and fieldType

      const encodedValue = foundHeader
        ? encodeFieldToExcel(
            value,
            foundHeader.fieldType === "CustomField"
              ? foundHeader.customFieldType
              : foundHeader.fieldType
          )
        : value;

      const encodedKey = foundHeader
        ? {
            [foundHeader.title ? foundHeader.title : foundHeader.fieldType]: encodedValue,
          }
        : {};
      encodedRow = { ...encodedRow, ...encodedKey };
    }

    return encodedRow;
  });

  const book = XLSX.utils.book_new();
  console.log(encodedData, columnOrder);
  const ws = XLSX.utils.json_to_sheet(encodedData, { header: columnOrder });

  ws["!cols"] = fitToColumn(encodedData, columnOrder);

  XLSX.utils.book_append_sheet(book, ws, "data");
  XLSX.writeFile(book, fileName);
};
