import { getPrimaryPalette, colorRange, getRangePalette } from "./colorpalette";
import {
  CheckOutlined,
  DeleteOutlined,
  FileUnknownOutlined,
  RobotOutlined,
  FileMarkdownOutlined,
  AuditOutlined,
  FileSearchOutlined,
  WarningOutlined,
  AlertOutlined,
  SyncOutlined,
  LockOutlined,
} from "@ant-design/icons";

const palette = getPrimaryPalette();
const rangePalette = getRangePalette();

export const closedQueues = ["Solved", "Obsolete", "Archive"];
export const activeQueues = ["NotReady", "NeedsApproval", "Ready", "Manual"];
export const queueTypes = [
  {
    key: "NotReady",
    label: "Needs Data",
    description: "Your action is required",
    text: "Needs Data",
    color: palette.heavy,
    colorRange: rangePalette.heavy,
    Icon: FileUnknownOutlined,
  },
  {
    key: "NeedsApproval",
    label: "Needs Approval",
    description: "Your action is required",
    text: "Needs Approval",
    color: palette.heavy,
    colorRange: rangePalette.heavy,
    Icon: AuditOutlined,
  },
  {
    key: "Ready",
    label: "Ready",
    description: "Waiting for Robot.",
    text: "Ready",
    color: palette.heavy,
    colorRange: rangePalette.heavy,
    Icon: RobotOutlined,
  },
  {
    key: "Manual",
    label: "Manual",
    description: "Your action is required",
    text: "Manual",
    color: palette.purple,
    colorRange: rangePalette.purple,
    Icon: FileMarkdownOutlined,
  },
  {
    key: "Solved",
    label: "Solved",
    description: "Task has been solved and archieved",
    text: "Solved",
    color: palette.green,
    colorRange: rangePalette.green,
    Icon: CheckOutlined,
  },
  {
    key: "Obsolete",
    label: "Cancelled",
    description: "cancelled tasks",
    text: "Obsolete",
    color: palette.yellow,
    colorRange: rangePalette.yellow,
    Icon: DeleteOutlined,
  },
];

export const reasonTypes = [
  {
    key: "Locked",
    label: "Locked",
    description: "Task is locked",
    text: "Locked",
    color: "inherit",
    colorRange: "inherit",
    Icon: LockOutlined,
  },
  {
    key: "Manual Control",
    Reason: "Manual Control",
    Icon: FileSearchOutlined,
    color: palette.purple,
    colorRange: colorRange(palette.purple),
  },
  {
    key: "Error",
    label: "Error",
    description: "Tasks with errors",
    text: "Error",
    color: palette.red,
    colorRange: colorRange(palette.red),
    Icon: AlertOutlined,
  },
  {
    key: "Retry",
    label: "Retry",
    description: "Tasks that needs to be retried",
    text: "Retry",
    color: palette.orange,
    colorRange: colorRange(palette.orange),
    Icon: SyncOutlined,
  },
  {
    key: "Cancelled",
    label: "Cancelled",
    description: "Task was cancelled",
    text: "Cancelled",
    color: palette.orange,
    colorRange: colorRange(palette.orange),
    Icon: DeleteOutlined,
  },
  {
    key: "Expired",
    label: "Expired",
    description: "Task went overdue and has been automatically expired",
    text: "Expired",
    color: palette.orange,
    colorRange: colorRange(palette.orange),
    Icon: DeleteOutlined,
  },
  {
    key: "NotActive",
    label: "Not Active",
    description: "Task is not active yet",
    text: "Not active yet",
    color: "inherit",
    colorRange: "inherit",
    Icon: DeleteOutlined,
  },
];

export const slaTypes = [
  {
    key: "Urgent",
    label: "Urgent",
    description: "Task is close to deadline",
    text: "Urgent",
    color: palette.yellow,
    colorRange: colorRange(palette.yellow),
    Icon: WarningOutlined,
  },
  {
    key: "Overdue",
    label: "Overdue",
    description: "Task is passed its deadline",
    text: "Overdue",
    color: palette.orange,
    colorRange: colorRange(palette.orange),
    Icon: WarningOutlined,
  },
];

export const allTypes = [...queueTypes, ...reasonTypes, ...slaTypes];
