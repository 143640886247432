import { useCallback, useRef, useReducer } from "react";
import { useReducerAsync } from "use-reducer-async";

export const useEnhancedReducer = (reducer, initState, initializer, asyncActionHandlers) => {
  const lastState = useRef(initState);
  const getState = useCallback(() => lastState.current, []);

  const referencedReducer = useRef((state, action) => (lastState.current = reducer(state, action)))
    .current;

  const [state, dispatch] = useReducerAsync(referencedReducer, initState, asyncActionHandlers);

  const dispatchMemo = useCallback(dispatch, [dispatch]);

  return [[state, dispatchMemo], getState];
};
