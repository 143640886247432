import React, { useEffect, Suspense } from "react";

import { useUserDatabase } from "./hooks/useUserDatabase";
import { useQueues } from "./hooks/useQueues";
import { usePermissions } from "./hooks/usePermissions";
import { useProcessTeams } from "./hooks/useProcessTeams";
import { useTimeSelection } from "./hooks/useTimeSelection";
import { useModal } from "./hooks/useModal";
import { useHttp } from "./hooks/useHttp";
import { useOverview } from "./hooks/useOverview";
import { useTour } from "./hooks/useTour";
import { useFeature } from "feature-toggle-jsx";

import { getLazyComponent } from "./utils/routerHelpers";
import { antdConfig, formConfig } from "./utils/antdConfig";
import paths from "./utils/paths";

import { Route, Switch, Redirect } from "react-router-dom";
import { Layout, ConfigProvider } from "antd";

import NavbarHeader from "./components/navbarHeader/navbarHeader";
import NotFound from "./components/common/layout/defaultPages/notFound";
import Loadscreen from "./components/common/layout/defaultPages/loadscreen";
import Modals from "./components/modal/modals";

//internationalization
import enGB from "antd/es/locale/en_GB";
import daDK from "antd/es/locale/da_DK";

//contexts
import { Utils } from "./contexts/utils";
import { UserDB } from "./contexts/userDB";
import { ProcessTeams } from "./contexts/processTeams";
import { OverviewContext } from "./contexts/overviewContext";
import { TimeFrame } from "./contexts/timeFrame";
import { QueueContext } from "./contexts/queueContext";
import { CustomerSettingsContext } from "./contexts/customerSettingsContext";
import { GetCustomerSettingStatus } from "./services/companyService";

import "./App.css";

//code splitting
const DepartmentsView = getLazyComponent(() => import("./components/departments/departmentsView"));
const SettingsView = getLazyComponent(() => import("./components/settings/settingsView"));
const Sandbox = getLazyComponent(() => import("./components/sandbox"));
const ReportsView = getLazyComponent(() => import("./components/reports/reportsView"));
const OverviewView = getLazyComponent(() => import("./components/overview/overview"));
const QueueView = getLazyComponent(() => import("./components/taskView/queueView"));
const ProcessView = getLazyComponent(() => import("./components/processView/processView"));

const App = (props) => {
  //timeframe for queue, overview  and runs to share
  const queueTimeFrame = useTimeSelection("queue", "short", "last7days");

  useEffect(() => {
    antdConfig();
  }, []);

  const [_, config] = useFeature("sandbox");
  const permissions = usePermissions();
  const processTeams = useProcessTeams(permissions[0]("OrganisationAdmin"));
  const userDatabase = useUserDatabase(permissions[0]("OrganisationAdmin"));

  const customerSettingStatus = useHttp(GetCustomerSettingStatus);

  const overview = useOverview(queueTimeFrame);
  const taskQueue = useQueues();

  const tour = useTour();

  const { getState: getStateModal, visible: visibleModal, dispatch: dispatchModal } = useModal();

  const utils = {
    tour,
    dispatchModal,
    permittor: permissions[0],
    permissionsLoading: permissions[1],
  };

  return (
    <ConfigProvider locale={daDK} form={formConfig}>
      <CustomerSettingsContext.Provider value={customerSettingStatus}>
        <QueueContext.Provider value={taskQueue}>
          <TimeFrame.Provider value={queueTimeFrame}>
            <UserDB.Provider value={userDatabase}>
              <OverviewContext.Provider value={overview}>
                <Utils.Provider value={utils}>
                  <ProcessTeams.Provider value={processTeams}>
                    <Layout>
                      <Route path="/:menu?/">
                        <NavbarHeader
                          {...props}
                          DepartmentsViewPreload={() => DepartmentsView.preload()}
                          overridePermissions={permissions[2]}
                          allowedPermissions={permissions[3]}
                        />
                      </Route>
                      <Layout.Content className="layoutmain">
                        <Suspense fallback={<Loadscreen loading={true} />}>
                          <Switch>
                            <Route path={paths.departments}>
                              <DepartmentsView {...props} />
                            </Route>
                            {config.isEnabled && (
                              <Route path="/sandbox/">
                                <Sandbox {...props} />
                              </Route>
                            )}
                            <Route path={paths.settings}>
                              <SettingsView {...props} />
                            </Route>
                            <Route path={paths.report}>
                              <ReportsView {...props} />
                            </Route>
                            <Route path={[paths.queue, paths.search]}>
                              <QueueView {...props} />
                            </Route>
                            <Redirect from="/tasks" exact to="/tasks/queue" />
                            <Route path={paths.overview}>
                              <OverviewView />
                            </Route>
                            <Route path={paths.process}>
                              <ProcessView {...props} />
                            </Route>

                            <Route path={paths.notFound}>
                              <NotFound />
                            </Route>
                            <Redirect from="/" exact to={paths.overview} />
                            <Redirect to={paths.notFound} />
                          </Switch>
                        </Suspense>
                      </Layout.Content>
                    </Layout>
                    <Route path="/:menu?/:submenu?">
                      <Modals
                        getStateModal={getStateModal}
                        visibleModal={visibleModal}
                        dispatchModal={dispatchModal}
                      />
                    </Route>
                  </ProcessTeams.Provider>
                </Utils.Provider>
              </OverviewContext.Provider>
            </UserDB.Provider>
          </TimeFrame.Provider>
        </QueueContext.Provider>
      </CustomerSettingsContext.Provider>
    </ConfigProvider>
  );
};
export default App;
