import React, { useState, useEffect, useRef, useMemo } from "react";
import { Typography, Form, Checkbox } from "antd";
import CopyToClipboard from "./copyToClipboard";
import ConditionalWrapper from "./../conditionalWrapper";
import useHover from "@react-hook/hover";
import { shouldRenderCheck } from "./formHelpers";
const { Text } = Typography;

const harvestDependencies = (shouldRender, dataIndex) => {
  if (typeof shouldRender === "object") {
    const effectiveDepArray = shouldRender.reduce((acc, curr) => {
      return [...acc, ...curr.dependencies];
    }, []);
    return [dataIndex, ...effectiveDepArray];
  } else return [dataIndex];
};

const FormRowWrapper = (props) => {
  const {
    title,
    fieldValue,
    fieldName,
    defaultValue,
    formProps,
    noStyle,
    effectiveRules,
    children,
    copyMessage = null,
    onBlur,
    allowNulls,
    dataIndex,
    fieldRenderData,
    fieldMetaData,
    valuePropName = "value",
    validateTrigger = "onChange",
    description,
    overrideAllowCopy,
  } = props;

  const { addRight, infoAdd, collapsible = null } = fieldRenderData || {};
  const { shouldRender = true } = fieldMetaData || {};
  const { allowCopy, form, updateKey, margin, disabled, applyDefaultValues } = formProps;
  const { getFieldsValue, getFieldValue } = form || {};

  const value = getFieldValue(dataIndex);
  const [isCollapsed, setIsCollapsed] = useState(!fieldValue && !value && collapsible);

  const target = useRef(null);
  const isHovering = useHover(target);

  const dependencies = useMemo(() => harvestDependencies(shouldRender, dataIndex), [
    updateKey,
    dataIndex,
  ]);

  useEffect(() => {
    if (collapsible) {
      if (fieldValue !== value) {
        setIsCollapsed(fieldValue || fieldValue === 0 ? false : true);
      }
    }
  }, [updateKey, fieldValue]);

  const handleCollapse = (e) => {
    setIsCollapsed(!e.target.checked);
    if (e.target.checked === false) {
      form.setFieldsValue({ [dataIndex]: null });
      form.validateFields([dataIndex]);
      onBlur && onBlur(null, dataIndex);
    }
  };

  const getShouldUpdate = (prevValue, nextValue) => {
    let shouldUpdate = false;
    dependencies.forEach((dataKey) => {
      if (prevValue[dataKey] !== nextValue[dataKey]) {
        shouldUpdate = true;
      }
    });

    return shouldUpdate;
  };

  const renderTitle = (style = {}) =>
    fieldName && (
      <div style={{ ...style }}>
        <ConditionalWrapper
          condition={collapsible}
          wrapper={(children) => (
            <Checkbox
              disabled={disabled}
              checked={!isCollapsed}
              style={{ marginRight: 6 }}
              onChange={handleCollapse}
            >
              {children}
            </Checkbox>
          )}
        >
          <Text style={{ fontWeight: "bold" }}>{title ? title : fieldName}</Text>
        </ConditionalWrapper>
      </div>
    );

  const marginAdd = (doit) => (doit ? { margin } : {});
  const effectiveAllowCopy = allowCopy && overrideAllowCopy !== false;

  return (
    <div ref={effectiveAllowCopy ? target : null} style={marginAdd(true)}>
      <Form.Item noStyle={true} shouldUpdate={getShouldUpdate}>
        {() => {
          const effectiveDefaultValue =
            fieldValue !== null && fieldValue !== undefined
              ? fieldValue
              : (applyDefaultValues && defaultValue) || undefined;

          const effectiveShouldRender = shouldRenderCheck(shouldRender, getFieldsValue());

          return (
            <React.Fragment>
              <ConditionalWrapper
                condition={!noStyle}
                wrapper={(children) => (
                  <Form.Item
                    extra={description}
                    preserve={true}
                    tooltip={infoAdd}
                    hidden={isCollapsed || !effectiveShouldRender}
                    required={!allowNulls}
                    label={renderTitle()}
                  >
                    {children}
                  </Form.Item>
                )}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    overflow: "hidden",
                  }}
                >
                  <Form.Item
                    noStyle
                    initialValue={effectiveDefaultValue}
                    name={dataIndex}
                    hidden={!effectiveShouldRender}
                    rules={effectiveShouldRender ? effectiveRules : []}
                    valuePropName={valuePropName}
                    validateTrigger={validateTrigger}
                  >
                    {children}
                  </Form.Item>
                  {addRight && <div style={{ marginLeft: 4 }}>{addRight}</div>}
                  {effectiveAllowCopy && (
                    <CopyToClipboard
                      hovered={isHovering}
                      copyMessage={copyMessage}
                      dataIndex={dataIndex}
                      allowCopy={effectiveAllowCopy}
                      text={value}
                    />
                  )}
                </div>
              </ConditionalWrapper>

              {isCollapsed && renderTitle({ marginBottom: 12 })}
            </React.Fragment>
          );
        }}
      </Form.Item>
    </div>
  );
};

export default FormRowWrapper;
