import React from "react";

const BotStaticAvatar = (props) => {
  const { name = "Anymate Bot", mainColor } = props;
  return (
    <svg
      id="avatar"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 300 300"
    >
      <defs>
        <clipPath id="clip-path">
          <rect x="91.1" y="160.2" width="84.8" height="30.98" fill="none" />
        </clipPath>
      </defs>
      <g id="background">
        <circle cx="150" cy="150" r="150" fill="#efefef" />
      </g>
      <g id="backarm">
        <path
          d="M175.8,169.5c-15-.4-18.4,19.7-18.4,36.2,0,29.8,10.2,59.6,44.5,58.4,13.3-.7,27.6-9.8,38.6-18.4,15.2-12.1-2.5-31.1-12.1-18.4-15.2,17.7-36.8,15.2-38.7-7.7-1.2-18.4,3.2-48.3-13.9-50.1"
          fill="#898e93"
        />
        <path
          d="M176.9,202.7l.4-.2,1-.2a11.7,11.7,0,0,1,2.6-.4,10.3,10.3,0,0,1,2.5-.1c.7.1,1.3.4,1.4.3l.6-1a5.5,5.5,0,0,0-1.5-1,7.5,7.5,0,0,0-3.3-.6,8.4,8.4,0,0,0-3,1.1l-1,.7-.4.3a4.1,4.1,0,0,0,.7,1.1"
          fill="#c1c1c1"
        />
        <path
          d="M177.4,185.1l1.3-.6a10.1,10.1,0,0,1,2.3-.7l2.4-.5h1.5l.2-1.2-1.6-.5a8.1,8.1,0,0,0-3.1,0,5.6,5.6,0,0,0-2.7,1.5l-.9.9-.2.4.8.8"
          fill="#c1c1c1"
        />
      </g>
      <g id="body">
        <path
          d="M99.6,321.2c15.2,18.4,40,23.5,58.4,7,36.3-31.8,24.4-98.5,17.9-145.4l-.9-6.2q-.6-5.1-1.2-9.6a11.9,11.9,0,0,0-6.6-5.4c-7.6-2.7-18.9-.9-27.5.2-2.4.3-4.6.6-6.5.7-8.8.6-35.5-5-39.9,1.3l-.7-1.9c-.2,2.6-.5,5.2-.8,7.8s-.4,4-.7,5.9c-2.7,21.9-6.4,43.4-8,65-2.5,27.3-1.3,59,16.5,80.6"
          fill="#76a6ba"
        />
        <g opacity="0.2">
          <g clipPath="url(#clip-path)">
            <path
              d="M91.1,175.6c7.6,8.5,23.4,12.7,32.4,14.3,17.4,2.5,38.4,1.9,52.4-7.1l-.9-6.2q-.6-5.1-1.2-9.6a11.9,11.9,0,0,0-6.6-5.4c-7.6-2.7-18.9-.9-27.5.2-2.4.3-4.6.6-6.5.7-8.8.6-35.5-5-39.9,1.3l-.7-1.9c-.2,2.6-.5,5.2-.8,7.8s-.4,4-.7,5.9"
              fill="#262626"
            />
          </g>
        </g>
        <path
          d="M158.4,74.8c-.6-19.1-42.3-13.6-41.4-1.6.1,10.2,37.1,3.1,45.3,2.6"
          fill="#194b77"
        />
        <path
          d="M121.5,28a11.5,11.5,0,0,0,1.7,1.1l1.7,1.4,2.1,2a42.6,42.6,0,0,1,7.1,10.9A40.8,40.8,0,0,1,138,55.8c.1,1,.1,2,.2,2.8a19.3,19.3,0,0,0-.1,2.3l-.3,1.9c-.1.5,1.6,1,1.7.6a17.1,17.1,0,0,0,.6-2.2,14.5,14.5,0,0,0,.4-2.5,28.5,28.5,0,0,0,.2-3.1,35.4,35.4,0,0,0-11.9-25,19.9,19.9,0,0,0-2.4-1.9l-2.1-1.3-2-1c-.5-.1-1.1,1.4-.8,1.6"
          fill="#050e1c"
        />
        <path
          d="M121.6,27.1c6.4-8.7-7.9-21.1-14.2-11.8s8.3,21.5,14.2,11.8"
          fill="#ff504f"
        />
        <path
          d="M117.9,201.8c1.1,9.4,50,3.7,48.9-5.8s-13-15.7-26.4-14.1-23.6,10.4-22.5,19.9"
          fill="#a0c470"
        />
        <path
          d="M120.1,198.4l4.7,2.6c-.5-.3-.7-.3-.6-.2l.5.2c.2.2.2-.1.3-.2l.3-.5,2.5-4.5h-.1c0-.1.2,0,.3.2l5.1,3.9.4-1.2.9-2.9,1.1-3.1c.1-.5.3-1,.4-1.5h.1l.5.6c.8,1,1.7,2.2,2.5,3.4l3,3.8,5.1-2.7,1.5-.8.3-.2h0l4.9,4c2.9-3,4.9-4.9,6.2-6.1l.7-.8.3-.3h.1c.1-.2.2-.2.2-.3l4.8,3.9c.5.5,1.3-.4.8-.9l-5.6-4.8-.9.8-1.5,1.4c-1.1.9-2.7,2.5-5.2,4.8v-.2h-.1l-.5-.4-3.8-3.3c-1,.4-3.3,1.6-6.5,3.3l-.4-.6-1.4-1.8-5.8-7.4c-.9,3.3-1.9,6.5-2.6,8.9l-.3,1.3c-.1,0-.1.1-.1.2v.2l-.3-.2-1.2-.8-2.1-1.6-1-.8h-.2v.2l-.2.3.3-.4-2.9,5.4a.5.5,0,0,0-.1.3h0l-3.6-1.9a.6.6,0,0,0-.6,1"
          fill="#3b3b3b"
        />
        <path
          d="M55.1,121.6c0,27.5,36.5,49.7,81.6,49.7s81.5-22.2,81.5-49.7-36.5-49.7-81.5-49.7S55.1,94.2,55.1,121.6"
          fill="#91c1d9"
        />
      </g>
      <g id="paper">
        <path
          d="M141,295.4l96.1-20s-1.1-51.9-1.1-78c0,0-89.3,18.7-96.8,20.4,0,0,0,66.6,1.8,77.6"
          fill="#fff"
          stroke="#7f7f7f"
          strokeMiterlimit="10"
          strokeWidth="1.22"
        />
      </g>
      <g id="frontarm">
        <path
          d="M131.3,258.4C146,245,160.6,268.6,144,275.7c-18.9,8.1-47.2,3.3-62.2-10.5-20.6-19-19.4-51.4-9.5-75.4,3.4-9.8,14.4-20.4,25.2-13.6s7,23,4.4,33.2-8.4,25.4-3.2,36.9c6.1,13.5,21.5,14.5,34,11.2"
          fill="#898e93"
        />
        <path
          d="M131.7,274.6a2.4,2.4,0,0,1-.1-.7,10.7,10.7,0,0,1,0-1.8,21.2,21.2,0,0,1,.4-4.4,17,17,0,0,1,1.1-4.3,13.7,13.7,0,0,1,1.1-2.1c0-.2-.8-.9-.9-.8l-.6.6a7,7,0,0,0-1.2,1.5,13.7,13.7,0,0,0-1.9,4.7,16.1,16.1,0,0,0,.2,4.9,7.4,7.4,0,0,0,.5,1.9,3.1,3.1,0,0,0,.2.7c.1.2,1.1-.1,1.2-.2"
          fill="#c1c1c1"
        />
        <path
          d="M101.8,269.5a3.2,3.2,0,0,0,.2-.6c.2-.3.4-.9.7-1.4a23.9,23.9,0,0,1,2.1-3.4,16.6,16.6,0,0,1,2.4-3l1.1-.9.5-.4a2.1,2.1,0,0,0-.4-1.1l-.7.2-1.6.8a12.2,12.2,0,0,0-3.3,3.1,17,17,0,0,0-1.8,4.1,14.3,14.3,0,0,0-.3,1.6,3.2,3.2,0,0,0-.1.7,1.7,1.7,0,0,0,1.2.3"
          fill="#c1c1c1"
        />
        <path
          d="M79.9,253.5l.3-.9a7.3,7.3,0,0,1,1.4-2A10.9,10.9,0,0,1,87,248a22,22,0,0,1,6.3-.2,14.2,14.2,0,0,1,3.5.8c.2,0,.7-1,.5-1.1l-1-.5-2.6-1a19.4,19.4,0,0,0-7.2-.3,10.5,10.5,0,0,0-6.3,3.8,8.9,8.9,0,0,0-1.3,2.6l-.2,1.2c0,.2,1.1.4,1.2.2"
          fill="#c1c1c1"
        />
        <path
          d="M76,223.5l.8-.5a11.6,11.6,0,0,1,2.2-1,14,14,0,0,1,6.1-.2,22.2,22.2,0,0,1,6.1,1.7,16,16,0,0,1,3.1,1.7c.2.1,1-.8.8-1a27.3,27.3,0,0,0-3-2.3,18.4,18.4,0,0,0-6.6-2.5,13.3,13.3,0,0,0-7.1,1,8.3,8.3,0,0,0-2.4,1.5l-.8.7c-.1.3.6,1,.8.9"
          fill="#c1c1c1"
        />
        <path
          d="M75.2,196.9l1.2-.3,2.8-.5a20.4,20.4,0,0,1,7.4,1c2.2.6,5,1.6,7.4,2.4s4.2,1.2,4.2,1.2.9-1,.6-1.1L95,197.9a49.7,49.7,0,0,0-7.4-3,18.5,18.5,0,0,0-8.8-.5,13,13,0,0,0-3.1.9l-1.2.6c-.2.2.4,1.1.7,1"
          fill="#c1c1c1"
        />
      </g>
      <g id="face">
        <path
          d="M93.8,117.7c0,17.4,24.8,31.6,55.3,31.6s55.2-14.2,55.2-31.6-24.7-31.6-55.2-31.6-55.3,14.1-55.3,31.6"
          fill="#050e1c"
        />
        <path
          d="M135.8,101.6c4.4,5.1,10.1,28.6-3.2,26-11.4-2.5-8.9-34.3,3.2-26"
          fill="#83e5e5"
        />
        <path
          d="M179.6,100.6c4.5,5.1,10.2,28.6-3.1,26-11.5-2.5-8.9-34.3,3.1-26"
          fill="#83e5e5"
        />
      </g>
    </svg>
  );
};

export default BotStaticAvatar;
