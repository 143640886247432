import React from "react";

import FormRowWrapper from "./../formRowWrapper";
import SelectList from "../inputComponents/select/selectList/selectList";
import SelectIcons from "../inputComponents/select/selectIcons/selectIcons";
import SelectDropdown from "../inputComponents/select/selectDropdown";
import SelectMultiple from "../inputComponents/select/selectMultiple";
import SelectTransfer from "../inputComponents/select/selectTransfer";

import { FrownOutlined } from "@ant-design/icons";

const Select = (props) => {
  const {
    formProps,
    onBlur,
    masterType,
    apiCheck,
    allowNulls,
    dataIndex,
    fieldName,
    type,
    autoFocus = false,
    isUnlockedForEditing,
    filterOption = false,
    fieldMetaData,
    searchFunction,
    fieldRenderData,
    customRender,
    style = {},
  } = props;
  const { form, disabled } = formProps;
  const { options = [] } = fieldMetaData || {};
  const { border = true, placeholder, showAs, titles } = fieldRenderData || {};
  const { getFieldValue } = form;

  const effectiveType = (showAs || masterType || "dropdown").toLowerCase();

  let filteredOptions = options
    ? options.map((item) => ({
        ...item,
        selectValue: item.value || item.selectValue,
        title: item.value || item.title,
        description: item.note,
      }))
    : [];

  if (filterOption) {
    filteredOptions = options.filter(
      (group) => group[filterOption] === getFieldValue(filterOption)
    );
  }
  const getEnumCheck = () => {
    const enumOptions = filteredOptions.map((option) => option.selectValue);

    return () => ({
      validator(rule, value) {
        if (!value) {
          /*null is allowed even if it isnt on the list.
          null and required are handled in other rule so we resolve it here          
          */
          return Promise.resolve();
        }
        if (typeof value === "string" || typeof value === "number") {
          const effectiveValue = typeof value === "string" ? value.replace(/(^\s+|\s+$)/g) : value;
          if (enumOptions.includes(effectiveValue)) {
            return Promise.resolve();
          }
        } else if (Array.isArray(value)) {
          const arrayValue = value[value.length - 1];
          const effectiveArrayValue =
            typeof arrayValue === "string" ? arrayValue.replace(/(^\s+|\s+$)/g) : arrayValue;
          enumOptions.includes(effectiveArrayValue);
          {
            return Promise.resolve();
          }
        }
        return Promise.reject("This is not an option");
      },
    });
  };

  const getRules = () => {
    let effectiveRules = [{ required: !allowNulls }, getEnumCheck()];

    return effectiveRules;
  };

  const renderNoOptions = () => {
    return (
      <div
        style={{
          minHeight: 100,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <FrownOutlined />
        No Options found
      </div>
    );
  };

  const commonProps = {
    placeholder,
    autoFocus,
    border,
    allowClear: allowNulls,
    disabled,
    onBlur: (e) => onBlur(e, dataIndex),
    options: filteredOptions,
    renderNoOptions: renderNoOptions,
  };

  const getInputComponent = () => {
    switch (effectiveType) {
      case "largeicons":
        return <SelectIcons style={{ ...style }} {...commonProps} />;

      case "list":
        return <SelectList style={{ ...style }} fieldMetaData={fieldMetaData} {...commonProps} />;

      case "dropdown":
        return <SelectDropdown {...commonProps} />;
      case "multiple":
        return <SelectMultiple {...commonProps} />;
      case "transfer":
        return (
          <SelectTransfer
            {...commonProps}
            customRender={customRender}
            titles={titles}
            searchFunction={searchFunction}
          />
        );
    }
  };

  const overrideCopy = ["multiple", "largeicons", "transfer"].includes(effectiveType);

  return (
    <FormRowWrapper {...props} overrideAllowCopy={!overrideCopy} effectiveRules={getRules()}>
      {getInputComponent()}
    </FormRowWrapper>
  );
};

export default Select;
