import http from "./httpService";
import config from "./config.json";

export function CreateProcess() {
  return { url: `${config.apiUrl}/ProcessAdmin/CreateProcess`, method: "POST" };
}

export function DeleteProcess(processId) {
  return { url: config.apiUrl + "/ProcessAdmin/DeleteProcess/" + processId, method: "DELETE" };
}

export function GetCreateTaskTemplate(processId) {
  return { url: `${config.apiUrl}/CreateTask/GetCreateTaskTemplate/${processId}`, method: "GET" };
}

export function GetCopyTask(taskId) {
  return { url: config.apiUrl + "/CreateTask/CopyTask/" + taskId, method: "GET" };
}

export function GetProcessCard(processId) {
  return http.get(config.apiUrl + "/Process/GetProcessCard/" + processId);
}

export function GetProcessNotificationHeader() {
  return {
    url: config.apiUrl + "/ProcessNotification/GetProcessNotificationHeader/",
    method: "GET",
  };
}

export function GetProcessNotificationList(processId) {
  return {
    url: config.apiUrl + "/ProcessNotification/GetProcessNotificationList/" + processId,
    method: "GET",
  };
}

export function ReviewProcessNotification() {
  return { url: config.apiUrl + "/ProcessNotification/ReviewProcessNotification", method: "POST" };
}

export function GetProcessSettings(processId) {
  return { url: config.apiUrl + "/ProcessAdmin/GetProcessSettings/" + processId, method: "GET" };
}
export function UpdateProcessSettings() {
  return { url: config.apiUrl + "/ProcessAdmin/UpdateProcessSettings/", method: "POST" };
}

export function GetProcessVariables(processId) {
  return {
    url: `${config.apiUrl}/ProcessAdmin/GetProcessVariables/${processId}`,
    method: "GET",
  };
}

export function UpdateProcessVariables() {
  return {
    url: `${config.apiUrl}/ProcessAdmin/UpdateProcessVariables/`,
    method: "POST",
  };
}

export function UpdateProcessGate() {
  return { url: `${config.apiUrl}/Process/UpdateProcessGate`, method: "POST" };
}

export function GetProcessHistory() {
  return { url: `${config.apiUrl}/Process/GetProcessHistory/`, method: "POST" };
}

export function UpdateDocumentation() {
  return {
    url: `${config.apiUrl}/ProcessAdmin/UpdateDocumentation/`,
    method: "POST",
  };
}

export function GetDocumentation(processId) {
  return {
    url: `${config.apiUrl}/ProcessAdmin/GetDocumentation/${processId}`,
    method: "GET",
  };
}

export function UpdateUserGuide() {
  return {
    url: `${config.apiUrl}/ProcessAdmin/UpdateUserGuide/`,
    method: "POST",
  };
}

export function GetUserGuide(processId) {
  return {
    url: `${config.apiUrl}/ProcessAdmin/GetUserGuide/${processId}`,
    method: "GET",
  };
}

export function SnoozeWarnings(processId) {
  return {
    url: `${config.apiUrl}/Process/SnoozeWarnings/${processId}`,
    method: "POST",
  };
}

export function CreateTaskFields() {
  return { url: `${config.apiUrl}/ProcessAdmin/CreateTaskFields`, method: "POST" };
}

export function GetProcessFields(processId) {
  return { url: `${config.apiUrl}/ProcessAdmin/GetProcessFields/${processId}`, method: "GET" };
}

export function DeleteTaskFields() {
  return { url: `${config.apiUrl}/ProcessAdmin/DeleteTaskFields`, method: "POST" };
}

export function UpdateTaskFieldSorting() {
  return { url: `${config.apiUrl}/ProcessAdmin/UpdateTaskFieldSorting`, method: "POST" };
}

export function UpdateTaskFields() {
  return { url: `${config.apiUrl}/ProcessAdmin/UpdateTaskFields`, method: "POST" };
}

export function GetMyLists() {
  return { url: `${config.apiUrl}/Process/GetMyLists`, method: "GET" };
}

export function GetProcessSelector() {
  return { url: `${config.apiUrl}/Process/GetProcessSelector`, method: "GET" };
}

export function AddFavoriteProcess(processId) {
  return http.post(`${config.apiUrl}/UserProfile/AddFavoriteProcess/${processId}`);
}

export function DeleteFavoriteProcess(processId) {
  return http.delete(`${config.apiUrl}/UserProfile/DeleteFavoriteProcess/${processId}`);
}

export function RoomForOneMoreProcess() {
  return { url: `${config.apiUrl}/Process/RoomForOneMoreProcess/`, method: "POST" };
}

export function ValidateCron() {
  return { url: `${config.apiUrl}/ProcessAdmin/ValidateCron/`, method: "POST" };
}
