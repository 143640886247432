import React from "react";
import { Skeleton, Divider, Button } from "antd";
import MainCard from "../../common/layout/mainCard/mainCard";

const TaskCardSkeleton = props => {
  return (
    <MainCard>
      <div style={{ margin: "0 20px" }}>
        <Skeleton title={true} paragraph={false} active />{" "}
        <div style={{ margin: "12px" }}>
          <div style={{ margin: "0 30px" }}>
            <Skeleton active paragraph={{ rows: 2 }} />
            <Button type="primary" style={{ width: "140px" }} />
            <Divider />
          </div>
          <div style={{ margin: "0 24px" }}>
            <Skeleton active paragraph={{ rows: 6 }} />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button style={{ width: "100px" }} />
              <Button style={{ width: "140px" }} />
            </div>
          </div>
        </div>
      </div>
    </MainCard>
  );
};

export default TaskCardSkeleton;
