import React from "react";

import { default as TextChild } from "./text";
import { default as ApproveButtonChild } from "./approveButton";
import { default as SelectChild } from "./select";
import { default as NumberInputChild } from "./NumberInput";
import { default as BoolChild } from "./bool";
import { default as PasswordChild } from "./password";
import { default as ListChild } from "./list";
import { default as TableChild } from "./table";
import { default as FileUploaderComponent } from "./fileUploader";
import { default as DatePickerComponent } from "./datePicker";
import { default as RichTextComponent } from "./richText";
import { default as CronComponent } from "./cronPicker";
import { default as ColorComponent } from "./color";
import { default as CountryChild } from "./countryPicker";
import { default as UserChild } from "./userPicker";
import { default as MultipleInputChild } from "./multipleInput";

export const renderChild = (displayName, inputProps) => {
  if (displayName === "Text") {
    return <TextChild {...inputProps} />;
  } else if (displayName === "CustomKey") {
    return <TextChild {...inputProps} masterType={"key"} />;
  } else if (displayName === "ApproveButton") {
    return <ApproveButtonChild {...inputProps} />;
  } else if (displayName === "Select") {
    return <SelectChild {...inputProps} />;
  } else if (displayName === "NumberInput" || displayName === "Number") {
    return <NumberInputChild {...inputProps} />;
  } else if (displayName === "Bool") {
    return <BoolChild {...inputProps} />;
  } else if (displayName === "Password") {
    return <PasswordChild {...inputProps} />;
  } else if (displayName === "List" || displayName === "JsonArray") {
    return <ListChild {...inputProps} />;
  } else if (displayName === "Table" || displayName === "JsonTable") {
    return <TableChild {...inputProps} />;
  } else if (displayName === "FileUploader") {
    return <FileUploaderComponent {...inputProps} />;
  } else if (displayName === "DatePicker" || displayName === "Date") {
    return <DatePickerComponent {...inputProps} />;
  } else if (displayName === "RichText" || displayName === "RichText") {
    return <RichTextComponent {...inputProps} />;
  } else if (displayName === "Cron" || displayName === "CronInput") {
    return <CronComponent {...inputProps} />;
  } else if (displayName === "Color") {
    return <ColorComponent {...inputProps} />;
  } else if (displayName === "Email") {
    return <TextChild {...inputProps} masterType="email" />;
  } else if (displayName === "Checkbox") {
    return <BoolChild {...inputProps} masterType="Checkbox" />;
  } else if (displayName === "Dropdown") {
    return <SelectChild {...inputProps} masterType="Dropdown" />;
  } else if (displayName === "Country") {
    return <CountryChild {...inputProps} />;
  } else if (displayName === "User") {
    return <UserChild {...inputProps} />;
  } else if (displayName === "MultipleInput") {
    return <MultipleInputChild {...inputProps} />;
  } else {
    return null;
  }
};
