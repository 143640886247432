import React from "react";
import { InputNumber } from "antd";

const NumberComponent = (props) => {
  const { onChange, onlyInteger, value, disabled, onBlur, placeholder, suffix, prefix } = props;

  const handleChange = (value) => {
    if (!value && value !== 0) {
      onChange(null);
    } else if (onlyInteger) {
      onChange(Math.floor(value));
    } else {
      onChange(value);
    }
  };

  const isFullWidth = !suffix && !prefix;

  return (
    <div style={{ display: "flex", marginBottom: 4, width: "100%" }}>
      {prefix && <div style={{ margin: "6px 6px 0px 0px" }}>{prefix} </div>}
      <InputNumber
        placeholder={placeholder}
        style={isFullWidth ? { width: "100%", margin: 0 } : { width: 140 }}
        onChange={handleChange}
        value={value}
        disabled={disabled}
        onBlur={onBlur}
        step={1}
      />
      {suffix && <div style={{ margin: "6px 0px 0px 6px" }}> {suffix} </div>}
    </div>
  );
};

export default NumberComponent;
