import { useLocalStorage } from "@rehooks/local-storage";
import { useEffect } from "react";
import { useEnhancedReducer } from "./useEnhancedReducer";
import { defaultShortRanges, defaultLongRanges, defaultReportRanges } from "./../utils/timeFrames";
const asyncTimeHandlers = {};

const initialTimeData = {
  title: null,
  description: null,
  key: null,
  toDate: null,
  fromDate: null,
  advicedResolution: null,
  cycleResolution: { value: 1, resolution: "day" },
  resolution: null,
  arraySelect: null,
};

const getTimeRangeFromKey = (key) => {
  const foundRange = [...defaultLongRanges, ...defaultShortRanges, ...defaultReportRanges].find(
    (range) => range.key.toLowerCase() === key.toLowerCase()
  );
  return foundRange ? foundRange : null;
};

const getAdvicedResolution = (toDate, fromDate) => {
  if (toDate && fromDate) {
    const spanOfDays = toDate.diff(fromDate, "days");
    const advicedResolution = spanOfDays > 200 ? "month" : spanOfDays > 60 ? "week" : "day";
    return advicedResolution;
  } else return "day";
};

const getResolutionAdd = (useAdvicedResolution, toDate, fromDate) => {
  const advicedResolution = getAdvicedResolution(toDate, fromDate);
  const resolutionAdd = useAdvicedResolution ? { advicedResolution, resolution: null } : {};

  return resolutionAdd;
};

export function useTimeSelection(timeframeName, arraySelect, defaultValue = "last7Days") {
  const [timeFrameKey, setTimeFrameKey] = useLocalStorage(timeframeName, defaultValue);

  useEffect(() => {
    if (arraySelect) {
      dispatch({ type: "SET_ARRAY", array: arraySelect });
    }
  }, [arraySelect]);

  useEffect(() => {
    //checks if localstorage value is valid
    const ranges = [...defaultShortRanges, ...defaultLongRanges];
    const arrayOfKeys = ranges.map((range) => range.key.toLowerCase());
    if (!arrayOfKeys.includes(timeFrameKey.toLowerCase())) {
      setTimeFrameKey(arraySelect === "long" ? "last3Months" : "last7Days");
    }
  }, []);

  function timeReducer(state, action) {
    let resolutionAdd = {};

    switch (action.type) {
      case "SET_ARRAY":
        return { ...state, arraySelect: action.array };
      case "SET_TO_KEY":
        let newTimeFrame = getTimeRangeFromKey(action.timeKey);
        if (newTimeFrame) {
          setTimeFrameKey(newTimeFrame.key);
          resolutionAdd = getResolutionAdd(
            action.useAdvicedResolution,
            newTimeFrame.toDate,
            newTimeFrame.fromDate
          );

          let payload = {
            ...newTimeFrame,
            ...resolutionAdd,
            arraySelect: arraySelect,
          };

          return payload;
        } else {
          console.error("timekey not found", action);
          return { ...state };
        }
      case "SET_CUSTOM_RANGE":
        resolutionAdd = getResolutionAdd(
          action.useAdvicedResolution,
          action.toDate,
          action.fromDate
        );
        return {
          ...state,
          key: "custom",
          toDate: action.toDate,
          fromDate: action.fromDate,
          ...resolutionAdd,
        };
      case "SHIFT_TIME":
        const strictRanges = defaultReportRanges.map((point) => point.key);
        if (strictRanges.includes(state.key)) {
          return {
            ...state,
            toDate: state.toDate.add(action.value, action.resolution).endOf(action.resolution),
            fromDate: state.fromDate
              .add(action.value, action.resolution)
              .startOf(action.resolution),
          };
        } else {
          return {
            ...state,
            toDate: state.toDate.add(action.value, action.resolution),
            fromDate: state.fromDate.add(action.value, action.resolution),
          };
        }
      case "CHANGE_RESOLUTION":
        return {
          ...state,
          resolution: action.resolution,
        };
    }
  }

  const [[currentTimeFrame, dispatch], getCurrentData] = useEnhancedReducer(
    timeReducer,
    initialTimeData,
    undefined,
    asyncTimeHandlers
  );

  useEffect(() => {
    //sets start-value
    dispatch({
      type: "SET_TO_KEY",
      timeKey: timeFrameKey ? timeFrameKey : defaultValue,
      useAdvicedResolution: true,
    });
  }, []);

  return {
    dispatch,
    currentTimeFrame,
    getTimeRangeFromKey, //helper function
  };
}
