import React from "react";
import { getPrimaryPalette } from "./../../../utils/colorpalette";
import { Tag, Tooltip } from "antd";

const InfoTag = (props) => {
  const palette = getPrimaryPalette();
  const {
    tooltip,
    visible = true,
    title = null,
    value = null,
    style = {},
    children,
    color = null,
  } = props;

  if (visible === false) {
    return <span />;
  } else {
    return (
      <div style={{ display: "inline-block" }}>
        <Tooltip title={tooltip}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: palette.heavyFont,
              fontWeight: "bold",
              ...style,
            }}
          >
            {title}
            <div style={{ marginLeft: 2 }}>
              {value !== null && <Tag color={color}>{value}</Tag>} {children}
            </div>
          </div>
        </Tooltip>
      </div>
    );
  }
};

export default InfoTag;
