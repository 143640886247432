import React, { useContext, useState } from "react";
import { Menu, Dropdown, Button, Space, Popconfirm, Tooltip, message } from "antd";
import {
  LoadingOutlined,
  ArrowRightOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  DownOutlined,
  CheckOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { Utils } from "./../../../contexts/utils";
import { closedQueues } from "./../../../utils/queueTypes";
import { getPrimaryPalette } from "../../../utils/colorpalette";
const palette = getPrimaryPalette();

const TaskButton = (props) => {
  const {
    data,
    taskLockedByAnother,
    process,
    isSelectedForManualControl = false,
    style = {},
    dispatchQueue,
    refreshQueue,
    refreshTaskCard,
  } = props;

  const { queue, taskId, userAllowedToApprove } = data || {};
  const { dispatchModal } = useContext(Utils);
  const [showNotReadyTooltip, setShowNotReadyTooltip] = useState(false);

  const handleErrorOnMove = (effectiveMessage) => {
    refreshQueue();
    refreshTaskCard();
    effectiveMessage && message.error(effectiveMessage);
  };

  const handleSuccessOnMove = (effectiveMessage) => {
    refreshTaskCard();
    effectiveMessage && message.success(effectiveMessage);
  };

  const moveMenuOverlay = () => {
    return (
      <Menu>
        {queue !== "Manual" && (
          <Menu.Item
            key="4"
            onClick={() =>
              dispatchQueue({
                type: "MANUAL_TASKS",
                fromQueue: queue,
                taskIds: [taskId],
                onSuccess: handleSuccessOnMove,
                onError: () => handleErrorOnMove("Move failed - please try again"),
              })
            }
          >
            <ArrowRightOutlined /> Move to Manual
          </Menu.Item>
        )}
        {queue !== "Ready" && (
          <Menu.Item
            disabled={queue === "NotReady"}
            key="2"
            onClick={() =>
              dispatchQueue({
                type: "READY_TASKS",
                fromQueue: queue,
                taskIds: [taskId],
                onSuccess: handleSuccessOnMove,
                onError: () => handleErrorOnMove("Move failed - please try again"),
              })
            }
          >
            <Tooltip
              onVisibleChange={setShowNotReadyTooltip}
              visible={showNotReadyTooltip && queue === "NotReady"}
              title={"Fill out the missing data before you can move this task to Ready"}
            >
              <ArrowRightOutlined />
              Move to Ready
            </Tooltip>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  if (closedQueues.includes(queue)) {
    return (
      <Button
        icon={<CopyOutlined />}
        onClick={() => {
          dispatchModal({
            type: "SET_STATE",
            payload: {
              NewTask: {
                activeProcess: process.processId,
                copyOf: taskId,
                note: `Copy of Task Id #${taskId}`,
              },
            },
          });
          dispatchModal({
            type: "TOGGLE_VISIBILITY",
            visible: { NewTask: true, TaskCard: false },
          });
        }}
      >
        Copy
      </Button>
    );
  } else if (isSelectedForManualControl) {
    return (
      <Button
        block
        loading={false}
        disabled={taskLockedByAnother}
        type={"primary"}
        icon={<CheckOutlined />}
        onClick={() =>
          dispatchQueue({
            type: "SOLVE_TASKS",
            taskIds: [taskId],
            fromQueue: queue,
            onSuccess: handleSuccessOnMove,
            onError: () => handleErrorOnMove("Review failed - please try again"),
          })
        }
        style={{ marginBottom: 24 }}
      >
        Mark as Reviewed
      </Button>
    );
  } else if (userAllowedToApprove) {
    return (
      <Button
        block
        type="primary"
        onClick={() =>
          dispatchQueue({
            type: "APPROVE_TASKS",
            fromQueue: queue,
            taskIds: [taskId],
            onSuccess: handleSuccessOnMove,
            onError: () => handleErrorOnMove("Approval failed - please try again"),
          })
        }
        style={{ marginBottom: 24 }}
      >
        {false ? <LoadingOutlined /> : <CheckCircleOutlined />}
        Approve Task
      </Button>
    );
  } else {
    return (
      <div style={{ ...style }}>
        <Space>
          <Button
            block
            loading={false}
            disabled={taskLockedByAnother}
            style={{ backgroundColor: palette.green, borderColor: "transparent" }}
            type={["Ready", "Manual", "NotReady"].includes(queue) ? "primary" : ""}
            icon={<CheckOutlined />}
            onClick={() =>
              dispatchQueue({
                type: "SOLVE_TASKS",
                fromQueue: queue,
                taskIds: [taskId],
                onSuccess: handleSuccessOnMove,
                onError: () => handleErrorOnMove("Task could not be solved - please try again"),
              })
            }
          >
            Mark as Solved
          </Button>
          <Dropdown
            overlay={moveMenuOverlay}
            disabled={taskLockedByAnother}
            placement="bottomRight"
          >
            <Button disabled={taskLockedByAnother} loading={false}>
              <ArrowRightOutlined /> Move <DownOutlined />
            </Button>
          </Dropdown>
          <Button
            icon={<CopyOutlined />}
            onClick={() => {
              dispatchModal({
                type: "SET_STATE",
                payload: {
                  NewTask: {
                    activeProcess: process.processId,
                    copyOf: taskId,
                    note: `Copy of Task Id #${taskId}`,
                  },
                },
              });
              dispatchModal({
                type: "TOGGLE_VISIBILITY",
                visible: { NewTask: true, TaskCard: false },
              });
            }}
          >
            Copy
          </Button>
          <Popconfirm
            disabled={taskLockedByAnother}
            onConfirm={() =>
              dispatchQueue({
                type: "CANCEL_TASKS",
                fromQueue: queue,
                taskIds: [taskId],
                onSuccess: handleSuccessOnMove,
                onError: () => handleErrorOnMove("Cancel failed - please try again"),
              })
            }
            title="This will cancel the task. Are you sure?"
            okText="Yes"
            cancelText="No, back"
          >
            <Button danger disabled={taskLockedByAnother} icon={<DeleteOutlined />} loading={false}>
              Cancel
            </Button>
          </Popconfirm>
        </Space>
      </div>
    );
  }
};

export default TaskButton;
