import React from "react";
import MainCard from "./../mainCard/mainCard";
import { Typography } from "antd";

const HeadlineBox = (props) => {
  const {
    title,
    children,
    addRight,
    size = "large",
    description,
    addMainCard = false,
    style = {},
  } = props;

  const content = (
    <div
      style={{
        padding: "24px 0 24px 0px",
        display: "flex",
        justifyContent: "space-between",
        ...style,
      }}
    >
      <div style={{ paddingRight: 24 }}>
        <Typography.Title level={size === "large" ? 1 : size === "small" ? 4 : 3}>
          {title}
        </Typography.Title>
        <Typography.Text>{description}</Typography.Text>
      </div>
      <div>{addRight}</div>
    </div>
  );

  if (addMainCard) {
    return <MainCard>{content}</MainCard>;
  } else {
    return content;
  }
};

export default HeadlineBox;
