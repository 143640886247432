import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import { getPrimaryPalette } from "./../../../utils/colorpalette";
import { Tag } from "antd";
import QueueTag from "./queueTag";

const SlaTag = (props) => {
  const {
    dueDate,
    urgentDate,
    createdDate,
    activationDate,
    queue,
    style = {},
    empty = false,
  } = props;
  const [now, setNow] = useState(dayjs());
  const [title, setTitle] = useState(null);
  const [color, setColor] = useState(null);
  const palette = getPrimaryPalette();

  useEffect(() => {
    setAppearance();
  }, [queue, dueDate]);

  //const due = dayjs(dueDate).add(1, "day");

  const setAppearance = () => {
    if (queue !== "Solved" && queue !== "Obsolete") {
      if (dayjs(dueDate).isBefore(now)) {
        setTitle("Overdue");
        setColor(palette.orange);
      } else if (dayjs(urgentDate).isBefore(now)) {
        setColor(palette.yellow);
        setTitle("Urgent");
      }
    }
  };

  return (
    <span>
      {title !== null ? (
        <Tag color={color} id="slatag" onClick={(e) => e.stopPropagation()} style={{ ...style }}>
          {title}
        </Tag>
      ) : empty ? (
        <span />
      ) : (
        <QueueTag activeQueue={queue} style={{ ...style }} activationDate={activationDate} />
      )}
    </span>
  );
};

export default SlaTag;
