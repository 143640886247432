import React, { useState } from "react";
import { Button, Upload, Tooltip } from "antd";
import {
  CopyOutlined,
  FileExcelOutlined,
  UploadOutlined,
  CloseOutlined,
} from "@ant-design/icons";

const Base64Uploader = (props) => {
  const { disabled, onChange, value } = props;

  const [fileList, setFileList] = useState([]);

  const handleChange = (newValue) => {
    onChange(newValue);
  };

  const handleRemove = (file) => {
    setFileList([]);
    handleChange(null);
  };

  const saveFile = (name, data, type, isBinary) => {
    if (isBinary) {
      var bytes = new Array(data.length);
      for (var i = 0; i < data.length; i++) {
        bytes[i] = data.charCodeAt(i);
      }
      data = new Uint8Array(bytes);
    }

    var blob = new Blob([data], { type: type });
    let objectURL = window.URL.createObjectURL(blob);
    let anchor = document.createElement("a");

    anchor.href = objectURL;
    anchor.download = name;
    anchor.click();

    URL.revokeObjectURL(objectURL);
  };

  const currentFileName = () => {
    if (fileList.length > 0) {
      return fileList[0].name;
    } else {
      return "file";
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div style={{ width: "100%" }}>
        <Upload
          disabled={disabled}
          accept=".txt, .xlsx"
          fileList={fileList}
          onRemove={(file) => handleRemove(file)}
          beforeUpload={(file) => {
            const reader = new FileReader();

            reader.onload = (e) => {
              var base64 = btoa(e.target.result);
              handleChange(base64);
            };
            reader.readAsBinaryString(file);
            setFileList([file]);
            // Prevent upload
            return false;
          }}
        >
          {!value && (
            <Button disabled={disabled} block>
              <UploadOutlined /> Click to Upload
            </Button>
          )}
          {value && fileList.length > 0 && (
            <div>
              <FileExcelOutlined /> File uploaded{" "}
              {!disabled && (
                <CloseOutlined onClick={() => handleRemove(null)} />
              )}
            </div>
          )}
          <span />
        </Upload>
      </div>
      {value && (
        <Tooltip title="Download File">
          <Button
            style={{ marginLeft: 4 }}
            icon={<CopyOutlined />}
            onClick={() =>
              saveFile(
                currentFileName(),
                atob(value),
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                true
              )
            }
          />
        </Tooltip>
      )}
    </div>
  );
};

export default Base64Uploader;
