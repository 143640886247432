import React from "react";
import { Select, ConfigProvider } from "antd";

const SelectUser = (props) => {
  const {
    onChange,
    value,
    disabled,
    allowClear,
    autoFocus,
    onBlur,
    options,
    renderNoOptions,
    showSearch = true,
  } = props;

  const handleChange = (newValue) => {
    onChange(newValue);
    onBlur(newValue);
  };
  return (
    <ConfigProvider renderEmpty={renderNoOptions}>
      <Select
        autoFocus={!disabled && autoFocus}
        showSearch={showSearch}
        allowClear={allowClear}
        disabled={disabled}
        optionFilterProp={"title"}
        optionLabelProp={"title"}
        value={value}
        onChange={(e) => handleChange(e)}
      >
        {options.map((item) => {
          return (
            <Select.Option
              key={item.userId}
              value={item.userId}
              disabled={item.alreadySelected}
              title={item.fullName}
            >
              {item.fullName}
            </Select.Option>
          );
        })}
      </Select>
    </ConfigProvider>
  );
};

export default SelectUser;
