import React from "react";
import { FrownOutlined } from "@ant-design/icons";
import { getSpinner } from "../../../../utils/antdConfig";

const Loadscreen = (props) => {
  const {
    message,
    children,
    loading,
    showAnimation = true,
    api = null,
    style = {},
    top = 100,
    message500 = null,
  } = props;

  const getContent = () => {
    const apiAnswer =
      //only works on api's with the suceeded key
      api && api.data && api.data.succeeded !== undefined ? api.data : null;
    if (apiAnswer) {
      const { succeeded, responseCode, message } = apiAnswer;
      switch (responseCode) {
        case 500:
          return (
            <div style={{ textAlign: "center", marginTop: top }}>
              <FrownOutlined style={{ fontSize: 20 }} /> <br />
              {message500 ? (
                message500
              ) : (
                <span>
                  Server Error.
                  <br /> We are terribly sorry.
                  <br /> Please try again and reach out to us if the problem
                  persists
                </span>
              )}
            </div>
          );
        default:
          return <span />;
      }
    } else
      return (
        <div style={{ textAlign: "center" }}>
          {getSpinner()} <br />
          {message}
        </div>
      );
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          padding: 50,
        }}
      >
        {getContent()}
      </div>
    );
  } else {
    return children;
  }
};

export default Loadscreen;
