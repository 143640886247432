import React, { useContext, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Utils } from "./../../contexts/utils";
import { useLocalStorage } from "@rehooks/local-storage";
import { Alert, Button } from "antd";

import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import relativeTime from "dayjs/plugin/relativeTime";
import { CustomerSettingsContext } from "../../contexts/customerSettingsContext";

dayjs.extend(relativeTime);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const TrialButton = () => {
  const { push } = useHistory();
  const [visible, setVisible] = useState(true);
  const { permittor } = useContext(Utils);
  const customerSettings = useContext(CustomerSettingsContext);
  const { subscriptionStatus, periodEnd, hasCreditCard } =
    (customerSettings[0].data && customerSettings[0].data.object) || {};
  const [trialBannerAccepted, setTrialBannerAccepted] = useLocalStorage(
    "trialBannerAccepted",
    false
  );

  const permittedToAct = permittor("CustomerSettings");

  const isAcceptedToday = useMemo(() => {
    if (!trialBannerAccepted) {
      return false;
    }
    if (dayjs(trialBannerAccepted).isValid()) {
      if (dayjs(trialBannerAccepted).isSame(dayjs(), "day")) {
        return true;
      } else return false;
    } else {
      setTrialBannerAccepted(false);
      return false;
    }
  }, [trialBannerAccepted]);

  const handleSubscriptionButton = () => {
    setVisible(false);
    push(`/settings/subscription`);
    setTrialBannerAccepted(dayjs().format());
  };

  const getProps = () => {
    switch (subscriptionStatus) {
      case "trialing":
        const getTrialType = () => {
          if (dayjs().isSameOrBefore(dayjs(periodEnd).subtract(14, "day"), "day")) {
            return false;
          }
          if (dayjs().isSameOrBefore(dayjs(periodEnd).subtract(7, "day"), "day")) {
            return "info";
          } else if (dayjs().isAfter(dayjs(periodEnd).subtract(7, "day"), "day")) {
            return "warning";
          } else {
            return false;
          }
        };
        return {
          action: permittedToAct && (
            <Button size="small" type="ghost" onClick={handleSubscriptionButton}>
              Subscribe
            </Button>
          ),
          type: getTrialType(),
          message: (
            <span>
              {permittedToAct
                ? `Trial ending in ${dayjs(periodEnd).fromNow(true)}`
                : `Trial ending in ${dayjs(periodEnd).fromNow(
                    true
                  )}. Your Admin can setup a subscription`}
            </span>
          ),
        };
      case "canceled":
      case "cancelled":
        return {
          action: permittedToAct ? (
            <Button size="small" type="ghost" onClick={handleSubscriptionButton}>
              Re-activate
            </Button>
          ) : (
            <span>Admin can activate</span>
          ),
          type: "success",
          showIcon: false,
          message: (
            <span>
              Welcome back. Your account is locked but you can re-active any time you like.
            </span>
          ),
        };
      case "unpaid":
        return {
          type: "warning",
          action: permittedToAct ? (
            <Button size="small" type="ghost" onClick={handleSubscriptionButton}>
              Re-activate
            </Button>
          ) : (
            <span>Admin can activate</span>
          ),
          message: (
            <span>
              Oops - We have not received payment for several weeks. We have locked your account.
            </span>
          ),
        };
      case "past_due":
      case "incomplete":
      case "incomplete_expired":
        return {
          type: "warning",
          closable: false,
          action: permittedToAct ? (
            <Button size="small" type="ghost" onClick={handleSubscriptionButton}>
              Resolve
            </Button>
          ) : (
            <span>Admins need to resolve this</span>
          ),
          message: <span>Oops - we didnt get your payment. </span>,
        };
      default:
        return { type: false };
    }
  };

  const genericProps = {
    onClose: () => setTrialBannerAccepted(dayjs().format()),
    showIcon: true,
    closable: true,
  };

  return visible && getProps().type /*&& !isAcceptedToday*/ ? (
    <Alert {...genericProps} {...getProps()} />
  ) : (
    <span />
  );
};
export default TrialButton;
