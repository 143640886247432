import config from "./config.json";

export function GetTaskCard(taskId) {
  return {
    url: `${config.apiUrl}/TaskUpdate/GetTaskCard/${taskId}`,
    method: "GET",
  };
}

export function TaskUpdateSolve() {
  return { url: config.apiUrl + "/TaskUpdate/Solve/", method: "POST" };
}

export function TaskUpdateCancel() {
  return { url: config.apiUrl + "/TaskUpdate/Cancel", method: "POST" };
}

export function TaskUpdateReady() {
  return { url: config.apiUrl + "/TaskUpdate/Ready", method: "POST" };
}

export function TaskUpdateManual() {
  return { url: config.apiUrl + "/TaskUpdate/Manual", method: "POST" };
}

export function WriteNote() {
  return { url: config.apiUrl + "/TaskUpdate/WriteNote/", method: "POST" };
}

export function TryApproveTask() {
  return { url: config.apiUrl + "/TaskUpdate/TryApproveTask/", method: "POST" };
}

export function ModifyTaskCards() {
  return {
    url: config.apiUrl + "/TaskUpdate/ModifyTaskCards",
    method: "POST",
  };
}

export function ModifyTaskCard() {
  return {
    url: config.apiUrl + "/TaskUpdate/ModifyTaskCard",
    method: "POST",
  };
}

export function CreateSingleTask() {
  return { url: config.apiUrl + "/CreateTask/CreateSingleTask/", method: "POST" };
}

export function CreateManyTasks(processId) {
  return { url: `${config.apiUrl}/CreateTask/CreateManyTasks/${processId}`, method: "POST" };
}

//asks backend to unlock the task. Only works if its the same user that locked it
export function TryUnlockTask(taskId) {
  return { url: config.apiUrl + "/TaskUpdate/TryUnlockTask/" + taskId, method: "PUT" };
}

export function UnlockAllTasks(processId) {
    return {
        url: config.apiUrl + `/TaskUpdate/UnlockAllTasks/${processId}`, method: "POST" };
}

export function ForceUnlockTask() {
  return { url: `${config.apiUrl}/TaskUpdate/ForceUnlockTasks`, method: "POST" };
}

export function UpdateActivationDate() {
  return { url: `${config.apiUrl}/TaskUpdate/UpdateActivationDate`, method: "POST" };
}
