import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

const SuspenseLoader = () => {
  return (
    <div
      style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: 100 }}
    >
      <LoadingOutlined />
    </div>
  );
};

export default SuspenseLoader;
