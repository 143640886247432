export function encodeFieldToExcel(value, fieldType) {
  switch (fieldType) {
    case "Date":
      if (!value) {
        return null;
      } else if ("9999-12-31" === value.substring(0, 10)) {
        return null;
      } else {
        return new Date(value);
      }
    case "Number":
      return value ? Number(value) : null;
    case "Checkbox":
      return value ? (value.toLowerCase() === "true" ? true : false) : false;
    case "ExcelFile":
      return value ? "File Uploaded" : null;
    case "JsonTable":
      return value ? "See Table in Anymate" : null;
    case "Status":
      return value ? value.text : null;
    default:
      return value;
  }
}

export function fitToColumn(data, columnOrder, tasteLength = 100) {
  const wscols = columnOrder.map((column) => {
    let effectiveLength = column.length;
    for (let i = 0; i < (data.length > tasteLength ? tasteLength : data.length); i++) {
      const proposedLength = data[i][column] && data[i][column].length;

      if (proposedLength > effectiveLength) {
        effectiveLength = proposedLength;
      }
    }
    return { wch: effectiveLength + 5 };
  });

  return wscols;
}

export function sumUpTotals(data) {
  return data;
}
