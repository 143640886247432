import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Quill = (props) => {
  const { disabled, onChange, value, style = {} } = props;
  const modules = !disabled
    ? {
        toolbar: [
          ["bold", "italic", "underline"], // toggled buttons
          ["blockquote"],

          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }], // superscript/subscript
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

          [{ size: ["small", false, "large", "huge"] }], // custom dropdown
          [{ header: [1, 2, 3, false] }],

          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ align: [] }],
        ],
      }
    : { toolbar: false };

  const handleValueChange = (newValue, delta, source) => {
    if (newValue.trim() === "<p><br></p>") {
      //catching some shenanigans in quill if the page is blank
      onChange(null);
    }
    if (value !== newValue && source !== "api") {
      onChange(newValue);
    }
  };

  return (
    <ReactQuill
      style={{ backgroundColor: "#fff", ...style, width: "100%" }}
      readOnly={disabled}
      modules={modules}
      theme="snow"
      defaultValue={value || ""}
      onChange={handleValueChange}
    />
  );
};

export default Quill;
