import { useEffect, useDebugValue, useContext } from "react";
import useAxios from "axios-hooks";
import { AuthContext } from "./../contexts/authContext";

export function useHttp(api, settings) {
  useDebugValue(api.name);
  const { signinRedirect } = useContext(AuthContext);

  const axios = useAxios(api(), settings);

  useEffect(() => {
    if (axios[0].error && axios[0].error.response) {
      if (axios[0].error.response.status === 401) {
        console.log("redirecting to singin");
        signinRedirect();
      }
    }
  }, [axios[0].error]);

  return axios;
}
