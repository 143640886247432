import React from "react";
import { Select, ConfigProvider } from "antd";

const SelectMultiple = (props) => {
  const { onChange, value, disabled, allowClear, onBlur, options, renderNoOptions } = props;

  const handleChange = (newValue) => {
    onChange(newValue);
  };

  const handleDeselect = (deselectedValue) => {
    const newValue =
      value && value.length > 0 ? [...value].filter((key) => key !== deselectedValue) : [];
    onChange(newValue);
    onBlur(newValue);
  };

  return (
    <ConfigProvider renderEmpty={renderNoOptions}>
      <Select
        value={value || []}
        allowClear={allowClear}
        disabled={disabled}
        mode="multiple"
        onChange={handleChange}
        onDeselect={handleDeselect}
        //onBlur={handleBlur}
        onDropdownVisibleChange={(visible) => !visible && onBlur(value)}
        optionFilterProp="children"
        filterOption={(input, option) => {
          return option.title.toLowerCase().indexOf(input && input.toLowerCase()) >= 0;
        }}
      >
        {options.map((item) => (
          <Select.Option key={item.selectValue} value={item.selectValue} title={item.title}>
            {item.title}
          </Select.Option>
        ))}
      </Select>
    </ConfigProvider>
  );
};

export default SelectMultiple;
