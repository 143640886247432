import axios from "axios";
import { configure } from "axios-hooks";

//currently using both axios, and the hook for simple stuff.

const setAxiosHeaders = (accessToken) => {
  //sets header
  if (!accessToken) {
    console.error("attempting to set a null accesstoken");
  } else {
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    const axiosConfig = axios.create();
    axiosConfig.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    configure({ axios: axiosConfig });
  }
};

export default {
  setAxiosHeaders,

  do: axios,
  //legacy
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
