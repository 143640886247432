import http from "./httpService";
import config from "./config.json";

export function ChangePassword() {
  return {
    url: `${config.apiUrl}/UserProfile/ChangePassword/`,
    method: "POST",
  };
}

export function ChangeFullName() {
  return {
    url: `${config.apiUrl}/UserProfile/ChangeFullName/`,
    method: "POST",
  };
}

export function RequestChangeEmail() {
  return {
    url: `${config.apiUrl}/UserProfile/RequestChangeEmail/`,
    method: "POST",
  };
}

export function ChangeAvatarBackground() {
  return {
    url: `${config.apiUrl}/UserProfile/ChangeAvatarBackground/`,
    method: "POST",
  };
}

export function ChangePhoneNumber() {
  return {
    url: `${config.apiUrl}/UserProfile/ChangePhoneNumber/`,
    method: "POST",
  };
}

export function ChangeTimeZone() {
  return {
    url: `${config.apiUrl}/UserProfile/ChangeTimeZone/`,
    method: "POST",
  };
}

export function ChangeTimeFormat() {
  return {
    url: `${config.apiUrl}/UserProfile/ChangeTimeFormat/`,
    method: "POST",
  };
}

export function ChangeDateFormat() {
  return {
    url: `${config.apiUrl}/UserProfile/ChangeDateFormat/`,
    method: "POST",
  };
}

export function GetTimeZoneDropdown() {
  return {
    url: `${config.apiUrl}/UserProfile/GetTimeZoneDropdown/`,
    method: "GET",
  };
}

export function GetDateFormatDropdown() {
  return {
    url: `${config.apiUrl}/UserProfile/GetDateFormatDropdown/`,
    method: "GET",
  };
}

export function GetTimeFormatDropdown() {
  return {
    url: `${config.apiUrl}/UserProfile/GetTimeFormatDropdown/`,
    method: "GET",
  };
}
