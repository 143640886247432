import React, { useContext, useState } from "react";
import { DatePicker, TimePicker, Button, Typography } from "antd";
import moment from "moment";
import { UserDB } from "./../../../../../contexts/userDB";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { getDateTimeFormat } from "../../formHelpers";
import ConditionalWrapper from "../../../conditionalWrapper";

const DateInput = (props) => {
  const {
    onChange,
    showAs,
    style = {},
    value,
    allowClear,
    disabled,
    onBlur,
    fieldName,
    defaultShowTime = true,
    picker,
    disablePast = false,
    showSeconds,
    description,
  } = props;

  const { currentUser } = useContext(UserDB);
  const { dateFormat, timeFormat, timeZone } = currentUser || {};
  const [timeAddSelected, setTimeAddSelected] = useState(defaultShowTime);

  const handleTimeToggler = (newSetting) => {
    setTimeAddSelected(newSetting);
    //preventing change if its an infodate because change is reserved for button press
    if (!newSetting && showAs !== "infodate") {
      const newValue = value ? moment(value).startOf("day") : null;
      onChange(newValue);
      onBlur(newValue);
    }
  };

  const handleSelect = (picker, origin) => (newDate) => {
    let sendDate = moment(newDate);
    //removes seconds if they are not to be shown
    if (["time", "datetime"].includes(picker)) {
      !showSeconds && sendDate.startOf("minute");
    }
    if (showAs === "infodate" && !origin) {
      //pass
    } else {
      onChange(sendDate);
      onBlur(sendDate);
    }
  };

  const handleChange = (newValue) => {
    //used for allowclear
    if (!newValue) {
      onChange(null);
      onBlur(null);
    } else {
      //used for "now"
      onChange(newValue);
      onBlur(newValue);
    }
  };

  const pickerProps = () => {
    switch (picker) {
      case "time":
        return {
          picker,
          format: getDateTimeFormat(picker, dateFormat, timeFormat, showSeconds),
          onSelect: handleSelect(picker),
          onOk: handleSelect(picker, "ok"),
        };
      case "datetime":
        return {
          format: getDateTimeFormat(picker, dateFormat, timeFormat, showSeconds),
          onSelect: handleSelect(picker),
          onOk: handleSelect(picker, "ok"),
          showTime: timeAddSelected
            ? { format: getDateTimeFormat("time", dateFormat, timeFormat, showSeconds) }
            : false,
          renderExtraFooter: () =>
            !timeAddSelected ? (
              <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                <Button type="text" onClick={() => handleTimeToggler(true)}>
                  show clock <RightOutlined />
                </Button>
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                <Button type="text" onClick={() => handleTimeToggler(false)}>
                  <LeftOutlined /> hide clock
                </Button>
              </div>
            ),
        };
      case "date":
        return {
          format: getDateTimeFormat(picker, dateFormat, timeFormat, showSeconds),
          onSelect: handleSelect(picker),
          picker,
        };
      default:
        return { picker, onSelect: handleSelect(picker) };
    }
  };

  const disabledProps = () =>
    disablePast
      ? {
          disabledDate: (current) => {
            return current && current < moment().subtract(1, "day").endOf("day");
          },
        }
      : {};

  const showAsWrapper = (children) => (
    <div style={{ width: "100%" }}>
      <Typography.Text strong>{fieldName}</Typography.Text>
      {children}
      <div>
        <Typography.Text
          type="secondary"
          style={{ fontSize: 10, margin: 0, padding: 0, fontStyle: "italic" }}
        >
          {description}
        </Typography.Text>
      </div>
    </div>
  );

  if (picker === "time") {
    return (
      <ConditionalWrapper condition={showAs === "infodate"} wrapper={showAsWrapper}>
        <TimePicker
          {...disabledProps()}
          {...pickerProps()}
          onChange={handleChange}
          value={!value ? null : moment(value).isValid() ? moment(value) : null}
          allowClear={allowClear}
          disabled={disabled}
          style={{ width: "100%", ...style }}
        />
      </ConditionalWrapper>
    );
  }
  return (
    <ConditionalWrapper condition={showAs === "infodate"} wrapper={showAsWrapper}>
      <DatePicker
        {...disabledProps()}
        {...pickerProps()}
        value={!value ? null : moment(value).isValid() ? moment(value) : null}
        onChange={handleChange}
        allowClear={allowClear}
        disabled={disabled}
        style={{ width: "100%", ...style }}
      />
    </ConditionalWrapper>
  );
};

export default DateInput;
