import React, { useContext, useEffect, useState, Suspense } from "react";
import { useFeature } from "feature-toggle-jsx";

import { Menu, Space, Badge } from "antd";
import { useParams, useRouteMatch, Link } from "react-router-dom";
import { getLazyComponent } from "./../../utils/routerHelpers";
import TaskSearch from "./taskSearch";
import AddNew from "./addNew";

import { Utils } from "./../../contexts/utils";
import { ProcessTeams } from "./../../contexts/processTeams";
import paths from "../../utils/paths";
import HelmetComponent from "../common/layout/defaultPages/helmetComponent";
import ProfileMenu from "./profileMenu";
import { useWindowWidth } from "@react-hook/window-size";
import LoaderMessage from "../common/loaders/loaderMessage";
import { getPrimaryPalette } from "./../../utils/colorpalette";

import {
  SettingOutlined,
  CarryOutOutlined,
  ProjectOutlined,
  ProfileOutlined,
  UsergroupAddOutlined,
  InfoCircleFilled,
  FundOutlined,
} from "@ant-design/icons";
import FullLogo from "../../img/logo/fullLogo";
import TrialButton from "./trialButton";
import { CustomerSettingsContext } from "../../contexts/customerSettingsContext";

const MainTour = getLazyComponent(() => import("../tour/mainTour"));
const DeveloperConsole = getLazyComponent(() => import("./developerConsole"));

const palette = getPrimaryPalette();

const NavbarHeader = ({ DepartmentsViewPreload, overridePermissions, allowedPermissions }) => {
  const { menu } = useParams();
  const [_, config] = useFeature("developerConsole");
  const [activeRouteMatch, setActiveRouteMatch] = useState(`/`);

  const width = useWindowWidth();
  const target = React.useRef(null);

  useEffect(() => {
    setActiveRouteMatch(paths.getPathFromMenuParam(menu));
  }, [menu]);

  const routeMatch = useRouteMatch(activeRouteMatch);
  const { params } = routeMatch || {};
  const { processKey = null } = params || {};

  const { permittor, dispatchModal, tour } = useContext(Utils);

  const { getProcess } = useContext(ProcessTeams);
  const customerSettings = useContext(CustomerSettingsContext);
  const { isCustomerInfoOk } = (customerSettings[0].data && customerSettings[0].data.object) || {};

  /*preset new task modal with process if it exists and has tasks
  Redo when configurable task creation rules are added*/
  useEffect(() => {
    if (processKey) {
      const process = getProcess(processKey);
      dispatchModal({
        type: "SET_STATE",
        payload: {
          NewTask: {
            activeProcess: process && process.processHasTasks ? process.processId : null,
            copyOf: null,
            note: null,
          },
        },
      });
    }
  }, [processKey]);

  const menuStructure = [
    {
      icon: <CarryOutOutlined />,
      title: "Tasks",
      key: "tasks",
      permission: "AgentPermissions",
    },
    {
      icon: <ProjectOutlined />,
      title: "Overview",
      key: "overview",
      permission: "AgentPermissions",
    },
    {
      icon: <ProfileOutlined />,
      title: "Processes",
      key: "process",
      permission: "AgentPermissions",
    },
    {
      icon: <FundOutlined />,
      title: "Reports",
      key: "reports",
      permission: "AgentPermissions",
    },
    {
      icon: <UsergroupAddOutlined />,
      title: "Teams",
      key: "team",
      permission: "AgentPermissions",
      //tried preloading here
      onHover: DepartmentsViewPreload,
    },
    {
      icon: <SettingOutlined />,
      title: (
        <span>
          Settings
          <Badge
            offset={[20, -6]}
            count={
              isCustomerInfoOk === false ? <InfoCircleFilled style={{ color: palette.main }} /> : 0
            }
          ></Badge>
        </span>
      ),
      key: "settings",
      permission: ["CustomerSettings", "OrganisationAdmin", "DeveloperPermissions"],
    },
  ];

  const permittedMenu = menuStructure.filter(
    (menuPoint) => !menuPoint.permission || permittor(menuPoint.permission)
  );

  return (
    <div style={{ paddingTop: 20, backgroundColor: "#fff", position: "relative" }}>
      <HelmetComponent
        title={`${menu ? menu.charAt(0).toUpperCase() + menu.slice(1) + " - " : ""}Anymate`}
      />
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          borderBottom: "1px solid lightgrey",
        }}
      >
        {width > 1150 && (
          <div style={{ flexGrow: 0 }}>
            <Link to={`/`}>
              <div style={{ width: 120, margin: "6px 0px 0px 24px" }} className="clickable">
                <FullLogo />
              </div>
            </Link>
          </div>
        )}
        <div ref={target} style={{ flexGrow: 1 }}>
          <Menu
            mode="horizontal"
            selectedKeys={[menu]}
            style={{
              border: "none",
            }}
          >
            {permittedMenu.map((menu) => {
              return (
                <Menu.Item
                  key={menu.key}
                  maintour={`${menu.key}-menu`}
                  onMouseOver={menu.onHover || null}
                >
                  <Link to={`/${menu.key}`}>
                    {menu.icon}
                    <span style={{ fontWeight: 800 }}>{menu.title}</span>
                  </Link>
                </Menu.Item>
              );
            })}
          </Menu>
        </div>
        <div style={{ flexGrow: 0 }}>
          <Space direction="horizontal" style={{ marginRight: 28 }}>
            {permittor("AgentPermissions") && (
              <TaskSearch
                maintour="search-menu"
                drawKeySelect={false}
                type="compact"
                collapsed={width < 1000}
              />
            )}
            {permittor(["ProcessCreateAndDelete", "CreateTasks", "OrganisationAdmin"]) && (
              <AddNew permittor={permittor} />
            )}
            {permittor("AgentPermissions") && <ProfileMenu />}
          </Space>
        </div>
      </div>
      {tour.state.visible.main && (
        <Suspense fallback={<LoaderMessage />}>
          <MainTour />
        </Suspense>
      )}
      {config.isEnabled && (
        <Suspense fallback={<span />}>
          <DeveloperConsole
            overridePermissions={overridePermissions}
            allowedPermissions={allowedPermissions}
          />
        </Suspense>
      )}
      <TrialButton />
    </div>
  );
};

export default NavbarHeader;
