import React, { useContext } from "react";
import { Typography, Comment } from "antd";

import LockedTag from "./../../common/tags/lockedTag";
import { activeQueues } from "./../../../utils/queueTypes";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import UserAvatar from "./../../common/typography/userAvatar";

import { UserDB } from "./../../../contexts/userDB";

dayjs.extend(relativeTime);

const { Text, Paragraph } = Typography;

const InfoRibbon = (props) => {
  const { isSelectedForManualControl, data = {}, currentQueueObject } = props;
  const {
    approvedState,
    userAllowedToApprove,
    reason,
    queue,
    taskLog,
    taskIsLockedForUserToEdit,
    taskLockedDate,
    taskLockedBy,
    taskId,
    activationDate,
  } = data;
  const { getUser } = useContext(UserDB);

  const renderLatestComment = () => {
    let commentsInLog =
      taskLog && taskLog.filter((log) => log.actionType === "Comment");

    if (
      !commentsInLog ||
      commentsInLog.length === 0 ||
      isSelectedForManualControl ||
      queue === "Obsolete"
    ) {
      return <span />;
    } else {
      let userWhoCommentedId =
        commentsInLog[commentsInLog.length - 1].actionUserId;
      const userWhoCommented = getUser(userWhoCommentedId);
      return (
        <div
          style={{
            width: "100%",
            overflow: "hidden",
            textAlign: "left",
            padding: 24,
          }}
        >
          <Comment
            author={userWhoCommented.fullName}
            avatar={<UserAvatar user={userWhoCommented} />}
            content={
              <Paragraph
                ellipsis={{
                  rows: 4,
                  expandable: true,
                  symbol: "more",
                }}
              >
                {commentsInLog[commentsInLog.length - 1].noteText}
              </Paragraph>
            }
          />
        </div>
      );
    }
  };

  const renderReasonTitle = () => {
    if (taskIsLockedForUserToEdit && activeQueues.includes(queue)) {
      return null;
    } else if (approvedState === "NeedsApproval" && !userAllowedToApprove) {
      return "Task needs Approval";
    } else if (isSelectedForManualControl) {
      return "Task is Solved but your action is required";
    } else if (queue === "Obsolete") {
      return "Task was cancelled";
    } else if (dayjs(activationDate).isAfter(dayjs(), "day")) {
      return "Task is not active yet";
    } else {
      return currentQueueObject ? currentQueueObject.description : null;
    }
  };

  const renderReasonText = () => {
    if (taskIsLockedForUserToEdit && activeQueues.includes(queue)) {
      return (
        <React.Fragment>
          <LockedTag
            type="ghost"
            size={"large"}
            taskLockedDate={taskLockedDate}
            taskLockedBy={taskLockedBy}
            taskId={taskId}
          />

          <br />
          <Text style={{ color: "#ccc", fontStyle: "italic" }}>
            click to force unlock
          </Text>
        </React.Fragment>
      );
    } else if (isSelectedForManualControl) {
      return "Please review the work that the robot did";
    } else if (approvedState === "NeedsApproval" && !userAllowedToApprove) {
      return "you are not autorized to approve";
    } else if (queue === "Ready") {
      if (dayjs(activationDate).isAfter(dayjs(), "day")) {
        return `Task will be active in ${dayjs(activationDate).fromNow()}`;
      } else {
        return "";
      }
    } else {
      return reason;
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          width: "100%",
          minHeight: 80,
          padding: 20,
          backgroundColor: currentQueueObject
            ? currentQueueObject.color
            : "#fff",

          textAlign: "center",
        }}
      >
        <Text style={{ color: "#fff" }}>{renderReasonTitle()}</Text>
        <br />
        <Text strong style={{ color: "#fff" }}>
          {renderReasonText()}
        </Text>
      </div>
      <div>{renderLatestComment()}</div>
    </React.Fragment>
  );
};

export default InfoRibbon;
