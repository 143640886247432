import React, { useState, useContext } from "react";
import { Timeline, Tag, Typography } from "antd";
import moment from "moment";
import UserPresentationTag from "../../common/typography/userPresentationTag";

import { PlusCircleOutlined, CheckOutlined, CommentOutlined } from "@ant-design/icons";
import { getPrimaryPalette } from "./../../../utils/colorpalette";
import { UserDB } from "./../../../contexts/userDB";

const palette = getPrimaryPalette();

const AuditTrail = (props) => {
  const { taskLog = [], style = {} } = props;
  const [reversed, setReversed] = useState(false);
  const { getUser } = useContext(UserDB);

  const addDot = (actionType) => {
    let dotProp = {};

    switch (actionType) {
      case "TaskCreated":
        dotProp.dot = <PlusCircleOutlined />;
        break;
      case "Ready":
        break;
      case "Comment":
        dotProp.dot = <CommentOutlined />;
        break;
      case "Solved":
        dotProp.dot = <CheckOutlined style={{ color: palette.green }} />;
        break;
      case "Manual":
        break;
      default: //default is defined in declaration of dotProp
    }

    return dotProp;
  };

  const addReason = (actionType, noteText) => {
    switch (actionType) {
      case "Solved":
      case "Manual":
        return (
          <React.Fragment>
            <br />
            <span>Reason: {noteText}</span>
          </React.Fragment>
        );

      default:
        return <span />;
    }
  };

  return (
    <div style={{ ...style }}>
      <Typography.Text
        className="clickable"
        onClick={() => setReversed(!reversed)}
        type="secondary"
        style={{ fontStyle: "italic" }}
      >
        {reversed ? "newest on top" : "oldest on top"}
      </Typography.Text>
      <Timeline reverse={reversed} style={{ marginTop: 12 }}>
        {taskLog.map((point) => {
          const activeUser = getUser(point.actionUserId);

          return (
            <Timeline.Item key={point.taskActionId} {...addDot(point.actionType)}>
              <div style={{ margin: "0 0 8px 0" }}>
                {moment(point.actionDateTime).format("LLL")}
              </div>
              <Tag style={{ margin: "0px" }}>{point.actionType}</Tag> by{" "}
              <UserPresentationTag type="small" user={activeUser} />{" "}
              {addReason(point.actionType, point.noteText)}
            </Timeline.Item>
          );
        })}
      </Timeline>
    </div>
  );
};

export default AuditTrail;
