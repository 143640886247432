import React from "react";

import Base64Uploader from "../inputComponents/files/base64Uploader";
import FormRowWrapper from "./../formRowWrapper";
import ExcelUploader from "../inputComponents/files/excelUploader";

const FileUploader = (props) => {
  const {
    formProps,
    onBlur,
    masterType,
    apiCheck,
    allowNulls,
    dataIndex,
    fieldName,
    type = "ExcelBase64",
    autoFocus = false,
    isUnlockedForEditing,
    fieldMetaData,
    fieldRenderData,
  } = props;

  const { disabled } = formProps;

  const getInputComponent = () => {
    switch (type) {
      case "ExcelBase64":
        return <Base64Uploader disabled={disabled} />;

      case "excel":
        return <ExcelUploader disabled={disabled} />;
    }
  };

  return (
    <FormRowWrapper
      {...props}
      effectiveRules={[{ required: !allowNulls }]}
      overrideAllowCopy={false}
    >
      {getInputComponent()}
    </FormRowWrapper>
  );
};

export default FileUploader;
