import React, { useMemo, useContext } from "react";
import { Cascader, Typography, ConfigProvider } from "antd";
import { Utils } from "./../../contexts/utils";
import { DownOutlined, MehOutlined } from "@ant-design/icons";
import { ProcessTeams } from "./../../contexts/processTeams";

const ProcessSelector = (props) => {
  const {
    onChange,
    allowClear = true,
    showHeaders = false,
    value = null,
    type = "cascader",
    label = "full",
    keyOption = "processId",
    style = {},
    showOnlyTasks = false,
  } = props;

  const { permittor } = useContext(Utils);
  const { getProcess, processSelector } = useContext(ProcessTeams);

  const processOptions = showOnlyTasks ? processSelector.withTasks : processSelector.all;
  const currentProcess = getProcess(value, keyOption);

  const calculatePath = () => {
    const currentProcessId = currentProcess && currentProcess.processId;
    let path = [];
    if (processOptions.length === 0) return path;

      for (let i = 0; i < processOptions.length; i++) {

      let topLevelItem = processOptions[i];
      if (!topLevelItem.children) {
        if (topLevelItem.value === currentProcessId) {
          path = [topLevelItem.value];
          return path;
        }
      } else {
          for (let k = 0; k < processOptions[i].children.length; k++) {

              let secondLevelItem = topLevelItem.children[k];

          if (!secondLevelItem.children) {
            if (secondLevelItem.value === currentProcessId) {
              path = [topLevelItem.value, secondLevelItem.value];
              return path;
            }
          } else {
            for (let j = 0; j < secondLevelItem.children.length; j++) {
              let thirdLevelItem = secondLevelItem.children[j];
              if (thirdLevelItem.value === currentProcessId) {
                path = [topLevelItem.value, secondLevelItem.value, thirdLevelItem.value];
                return path;
              }
            }
          }
        }
      }
    }
    return path;
  };

  const effectivePath = useMemo(calculatePath, [value, processSelector]);

  const handleClickProcess = (newValue, selectedOptions) => {
    const processId = newValue[newValue.length - 1];
    const newProcess = getProcess(processId);
    onChange(newProcess, selectedOptions);
  };

  const getDisplayValue = () => {
    switch (type) {
      case "link":
        return (
          <a className="ant-dropdown-link" href="#">
            <span>{/*processName !== null ? processName :*/ "Process"}</span>
          </a>
        );
      case "title":
        //dno why but needs the <a> to stop a black border
        return (
          <a href="#">
            <Typography.Title
              level={4}
              style={{ margin: 0, padding: 4 }}
              className="clickable hovergrey"
            >
              {currentProcess ? currentProcess.processName : "Process"}{" "}
              <DownOutlined style={{ fontSize: 14, marginLeft: 6 }} />
            </Typography.Title>
          </a>
        );
      default:
        return null;
    }
  };

  function filterSearch(inputValue, path) {
    return path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  }

  const renderNoOptions = () => {
    return (
      <div style={{ textAlign: "center", padding: 30 }}>
        No processes here <MehOutlined /> <br />
        {permittor("ProcessCreateAndDelete") && (
          <div>You can create new processes in the process menu</div>
        )}
      </div>
    );
  };

  const displayRender = (selection, selectedOptions) => {
    switch (label) {
      case "full":
        return selection.join(" / ");
      case "process":
        return selection[selection.length - 1];
    }
  };

  return (
    <div style={{ ...style }}>
      {showHeaders && (
        <Typography.Text strong style={{ marginRight: 6 }}>
          Process
        </Typography.Text>
      )}
      <ConfigProvider renderEmpty={renderNoOptions}>
        <Cascader
          allowClear={allowClear}
          onChange={handleClickProcess}
          value={effectivePath}
          placeholder="Please select Process"
          style={{ width: "100%" }}
          options={processOptions}
          showSearch={{ ...filterSearch }}
          bordered={type === "title" ? false : true}
          displayRender={displayRender}
        >
          {getDisplayValue()}
        </Cascader>
      </ConfigProvider>
    </div>
  );
};

export default ProcessSelector;

/*

 <div
            className="clickable hovergrey"
            style={{ display: "flex", alignItems: "center", padding: 6 }}
          >
            <Typography.Title level={4} style={{ margin: 0, padding: 0 }}>
              {currentProcess ? currentProcess.processName : "Process"}{" "}
            </Typography.Title>
            <DownOutlined style={{ fontSize: 14, marginLeft: 6 }} />
          </div>

          */
