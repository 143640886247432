import React from "react";
import Headline from "./headline";
import UserAvatar from "./userAvatar";

const UserPresentationTag = (props) => {
  const { user = {}, style = {}, type, subtitle = "email" } = props;

  switch (type) {
    case "small":
      return (
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            height: "24px",
            ...style,
          }}
        >
          <UserAvatar user={user} style={{ marginRight: "6px" }} size="small" />
          <Headline title={user.fullName} style={{ fontSize: "16px" }} />
        </div>
      );
    case "avatar":
      return (
        <span>
          <UserAvatar user={user} style={{ marginRight: "6px" }} size="small" />
        </span>
      );

    default:
      return (
        <div style={{ display: "inline-flex", alignItems: "center", ...style }}>
          <UserAvatar user={user} style={{ marginRight: "6px" }} />
          <Headline title={user.fullName} subtitle={user[subtitle]} />
        </div>
      );
  }
};
export default UserPresentationTag;
