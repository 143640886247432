import { useEnhancedReducer } from "./useEnhancedReducer";

const modalSetup = {
  visible: {
    Profile: false,
    NeededInfo: false,
    Process: false,
    NewTask: false,
    NewProcess: false,
    NewGroup: false,
    NewUser: false,
    NewKey: false,
    NewDepartment: false,
    ExcelUploader: false,
    TaskCard: false,
    Intro: false,
    Frozen: false,
    LongSince: false,
  },
  modalState: {
    NeededInfo: null,
    NewKey: null,
    Profile: null,
    Process: null,
    Frozen: null,
    NewTask: { activeProcess: null, copyOf: null, note: null },
    NewProcess: null,
    NewGroup: null,
    NewUser: null,
    ExcelUploader: { fileList: [] },
    TaskCard: { onClose: null },
    Intro: { frozen: false },
    LongSince: null,
  },
};

export function useModal() {
  const reducer = (state, action) => {
    //console.log(action);
    switch (action.type) {
      case "TOGGLE_VISIBILITY":
        const newVisible = { ...state.visible, ...action.visible };
        return { ...state, visible: newVisible };
      case "SET_STATE":
        const newModalState = { ...state.modalState, ...action.payload };
        return { ...state, modalState: newModalState };
    }
  };

  const [[state, dispatch], getAllState] = useEnhancedReducer(reducer, modalSetup, undefined, {});
  const getState = (modalId) => state.modalState[modalId];
  const visible = (modalId) => state.visible[modalId];

  return { getState, visible, dispatch };
}
