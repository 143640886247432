import http from "./httpService";
import config from "./config.json";
import { saveAs } from "file-saver";
import axios from "axios";
//services for the Excel Uploader tool
export function DownloadDefaultExcelTemplate(processId, filename = null) {
  const url = `${config.apiUrl}/Excel/DownloadDefaultExcelTemplate/${processId}`;

  axios
    .get(url, { responseType: "blob" })
    .then((response) => {
      const effectiveFilename = !filename ? "template.xls" : filename;

      // Let the user save the file.
      saveAs(response.data, effectiveFilename);
    })
    .catch((response) => {
      console.error("Could not Download the Excel report from the backend.", response);
    });
}

export function UploadFileWithTasks(processId) {
  return config.apiUrl + `/Excel/UploadFileWithTasks/${processId}`;
}

export function CreateTasksFromFile() {
  return { url: config.apiUrl + "/Excel/CreateTasksFromFile/", method: "POST" };
}

//Deprecated
export function DownloadTasksToExcelActive(sendObject, filename = null) {
  let url = `${config.apiUrl}/Excel/DownloadTasksToExcelActive/`;

  axios
    .post(url, sendObject, { responseType: "blob" })
    .then((response) => {
      const fileNameHeader = "content-disposition";
      const headerFileName = response.headers[fileNameHeader];
      const res = headerFileName.split("=");
      const suggestedFileName = res[1].split(";")[0];
      const effectiveFileName = filename
        ? filename
        : suggestedFileName
        ? suggestedFileName
        : "Spreadsheet.xls"; //det her er en fallback... måske vi skal kalde den noget andet ;D

      // Let the user save the file.
      saveAs(response.data, effectiveFileName);
    })
    .catch((response) => {
      console.error("Could not Download the Excel report from the backend.", response);
    });
}

//Deprecated
export function DownloadTasksToExcelClosed(sendObject, filename = null) {
  let url = `${config.apiUrl}/Excel/DownloadTasksToExcelClosed/`;
  axios
    .post(url, sendObject, { responseType: "blob" })
    .then((response) => {
      const fileNameHeader = "content-disposition";
      const headerFileName = response.headers[fileNameHeader];
      const res = headerFileName.split("=");
      const suggestedFileName = res[1].split(";")[0];
      const effectiveFileName = filename
        ? filename
        : suggestedFileName
        ? suggestedFileName
        : "Spreadsheet.xls"; //det her er en fallback... måske vi skal kalde den noget andet ;D

      // Let the user save the file.
      saveAs(response.data, effectiveFileName);
    })
    .catch((response) => {
      console.error("Could not Download the Excel report from the backend.", response);
    });
}
