import React, { useContext } from "react";
import Loadscreen from "./../common/layout/defaultPages/loadscreen";
import { AuthContext } from "../../contexts/authContext";

export const LogoutCallback = () => {
  const user = useContext(AuthContext);

  user.signoutRedirectCallback();
  return <Loadscreen loading={true} message="Logging out" />;
};
