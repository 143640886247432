import React from "react";
import SelectIconOption from "./selectIconOption";

const SelectIcons = (props) => {
  const {
    options = [],
    onChange,
    onBlur,
    value,
    style = {},
    allowClear,
    disabled,
    renderNoOptions,
    border,
  } = props;

  const handleSelection = (newSelection = null) => {
    onChange(newSelection);
    onBlur(newSelection);
  };

  if (options.length === 0) {
    return renderNoOptions();
  }

  return (
    <div style={{ display: "flex" }}>
      {options.map((option) => (
        <SelectIconOption
          key={option.selectValue}
          option={option}
          value={value}
          disabled={disabled}
          allowClear={allowClear}
          onClick={handleSelection}
        />
      ))}
    </div>
  );
};

export default SelectIcons;
