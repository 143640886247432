export function getFeatures() {
  const inDev = process.env.NODE_ENV === "development";

  const url = window.location.host;
  const parts = url.split(".");
  const tld = parts[parts.length - 1];
  const inStaging = tld === "eu";

  const features = {
    introModal: {
      isEnabled: true,
    },
    overviewTable: {
      isEnabled: inDev,
    },
    publicPage: {
      isEnabled: inDev,
    },
    fieldVersions: {
      isEnabled: true,
      version1: { isEnabled: true },
      version2: { isEnabled: false },
    },
    sandbox: {
      isEnabled: inDev,
    },
    exportcsv: {
      isEnabled: false,
    },
    developerConsole: {
      isEnabled: inDev || inStaging,
    },
  };
  return features;
}
