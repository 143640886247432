const queue = "/tasks/queue/:processKey?/:queue?";
const search = "/tasks/search/:searchKey?/:searchWord?";
const settings = "/settings/:settingsMenu?/:status?/:sessionId?";
const departments = "/team/:departmentKey?/:submenu?";
const overview = "/overview";
const process = "/process/:processKey?/:submenu?/:selection?";
const notFound = "/not-found";
const report = "/reports/:type?/:report?/:split?/:key?/:timeKey?";

const getPathFromMenuParam = (menuParam) => {
  switch (menuParam) {
    case "tasks":
      return queue;
    case "settings":
      return settings;
    case "team":
      return departments;
    case "overview":
      return overview;
    case "process":
      return process;
    case "not-found":
      return notFound;
    case "report":
      return report;
    default:
      return `/`;
  }
};

export default {
  queue,
  search,
  settings,
  departments,
  process,
  overview,
  report,
  notFound,
  getPathFromMenuParam,
};
