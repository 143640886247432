import React, { memo, useEffect, useReducer, useRef } from "react";
import { Typography } from "antd";
import useSize from "@react-hook/size";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "../../../../../utils/dndOrderfunctions";
import { CustomScrollbarsVirtualList } from "./scrollbar";

import InputBar from "./inputBar";
import InfoIcon from "./../../../infoIcon";

import RowInList from "./rowInList";
import { areEqual, FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { getPrimaryPalette } from "../../../../../utils/colorpalette";
const palette = getPrimaryPalette();
const initialValue = {
  rows: [],
  skipFormUpdate: true,
};

const listReducer = (state, action) => {
  let newRows = [];
  switch (action.type) {
    case "RESET_ROWS":
      return { rows: action.rows, skipFormUpdate: true };

    case "SET_ROWS":
      return { rows: action.rows, skipFormUpdate: false };
    case "CHANGE_NOTE":
      newRows = state.rows.map((row, i) =>
        //todo make multiple, perhaps so you can put em in on creation
        i === action.index ? { ...row, note: action.newNote } : row
      );
      return { ...state, rows: newRows, skipFormUpdate: false };
    case "CHANGE_VALUE":
      newRows = state.rows.map((row, i) =>
        //todo make multiple, perhaps so you can put em in on creation
        i === action.index ? { ...row, value: action.value } : row
      );
      return { ...state, rows: newRows };
    case "ADD_ROWS":
      newRows = [...state.rows, ...action.rows];
      return { ...state, rows: newRows, skipFormUpdate: false };
    case "HIGHLIGHT_ROWS":
      newRows = [...state.rows].map((row, rowIndex) => {
        if (action.indexes.includes(rowIndex)) {
          return { ...row, highlighted: true };
        } else if (action.rule === "one") {
          return { value: row.value, note: row.note };
        } else {
          return row;
        }
      });
      return { ...state, rows: newRows, skipFormUpdate: false };

    case "REMOVE_HIGHLIGHT_ROWS":
      newRows = [...state.rows].map((row, rowIndex) => {
        if (action.indexes.includes(rowIndex)) {
          return { value: row.value, note: row.note };
        } else return row;
      });

      return { ...state, rows: newRows, skipFormUpdate: false };
    case "DELETE_ROWS":
      /*todo make it so you can delete multiple and not just 1
      this is a bit hacky because we dont have actual ID's on rows and reducers can be fired twice
      so i check that the suspect actually matches on value and note before i delete */
      newRows = [...state.rows];
      const suspect = newRows[action.row.index];

      if (suspect && suspect.value === action.row.value && suspect.note === action.row.note) {
        newRows.splice(action.row.index, 1);
      }

      return { ...state, rows: newRows, skipFormUpdate: false };

    case "SORT_ROWS":
      const newSort = reorder([...state.rows], action.source.index, action.destination.index);
      return { ...state, rows: newSort, skipFormUpdate: false };
    default:
      console.error(action);
      return { ...state };
  }
};

const List = (props) => {
  const { formProps, onChange, onBlur, value, fieldRenderData, autoFocus, fieldMetaData } = props;

  const { allowCopy, disabled, margin } = formProps;
  const { showNotes = false, highlight: highlightRule = false } = fieldMetaData || {};
  const { valueText, placeholder = "value" } = fieldRenderData || {};

  const target = useRef(null);
  const [listSize, _] = useSize(target);

  const [state, dispatch] = useReducer(listReducer, initialValue);

  useEffect(() => {
    dispatch({ type: "RESET_ROWS", rows: value || [] });
  }, []);

  useEffect(() => {
    /*used to update the actual field in the antd form. Skipping when we reset the list so it doesnt trigger validation*/

    if (!state.skipFormUpdate) {
      onChange(state.rows);
      onBlur(state.rows);
    }
  }, [state.rows]);

  const effectiveShowNotes = state.rows.length === 0 ? false : showNotes;

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list and catches impossible drags due to lag in browser
    if (!destination) {
      return;
    }

    switch (source.droppableId) {
      case destination.droppableId:
        dispatch({ type: "SORT_ROWS", source, destination });
    }
  };

  const RowVirtual = memo(function RowVirtual(props) {
    const { data: items, index, style } = props;
    const item = items[index];

    if (!item) {
      return null;
    }
    return (
      <Draggable
        isDragDisabled={disabled}
        key={index + 1 + ""}
        draggableId={index + 1 + ""}
        index={index}
      >
        {(provided) => {
          return (
            <RowInList
              width={listSize}
              highlightRule={highlightRule}
              showNotes={showNotes}
              disabled={disabled}
              provided={provided}
              item={item}
              style={style}
              index={index}
              dispatch={dispatch}
            />
          );
        }}
      </Draggable>
    );
  }, areEqual);

  const cellWidthValue = showNotes ? (listSize - 150) / 3 : listSize - 150;

  return (
    <div style={{ width: "100%" }} ref={target}>
      <div style={{ display: "flex", borderBottom: "1px solid lightgrey" }}>
        <div style={{ marginLeft: 40, width: cellWidthValue }}>
          {!state.rows.length === 0 ? " " : valueText === undefined ? "Value" : valueText}
        </div>

        {effectiveShowNotes && (
          <div>
            Note
            <InfoIcon
              color={palette.lightGrey}
              description={`Notes are here to help you remember why the value is on the list. `}
            />
          </div>
        )}
      </div>
      {state.rows.length === 0 ? (
        <div style={{ height: 60, textAlign: "center", padding: 18 }}>
          <Typography.Text type="secondary">Nothing on the list yet...</Typography.Text>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: Math.min((state.rows.length + 1) * 34, 500),
            overflow: false,
          }}
        >
          <AutoSizer>
            {({ width, height }) => (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                  droppableId="LIST"
                  mode="virtual"
                  renderClone={(provided, snapshot, rubric) => {
                    return (
                      <RowInList
                        width={listSize}
                        highlightRule={highlightRule}
                        showNotes={showNotes}
                        disabled={disabled}
                        index={rubric.source.index}
                        provided={provided}
                        isDragging={snapshot.isDragging}
                        item={state.rows[rubric.source.index]}
                      />
                    );
                  }}
                >
                  {(provided, snapshot) => {
                    const itemCount = snapshot.isUsingPlaceholder
                      ? state.rows.length + 1
                      : state.rows.length;

                    return (
                      <FixedSizeList
                        width={width} //AutoSizer supplied value
                        height={height} //AutoSizer supplied value
                        itemSize={34}
                        outerElementType={CustomScrollbarsVirtualList}
                        itemCount={itemCount}
                        itemData={state.rows}
                        outerRef={provided.innerRef}
                      >
                        {RowVirtual}
                      </FixedSizeList>
                    );
                  }}
                </Droppable>
              </DragDropContext>
            )}
          </AutoSizer>
        </div>
      )}
      {!disabled ? (
        <div style={{ paddingTop: 20, textAlign: "center", borderTop: "1px solid lightgrey" }}>
          <InputBar
            width={listSize}
            dispatch={dispatch}
            disabled={disabled}
            columns={[{ column: "value" }]}
            placeholderText={placeholder}
          />
        </div>
      ) : (
        <div style={{ paddingTop: 8, borderTop: "1px solid lightgrey" }} />
      )}
    </div>
  );
};

export default List;
