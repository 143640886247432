import React from "react";
import { Form, Button } from "antd";
import { LoadingOutlined, CheckOutlined } from "@ant-design/icons";
import { getPrimaryPalette } from "./../../../../utils/colorpalette";

const palette = getPrimaryPalette();

const ApproveButton = (props) => {
  const {
    formProps,
    fieldMetaData,
    fieldRenderData,
    handleSubmit,
    style = {},
    dependencies = [],
    dataIndex = "closeAfter",
    successTimer,
  } = props;

  const { form, touchBeforeOk, waitForRequiredTouch, margin, loading, disabled } = formProps;
  const { okAndClose = false, ok = true, allowClear = false } = fieldRenderData || {};
  const { okAndCloseText = "Save and close", add = null, okText = "Save", clearText = "Reset" } =
    fieldMetaData || {};

  const resetFields = () => {
    form.resetFields();
    form.validateFields();
  };

  const renderInput = () => {
    return (
      <Form.Item shouldUpdate>
        {() => {
          const getIcon = (loader = true) => {
            if (successTimer && successTimer.state) {
              return <CheckOutlined />;
            } else if (loading && loader) {
              return <LoadingOutlined />;
            } else return <span />;
          };
          const getDynamicButtonStyle = () => {
            if (typeof successTimer === "object" && successTimer.state === true) {
              return {
                backgroundColor: palette.green,
                color: "#fff",
                borderColor: "#fff",
                marginLeft: 6,
                ...style,
              };
            } else {
              return { marginLeft: 6, ...style };
            }
          };

          const disableTouch = () => {
            const buttonIsDisabled =
              waitForRequiredTouch === "all"
                ? !form.isFieldsTouched(touchBeforeOk, true)
                : waitForRequiredTouch === "never"
                ? false
                : !form.isFieldsTouched();
            return buttonIsDisabled;
          };

          const disableButtons =
            disableTouch() ||
            form.getFieldsError().filter(({ errors }) => errors.length).length ||
            loading;

          if (!disabled) {
            return (
              <div style={{ display: "flex", justifyContent: "space-between", margin }}>
                <div>
                  {allowClear && (
                    <Button type="link" onClick={resetFields} disabled={!form.isFieldsTouched()}>
                      {clearText}
                    </Button>
                  )}
                  {!!add && add}
                </div>
                <div>
                  {okAndClose && (
                    <Button
                      style={{ ...getDynamicButtonStyle() }}
                      disabled={disableButtons}
                      onClick={() => {
                        handleSubmit(true);
                      }}
                    >
                      {getIcon(false)}
                      {okAndCloseText}
                    </Button>
                  )}
                  {ok && (
                    <Button
                      style={{ ...getDynamicButtonStyle() }}
                      type="primary"
                      disabled={disableButtons}
                      onClick={() => handleSubmit(false)}
                    >
                      {getIcon()}
                      {okText}
                    </Button>
                  )}
                </div>
              </div>
            );
          }
        }}
      </Form.Item>
    );
  };

  if (dependencies.length > 0) {
    return (
      <Form.Item
        noStyle
        shouldUpdate={(cur, prev) => cur[dependencies[0]] !== prev[dependencies[0]]}
      >
        {() => {
          return renderInput();
        }}
      </Form.Item>
    );
  } else {
    return renderInput(true);
  }
};

export default ApproveButton;
