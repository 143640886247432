import { useEnhancedReducer } from "./useEnhancedReducer";

const initialValuesTour = {
  visible: { main: false, settings: false, masscreate: false, process: false },
};

const flipAll = (visibleObject, toBool = false) => {
  const newVisibleObject = {};

  Object.keys(visibleObject).forEach((key) => {
    newVisibleObject[key] = toBool;
  });
  return newVisibleObject;
};

export function useTour() {
  function tourReducer(state, action) {
    switch (action.type) {
      case "start":
        const newVisible = { ...flipAll(state.visible), [action.tour]: true };
        return { ...state, visible: newVisible };
      case "reset":
        return { ...initialValuesTour };
    }
  }

  const [[state, dispatch], getCurrentData] = useEnhancedReducer(
    tourReducer,
    initialValuesTour,
    undefined,
    {}
  );

  return {
    dispatch,
    state,
  };
}
