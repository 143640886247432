import React from "react";
import { Select, ConfigProvider } from "antd";
import IconTitle from "./../../../typography/iconTitle";

const SelectDropdown = (props) => {
  const {
    onChange,
    value,
    disabled,
    allowClear,
    autoFocus,
    onBlur,
    options,
    renderNoOptions,
    showSearch = true,
    optionFilterProp,
    placeholder,
  } = props;

  const handleChange = (newValue) => {
    onChange(newValue);
    onBlur(newValue);
  };

  return (
    <ConfigProvider renderEmpty={renderNoOptions}>
      <Select
        autoFocus={!disabled && autoFocus}
        showSearch={showSearch}
        allowClear={allowClear}
        disabled={disabled}
        placeholder={placeholder}
        optionFilterProp={optionFilterProp ? optionFilterProp : "value"}
        optionLabelProp={"title"}
        value={value}
        onChange={(e) => handleChange(e)}
      >
        {options.map((item) => {
          return (
            <Select.Option
              key={item.selectValue}
              value={item.selectValue}
              disabled={item.alreadySelected}
              title={item.title}
            >
              <IconTitle
                icon={item.icon}
                title={`${item.title} ${item && item.alreadySelected ? "(already selected)" : ""}`}
                description={item.description}
                style={{ margin: 6 }}
              />
            </Select.Option>
          );
        })}
      </Select>
    </ConfigProvider>
  );
};

export default SelectDropdown;
