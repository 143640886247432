import React, { useState, useEffect } from "react";
import { Input, Select, Typography, Col, Row, Popover, Button } from "antd";
import { useHttp } from "./../../hooks/useHttp";
import { useHistory, useParams } from "react-router-dom";

import { GetProcessCustomKeys } from "./../../services/customerKeyService";
import { getSearchLink } from "../../services/searchService";
import { SearchOutlined } from "@ant-design/icons";

const TaskSearch = (props) => {
  const {
    drawKeySelect = true,
    type = "compact",
    collapsed = false,
    showHeaders = true,
    style = {},
    ...rest
  } = props;

  const { push } = useHistory();
  const { searchKey, searchWord } = useParams();

  const [newSearch, setNewSearch] = useState(null);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [
    { data: keyData = [], loading: loadingGet, error: errorGet },
    refetchKeys,
  ] = useHttp(GetProcessCustomKeys, { manual: true });

  useEffect(() => {
    if (drawKeySelect) {
      refetchKeys();
    }
  }, [drawKeySelect]);

  useEffect(() => {
    setNewSearch(searchWord ? searchWord : null);
  }, [searchWord]);

  const handleSearchChange = (newValue) => {
    setNewSearch(newValue);
    if (!newValue) {
      push(getSearchLink(null, searchKey));
    }
  };

  const handleSearch = (newKey) => {
    setPopoverVisible(false);
    push(getSearchLink(newSearch, newKey));
  };

  const currentKeyObject =
    searchKey === "all" || !searchKey
      ? { customKeyName: "All" }
      : keyData.find((x) => x.customKeyId === Number(searchKey));

  const keyRender = (key) => {
    return (
      <div>
        <Typography.Text type="secondary">Key: </Typography.Text>
        <Typography.Text> {key}</Typography.Text>
      </div>
    );
  };

  const searchBar = (
    <div style={{ display: "inline-block" }}>
      <Input.Group compact>
        {drawKeySelect && (
          <Select
            value={keyRender(currentKeyObject.customKeyName)}
            style={{ width: 160 }}
            onChange={(searchKey) => {
              push(getSearchLink(newSearch, searchKey));
            }}
          >
            {keyData.map((key) => {
              return (
                <Select.Option key={key.customKeyId} value={key.customKeyId}>
                  {keyRender(key.customKeyName)}
                </Select.Option>
              );
            })}
            <Select.Option key="all" value={null}>
              {keyRender("All")}
            </Select.Option>
          </Select>
        )}

        <Input.Search
          allowClear
          value={newSearch}
          placeholder="Search in All Tasks"
          onSearch={() => handleSearch("all")}
          onChange={(e) => {
            setNewSearch(e.target.value);
          }}
          style={{ width: 200 }}
        />
      </Input.Group>
    </div>
  );

  switch (type) {
    case "compact":
      if (!collapsed) {
        return <span>{searchBar}</span>;
      } else {
        return (
          <Popover
            content={searchBar}
            placement="left"
            trigger={["click"]}
            visible={popoverVisible}
            onVisibleChange={setPopoverVisible}
          >
            <Button icon={<SearchOutlined />} shape="circle" />
          </Popover>
        );
      }

    case "full":
      return (
        <div style={{ ...style }} {...rest}>
          <Row gutter={12}>
            <Col span={16}>
              {showHeaders && (
                <div>
                  <Typography.Text strong style={{ marginRight: 6 }}>
                    Search
                  </Typography.Text>
                </div>
              )}
              <Input.Search
                value={newSearch}
                allowClear
                placeholder="Search in All Tasks"
                onSearch={() => push(getSearchLink(newSearch, searchKey))}
                onChange={(e) => {
                  handleSearchChange(e.target.value);
                }}
                style={{ width: "100%" }}
              />
            </Col>

            <Col span={8}>
              {showHeaders && (
                <div>
                  <Typography.Text strong style={{ marginRight: 6 }}>
                    Keys{" "}
                  </Typography.Text>
                </div>
              )}
              <Select
                value={currentKeyObject && currentKeyObject.customKeyName}
                style={{ width: 160 }}
                onChange={(searchKey) => {
                  push(getSearchLink(newSearch, searchKey));
                }}
              >
                {keyData.map((key) => {
                  return (
                    <Select.Option key={key.customKeyId} value={key.customKeyId}>
                      {key.customKeyName}
                    </Select.Option>
                  );
                })}
                <Select.Option key="all" value={null}>
                  {"All"}
                </Select.Option>
              </Select>
            </Col>
          </Row>
        </div>
      );
  }
};

export default TaskSearch;
