import React, { useState, useEffect, useContext } from "react";
import { Upload, message, Button } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import moment from "moment";

import { ProcessTeams } from "./../../../contexts/processTeams";

import { UploadFileWithTasks, DownloadDefaultExcelTemplate } from "../../../services/excelService";
import { AuthContext } from "./../../../contexts/authContext";

const TaskUpload = (props) => {
  const { getStateModal, dispatchModal } = props;
  const [subscriptionId, setSubscriptionId] = useState(false);
  const [currentToken, setCurrentToken] = useState(null);
  const [fileList, setFileList] = useState([]);
  const userService = useContext(AuthContext);
  const { getProcess } = useContext(ProcessTeams);

  useEffect(() => {
    populateToken();
    const newSubId = userService.subscribe(() => populateToken());
    setSubscriptionId(newSubId);
    return () => userService.unsubscribe(newSubId);
  }, []);

  const populateToken = async () => {
    console.log("populating token");
    const promises = Promise.all([userService.isAuthenticated(), userService.getAccessToken()]);
    const answers = await promises;
    console.log(answers);
    if (answers[0] && answers[1]) {
      setCurrentToken(answers[1]);
    }
  };

  const processId = getStateModal("NewTask").activeProcess
    ? getStateModal("NewTask").activeProcess
    : null;

  const process = getProcess(processId);

  const handleSubmit = () => {
    dispatchModal({
      type: "SET_STATE",
      payload: { ExcelUploader: { fileList } },
    });
    dispatchModal({
      type: "TOGGLE_VISIBILITY",
      visible: { ExcelUploader: true, NewTask: false },
    });
  };

  const handleChange = (info) => {
    let fileList = [...info.fileList];

    const status = info.file.status;
    if (status !== "uploading") {
      //add error handling
    }
    if (status === "done") {
      //dno perhaps add something
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-1);

    // 2. Read from response and show file link
    fileList = fileList.map((file) => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });

    setFileList(fileList);
  };

  const draggerProps = {
    action: UploadFileWithTasks(processId),
    headers: {
      Authorization: "Bearer " + currentToken,
    },
    multiple: false,
    onChange: handleChange,
  };
  return (
    <React.Fragment>
      <div style={{ textAlign: "left" }}>
        <Upload.Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Drag and drop a file or click to browse</p>
          <p className="ant-upload-hint">.xlsx, .xls and .csv file works</p>
        </Upload.Dragger>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
        <Button
          onClick={() =>
            DownloadDefaultExcelTemplate(
              processId,
              `${process.processKey ? process.processKey : "Template"}_${moment().format("L")}.xlsx`
            )
          }
        >
          Download a template
        </Button>
        <Button
          disabled={fileList.length === 0}
          type="primary"
          onClick={handleSubmit}
          style={{ marginLeft: 6 }}
        >
          Submit
        </Button>
      </div>
    </React.Fragment>
  );
};
export default TaskUpload;
