import React, { useState } from "react";
import { Input, message, Typography, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { getGrid } from "../../../../../utils/calc";

const InputBar = (props) => {
  const {
    showTitle = false,
    placeholder,
    dispatch,
    closed,
    disabled,
    width,
    columns = [],
    style = {},
  } = props;

  const [inputValue, setInputValue] = useState(null);

  //ref for input
  let textInput = React.createRef();
  function handleFocus() {
    textInput.current.focus();
  }

  const handlePaste = (e) => {
    const data = e.clipboardData.getData("Text");

    if (!data) {
      e.preventDefault();
      return;
    }

    if (data.indexOf("\n") === -1 && data.indexOf("\t") === -1) {
      //console.log("regular paste without any cells");
      return;
    } else if (
      data.indexOf("\n") > 0 &&
      (data.match(/\n/g) || []).length === 1 &&
      data.indexOf("\t") === -1
    ) {
      //console.log("Found single cell copy/pasted from excel");
      return;
    } else {
      let rawRows = data.split("\n");
      let rowObjects = [];
      for (let y in rawRows) {
        //splits row into array
        let rowWithcells = rawRows[y].split("\t");
        if (y === "0" && rowWithcells.length > columns.length) {
          e.preventDefault();
          message.error("The amount of columns you try to paste is larger than the table");
          return;
        }

        //check if cells and colums match
        if (rowWithcells.length <= columns.length) {
          //removes rows that only consists of ""
          if (
            !rowWithcells.every((currentValue) => {
              return !currentValue.replace(/\s/g, "");
            })
          ) {
            let rowObject = {};

            for (let x in rowWithcells) {
              if (columns.length > Number(x)) {
                rowObject[columns[x].column] = rowWithcells[x].trim();
              }
            }

            rowObjects.push(rowObject);
          }
        }
      }

      if (rowObjects.length === 0) {
        e.preventDefault();
        return;
      } else {
        e.preventDefault();

        dispatch({ type: "ADD_ROWS", rows: rowObjects });
        setInputValue(null);
        handleFocus();
      }
    }
  };

  const handleAddRow = (e) => {
    e.preventDefault && e.preventDefault();

    if (!inputValue) {
      //do nothing if no value
      return;
    }

    let trimmedInpuptValues = {};
    Object.keys(inputValue).map(function (key, index) {
      trimmedInpuptValues[key] = inputValue[key] ? inputValue[key].trim() : inputValue[key];
    });

    if (!!trimmedInpuptValues) {
      dispatch({ type: "ADD_ROWS", rows: [trimmedInpuptValues] });
      setInputValue(null);
      handleFocus();
    }
  };

  const handleInputChange = (e, col) => {
    if (!col) {
      return;
    }
    const newValue = e.target.value ? e.target.value : null;
    let payload;

    payload = {
      ...inputValue,
      [col]: newValue,
    };

    setInputValue(payload);
  };

  const effectiveColumnAmount = columns.length > 0 ? columns.length : 1;
  const effectiveDisabled = disabled || columns.length === 0;
  const [normalWidth, lastWidth, buttonWidth] = getGrid(width, effectiveColumnAmount, 40);

  const lastCol = effectiveColumnAmount - 1;
  const lastColName = columns && columns[lastCol] ? [columns[lastCol].column] : null;

  if (closed) {
    //shouldnt render if table is closed
    return <span />;
  }

  //set to avoid visual horizontal overflow with antd transition
  const inputPropsTransition = {
    transition: "",
    transitionProperty: "none",
  };
  return (
    <div style={{ ...style }}>
      {columns.length > 0 && (
        <React.Fragment>
          {showTitle && (
            <Typography.Text type="secondary" style={{ fontStyle: "italic", fontSize: 10 }}>
              add new line
            </Typography.Text>
          )}
          <Input.Group compact>
            {columns.length > 1 &&
              columns
                .filter((col, index) => index !== columns.length - 1)
                .map((col, i) => (
                  <Input
                    ref={i === 0 ? textInput : null}
                    onPressEnter={(e) => {
                      e.target.nextElementSibling.focus();
                    }}
                    value={inputValue && col && inputValue[col.column]}
                    disabled={effectiveDisabled}
                    onPaste={handlePaste}
                    placeholder={effectiveDisabled ? "" : col.column}
                    key={col.column}
                    style={{ width: normalWidth, ...inputPropsTransition }}
                    onChange={(e) => handleInputChange(e, col && col.column)}
                  />
                ))}
            <Input
              disabled={effectiveDisabled}
              style={{
                width: lastWidth,
                textAlign: "left",
                ...inputPropsTransition,
              }}
              onPaste={handlePaste}
              ref={lastCol === 0 ? textInput : null}
              value={inputValue && inputValue[lastColName]}
              onChange={(e) => handleInputChange(e, lastColName)}
              placeholder={
                effectiveDisabled
                  ? disabled
                    ? ""
                    : "No columns"
                  : placeholder
                  ? placeholder
                  : lastColName
              }
              onPressEnter={handleAddRow}
            />
            <Button
              onClick={handleAddRow}
              type="primary"
              disabled={effectiveDisabled || !inputValue}
              style={{ width: buttonWidth }}
              icon={<PlusOutlined />}
            />
          </Input.Group>
        </React.Fragment>
      )}
    </div>
  );
};

export default InputBar;
