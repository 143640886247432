import React, { useState } from "react";
import { Select, ConfigProvider, message } from "antd";

const InputMultipleComponent = (props) => {
  const {
    value,
    onChange,
    disabled,
    allowClear,
    onBlur,
    renderNoOptions,
    placeholder,
    separator = [","],
    style = {},
    enterBehavior = "addandremove",
  } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mouseIsInDropdown, setMouseIsInDropdown] = useState(false);
  const [currentSearch, setCurrentSearch] = useState(null);

  const handleChange = (newValue, overrideEnterStop) => {
    const effectiveNewValue = newValue.filter((point) => point.trim() !== "");
    const trimmedNewValues = effectiveNewValue.map((point) => point.trim());
    const currentSearchAlreadyAValue = value ? value.includes(currentSearch) : false;

    if (newValue && newValue[newValue.length - 1] == "") {
      //latestvalue is "" so we dont do anything
      return;
    } else if (currentSearchAlreadyAValue && !mouseIsInDropdown) {
      //already in array and mouse is not in dropdown so we dont remove it
      return;
    } else {
      onChange(trimmedNewValues);
      onBlur(trimmedNewValues);
      setCurrentSearch(null);
      if (!mouseIsInDropdown) {
        setDropdownOpen(false);
      }
    }
  };

  const handleDropdownOpen = (newValue) => {
    if (!value && newValue) {
      setDropdownOpen(false);
    } else {
      setDropdownOpen(newValue);
    }
  };

  const handlePaste = (e) => {
    const data = e.clipboardData.getData("Text");
    e.preventDefault();
    if (
      data.indexOf("\n") === -1 &&
      data.indexOf("\t") === -1 &&
      (!separator || (separator && data.indexOf(separator[0]) === -1))
    ) {
      let trimmedSingleField = data.trim();
      if (trimmedSingleField) {
        handleChange(
          value ? Array.from(new Set([...value, trimmedSingleField])) : [trimmedSingleField],
          true
        );
      }
    } else {
      let rawFields = [];
      let rawRows = data.split("\n");

      for (let y in rawRows) {
        let fieldsInThisRow = separator
          ? rawRows[y].split(separator[0]).join("\t").split("\t")
          : rawRows[y].split("\t");

        for (let z in fieldsInThisRow) {
          if (fieldsInThisRow[z].replace(/\s/g, "")) {
            rawFields.push(fieldsInThisRow[z].replace(/\s/g, "").trim());
          }
        }
      }

      const distinctRawFields = Array.from(new Set(rawFields));
      const alreadyInValue = value
        ? value.filter((element) => distinctRawFields.includes(element))
        : [];

      let effectiveValue = value ? [...value, ...rawFields] : [...rawFields];
      let effectiveDistinctValue = Array.from(new Set(effectiveValue));
      const newValuesToBeCreated = distinctRawFields.length - alreadyInValue.length;

      if (alreadyInValue.length > 0) {
        message.info({
          content: `Your selection had ${distinctRawFields.length} unique keys. ${alreadyInValue.length} keys is already on the list. Added ${newValuesToBeCreated} keys to the list`,
        });
      } else {
        message.success({
          content: `Your selection had ${distinctRawFields.length} unique keys. ${newValuesToBeCreated} keys added`,
        });
      }

      handleChange(effectiveDistinctValue, true);
    }
  };

  const handleMouseIsInDropdown = (e, order) => {
    /* this is unfortunatly needed to get the behaviour i wanted
    where a simple onblur/enter cant deselect - only mouseclick can. */
    if (order === "enter") {
      if (
        e &&
        e.target &&
        e.target.className &&
        e.target.className.includes("ant-select-dropdown")
      ) {
        setMouseIsInDropdown(true);
      }
    } else {
      if (mouseIsInDropdown) {
        setMouseIsInDropdown(false);
      }
    }
  };

  return (
    <ConfigProvider renderEmpty={renderNoOptions}>
      <Select
        allowClear={allowClear}
        maxTagCount={50}
        maxTagPlaceholder={(omittedValues) => {
          return (
            <span>
              + <strong>{omittedValues && omittedValues.length}</strong> more. Total keys:{" "}
              <strong>{value && value.length}</strong>
            </span>
          );
        }}
        tokenSeparators={separator}
        placeholder={placeholder}
        value={value || []}
        open={dropdownOpen}
        onDropdownVisibleChange={handleDropdownOpen}
        disabled={disabled}
        onPaste={handlePaste}
        onChange={(newValues) => handleChange(newValues, false)}
        onSearch={setCurrentSearch}
        onMouseEnter={(e) => handleMouseIsInDropdown(e, "enter")}
        onMouseLeave={(e) => handleMouseIsInDropdown(e, "leave")}
        style={{ ...style }}
        mode="tags"
      ></Select>
    </ConfigProvider>
  );
};

export default InputMultipleComponent;
