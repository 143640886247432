import React from "react";
import { Tag } from "antd";
import { getPrimaryPalette } from "./../../../utils/colorpalette";
import { LockFilled } from "@ant-design/icons";

const LockedByMeTag = (props) => {
  const palette = getPrimaryPalette();

  const { modifiedDate } = props;

  return (
    <Tag>
      <LockFilled /> by me
    </Tag>
  );
};

export default LockedByMeTag;
