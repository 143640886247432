import { useDebugValue } from "react";
import http from "../services/httpService";
import { useEnhancedReducer } from "./useEnhancedReducer";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";

import { GetHeadersQueue, GetTasksClosed, GetTasksActive } from "./../services/queueService";

import { GetResultsSearch, GetHeaderSearch } from "./../services/searchService";
import { GetProcessQueueCounts } from "./../services/overviewService";

import { getTagObject } from "./../components/taskView/taskFieldRender";
import { getExcelQueueExport } from "../utils/excel/excelQueueExport";

import {
  GetTaskCard,
  TryUnlockTask,
  TryApproveTask,
  TaskUpdateSolve,
  TaskUpdateCancel,
  TaskUpdateReady,
  TaskUpdateManual,
  WriteNote,
  ModifyTaskCards,
  ModifyTaskCard,
  UpdateActivationDate,
} from "./../services/taskService";

const queuesSetup = {
  columns: [],
  tableData: [],
  selectedIds: [],
  taskCard: {},
  filtered: [],
  counts: [],
  queueLoading: false,
  taskCardLoading: false,
  latestGet: null,
};

const statusColumnAdd = {
  dataIndex: "Status",
  fieldType: "Status",
  title: "",
  fixed: "right",
};

const taskMover = (taskIds, from, to, oldCounts) => {
  if (!oldCounts) {
    return [];
  }
  let newCounts = [...oldCounts];
  const amountToMove = taskIds.length;

  const indexToLose = newCounts.findIndex((queue) => queue.queue === from);
  newCounts[indexToLose] = {
    ...oldCounts[indexToLose],
    queueCount: oldCounts[indexToLose].queueCount - amountToMove,
  };

  const indexToGain = newCounts.findIndex((queue) => queue.queue === to);
  newCounts[indexToGain] = {
    ...oldCounts[indexToGain],
    queueCount: oldCounts[indexToGain].queueCount + amountToMove,
  };

  return newCounts;
};

export function useQueues() {
  function queueReducer(state, action) {
    let newTaskCard = null;
    let newTableData = null;
    let newCounts = null;
    switch (action.type) {
      case "START_LOADING_QUEUE":
        return { ...state, queueLoading: true, latestGet: action.latestGet };
      case "RESET_LATEST":
        return { ...state, latestGet: null, columns: [], tableData: [] };
      case "START_LOADING_TASKCARD":
        return { ...state, taskCardLoading: true };
      case "UPDATE_DATA":
        const addTagColumn = (tableData = []) => {
          const effectiveTableData = tableData.map((row) => {
            return { ...row, Status: getTagObject(row) };
          });
          return effectiveTableData;
        };

        return {
          ...state,
          columns: action.columns,
          tableData: addTagColumn(action.tableData),
          queueLoading: false,
        };
      case "UPDATE_TASKCARD":
        return { ...state, taskCard: action.taskCard, taskCardLoading: false };
      case "CLEAN_TABLEDATA":
        return { ...state, columns: [], tableData: [] };
      case "SET_FILTERED":
        return { ...state, filtered: action.filtered };

      case "RESET_FILTER_AND_SELECTION":
        return { ...state, filtered: [], selectedIds: [] };

      case "SET_SELECTED_IDS":
        return { ...state, selectedIds: action.selectedIds };

      case "SET_COUNTS":
        return { ...state, counts: action.counts };

      case "MOVE_COUNT_OPT":
        newCounts = taskMover(
          action.taskIds,
          action.fromQueue,
          action.toQueue,
          state.counts ? [...state.counts] : null
        );
        return { ...state, counts: newCounts };

      case "REMOVE_ROWS_OPT":
        newTableData = [...state.tableData].filter((row) => !action.taskIds.includes(row.taskId));
        return { ...state, tableData: newTableData };

      case "CHANGE_FIELDVALUES_OPT":
        let newKeyFields = [...state.taskCard.keyFields].map((keyField) => {
          let foundInNew = action.keyFields[keyField.keyId];
          if (foundInNew) {
            return {
              ...keyField,
              fieldValue: action.keyFields[keyField.keyId],
            };
          } else return keyField;
        });

        let newCustomFields = [...state.taskCard.customFields].map((customField) => {
          let foundInNew = action.customFields[customField.customFieldId];

          if (foundInNew !== undefined) {
            return {
              ...customField,
              fieldValue: action.customFields[customField.customFieldId],
            };
          } else return customField;
        });
        newTaskCard = {
          ...state.taskCard,
          keyFields: newKeyFields,
          customFields: newCustomFields,
        };

        return { ...state, taskCard: newTaskCard };

      case "ADD_COMMENTS_OPT":
        newTaskCard = { ...state.taskCard };
        newTaskCard.taskLog.push({
          actionType: "Comment",
          actionUserId: action.userId,
          noteText: action.comment,
          actionDateTime: dayjs().format(),
          taskActionId: action.taskActionIds[0],
        });
        return { ...state, taskCard: newTaskCard };

      case "REMOVE_COMMENTS_OPT":
        newTaskCard = { ...state.taskCard };

        newTaskCard.taskLog = [...newTaskCard.taskLog].filter(
          (entry) => !action.taskActionIds.includes(entry.taskActionId)
        );
        return { ...state, taskCard: newTaskCard };

      default:
        console.error("usequeues: no reducer found");
        return { ...state };
    }
  }
  const asyncActionHandlers = {
    GET_ACTIVE_QUEUE: ({ dispatch, getState, signal }) => async (action) => {
      dispatch({
        type: "START_LOADING_QUEUE",
        latestGet: { ...action },
      });
      let payload = { processKey: action.processKey, queue: action.queue };

      let promiseList = [
        http.do({ ...GetHeadersQueue(action.processKey, action.queue) }),
        http.do({ ...GetTasksActive(), data: payload }),
      ];
      const answerList = await Promise.all(promiseList);
      const newColumns = [...answerList[0].data.columns, { ...statusColumnAdd }];

      dispatch({
        type: "UPDATE_DATA",
        columns: newColumns,
        tableData: answerList[1].data,
      });
    },
    GET_CLOSED_QUEUE: ({ dispatch, getState, signal }) => async (action) => {
      console.log(action);
      let fromDate = action.fromDate;
      let toDate = action.toDate;

      let payload = {
        processKey: action.processKey,
        queue: action.queue,
        fromDate,
        toDate,
      };
      dispatch({
        type: "START_LOADING_QUEUE",
        latestGet: { ...action },
      });
      let promiseList = [
        http.do({ ...GetHeadersQueue(action.processKey, action.queue) }),
        http.do({ data: payload, ...GetTasksClosed() }),
      ];
      const answerList = await Promise.all(promiseList);

      const newColumns = [...answerList[0].data.columns, { ...statusColumnAdd }];
      dispatch({
        type: "UPDATE_DATA",
        columns: newColumns,
        tableData: answerList[1].data,
      });
    },
    GET_SEARCH: ({ dispatch, getState, signal }) => async (action) => {
      dispatch({
        type: "START_LOADING_QUEUE",
        latestGet: { ...action },
      });

      let promiseList = [
        http.do({ ...GetHeaderSearch(action.key) }),
        http.do({ ...GetResultsSearch(action.searchWord, action.key) }),
      ];

      const answerList = await Promise.all(promiseList);
      const newColumns = [...answerList[0].data, { ...statusColumnAdd }];
      dispatch({
        type: "UPDATE_DATA",
        columns: newColumns,
        tableData: answerList[1].data.results,
      });
    },
    GET_TASKCARD: ({ dispatch, getState, signal }) => async (action) => {
      dispatch({ type: "START_LOADING_TASKCARD" });
      const answer = await http.do({ ...GetTaskCard(action.taskId) });

      dispatch({ type: "UPDATE_TASKCARD", taskCard: answer.data });
    },
    GET_COUNTS: ({ dispatch, getState, signal }) => async (action) => {
      try {
        const payload = {
          fromDate: action.fromDate,
          toDate: action.toDate,
          processKey: action.processKey,
        };
        const answer = await http.do({ ...GetProcessQueueCounts(), data: payload });

        dispatch({ type: "SET_COUNTS", counts: answer.data.queueData });
      } catch (ex) {
        console.error(ex);
      }
    },
    MODIFY_TASKCARD_FIELDS: ({ dispatch, getState, signal }) => async (action) => {
      let keyFields = {};
      let customFields = { ...action.newValues };
      for (var k in customFields) {
        if (!action.newValues.hasOwnProperty(k)) continue;
        let found = k.indexOf("key");
        if (found === 0) {
          keyFields[k.slice(3)] = customFields[k];
          delete customFields[k];
        }
      }

      dispatch({ type: "CHANGE_FIELDVALUES_OPT", keyFields, customFields });
      if (typeof action.onChange === "function") {
        action.onChange();
      }
      const payload = {
        taskId: action.taskId,
        customFieldsList: customFields,
      };
      const answer = await http.do({ ...ModifyTaskCard(), data: payload });

      if (typeof action.onSuccess === "function") {
        action.onSuccess();
      }
    },
    CREATE_NEW_COMMENTS: ({ dispatch, getState, signal }) => async (action) => {
      const tempId = uuidv4();
      if (action.taskIds.includes(getState().taskCard.taskId)) {
        dispatch({
          type: "ADD_COMMENTS_OPT",
          comment: action.comment,
          userId: action.userId,
          taskIds: action.taskIds,
          taskActionIds: [tempId],
        });
      }
      const payload = { taskIds: action.taskIds, noteText: action.comment };

      try {
        const answer = await http.do({ ...WriteNote(), data: payload });
        if (typeof action.onSuccess === "function") {
          action.onSuccess(answer);
        }
      } catch (ex) {
        dispatch({ type: "REMOVE_COMMENTS_OPT", taskActionIds: [tempId] });
        if (typeof action.onError === "function") {
          action.onError();
        }
      }
    },
    APPROVE_TASKS: ({ dispatch, getState, signal }) => async (action) => {
      dispatch({ type: "REMOVE_ROWS_OPT", taskIds: action.taskIds });
      dispatch({
        type: "MOVE_COUNT_OPT",
        taskIds: action.taskIds,
        toQueue: "Ready",
        fromQueue: action.fromQueue,
      });
      const payload = { taskIds: action.taskIds };
      try {
        const answer = await http.do({ ...TryApproveTask(), data: payload });
        if (typeof action.onSuccess === "function") {
          action.onSuccess();
        }
      } catch (ex) {
        if (typeof action.onError === "function") {
          action.onError();
        }
      }
    },
    UNLOCK_TASK: ({ dispatch, getState, signal }) => async (action) => {
      const answer = await http.do({ ...TryUnlockTask(action.taskId) });
    },
    SOLVE_TASKS: ({ dispatch, getState, signal }) => async (action) => {
      dispatch({ type: "REMOVE_ROWS_OPT", taskIds: action.taskIds });
      dispatch({
        type: "MOVE_COUNT_OPT",
        taskIds: action.taskIds,
        toQueue: "Solved",
        fromQueue: action.fromQueue,
      });
      const payload = { taskIds: action.taskIds };
      try {
        const answer = await http.do({ ...TaskUpdateSolve(), data: payload });
        if (typeof action.onSuccess === "function") {
          action.onSuccess();
        }
      } catch (ex) {
        if (typeof action.onError === "function") {
          action.onError();
        }
      }
    },
    CANCEL_TASKS: ({ dispatch, getState, signal }) => async (action) => {
      dispatch({ type: "REMOVE_ROWS_OPT", taskIds: action.taskIds });
      const payload = { taskIds: action.taskIds };
      try {
        const answer = await http.do({ ...TaskUpdateCancel(), data: payload });
        if (typeof action.onSuccess === "function") {
          action.onSuccess();
          dispatch({
            type: "MOVE_COUNT_OPT",
            taskIds: action.taskIds,
            toQueue: "Obsolete",
            fromQueue: action.fromQueue,
          });
        }
      } catch (ex) {
        if (typeof action.onError === "function") {
          action.onError();
        }
      }
    },
    MANUAL_TASKS: ({ dispatch, getState, signal }) => async (action) => {
      dispatch({ type: "REMOVE_ROWS_OPT", taskIds: action.taskIds });
      const payload = { taskIds: action.taskIds };
      dispatch({
        type: "MOVE_COUNT_OPT",
        taskIds: action.taskIds,
        toQueue: "Manual",
        fromQueue: action.fromQueue,
      });
      try {
        const answer = await http.do({ ...TaskUpdateManual(), data: payload });
        if (typeof action.onSuccess === "function") {
          action.onSuccess();
        }
      } catch (ex) {
        if (typeof action.onError === "function") {
          action.onError();
        }
      }
    },
    READY_TASKS: ({ dispatch, getState, signal }) => async (action) => {
      dispatch({ type: "REMOVE_ROWS_OPT", taskIds: action.taskIds });
      dispatch({
        type: "MOVE_COUNT_OPT",
        taskIds: action.taskIds,
        toQueue: "Ready",
        fromQueue: action.fromQueue,
      });
      const payload = { taskIds: action.taskIds };
      try {
        const answer = await http.do({ ...TaskUpdateReady(), data: payload });
        if (typeof action.onSuccess === "function") {
          action.onSuccess();
        }
      } catch (ex) {
        if (typeof action.onError === "function") {
          action.onError();
        }
      }
    },
    UPDATE_ACTIVATION_DATES: ({ dispatch, getState, signal }) => async (action) => {
      const payload = { taskIds: action.taskIds, newActivationDate: action.newActivationDate };
      try {
        const answer = await http.do({ ...UpdateActivationDate(), data: payload });
        if (typeof action.onSuccess === "function") {
          action.onSuccess();
        }
      } catch (ex) {
        if (typeof action.onError === "function") {
          action.onError();
        }
      }
    },
    MASS_EDIT_TASKS: ({ dispatch, getState, signal }) => async (action) => {
      const { customFieldId, taskIds, newFieldValue } = action;
      const payload = { customFieldId, taskIds, newFieldValue };

      if (taskIds.includes(getState().taskCard.taskId)) {
        //works but form is not updated on this chaange yet
        dispatch({
          type: "CHANGE_FIELDVALUES_OPT",
          keyFields: {},
          customFields: { [customFieldId]: newFieldValue },
        });
      }
      try {
        const answer = await http.do({ ...ModifyTaskCards(), data: payload });
        if (typeof action.onSuccess === "function") {
          action.onSuccess();
        }
      } catch (ex) {
        if (typeof action.onError === "function") {
          action.onError();
        }
      }
    },
  };

  const [[state, dispatch], getState] = useEnhancedReducer(
    queueReducer,
    queuesSetup,
    undefined,
    asyncActionHandlers
  );

  const refreshQueue = () => {
    if (state.latestGet) {
      dispatch({ ...state.latestGet });
      if (["GET_ACTIVE_QUEUE", "GET_CLOSED_QUEUE"].includes(state.latestGet.type)) {
        dispatch({ ...state.latestGet, type: "GET_COUNTS" });
      }
    }
  };

  const refreshTaskCard = () => {
    if (state.taskCard.taskId) dispatch({ type: "GET_TASKCARD", taskId: state.taskCard.taskId });
  };

  const handleExcelExport = (whichToTake) => {
    let fileName;
    let rows = [];

    switch (whichToTake) {
      case "all":
        rows = [...state.tableData];
        fileName = "all.xlsx";
        break;
      case "search-all":
        rows = [...state.tableData];
        fileName = "Search.xlsx";
        break;
      case "filter":
        rows = [...state.filtered];
        fileName = "filter.xlsx";
        break;
      case "selected":
      case "search-selection":
        fileName = "selection.xlsx";
        rows = state.selectedIds.map((taskId) => {
          const foundRow = state.tableData.find((data) => data.taskId === taskId);
          return foundRow;
        });
        break;
      default:
        break;
    }
    getExcelQueueExport(fileName, rows, state.columns);
  };

  useDebugValue(`columns: ${state.columns.length}, data: ${state.tableData.length}`);

  return { state, dispatch, refreshQueue, handleExcelExport, refreshTaskCard };
}
