export const periodOptions = [
  /*{
      label: "Every Minute",
      value: "minute",
      prep: "",
    },*/
  {
    title: "Every hour at",
    label: "Every hour",
    value: "hour",
    prep: "at",
    override: [false, true, true, true, true],
  },
  {
    title: "Every day at ",
    label: "Every day",
    value: "day",
    prep: "at",
    override: [false, false, true, true, true],
  },
  {
    title: "Every week at",
    label: "Every week",
    value: "week",
    prep: "on",
    override: [false, false, true, true, false],
  },
  {
    title: "Every month at the",
    label: "Every month",
    value: "month",
    prep: "on the",
    override: [false, false, false, true, true],
  },
  {
    title: "Every year at the",
    label: "Every year",
    value: "year",
    prep: "on the",
    override: [false, false, false, false, true],
  },
  {
    title: "Raw Cron",
    label: "Raw Cron",
    value: "custom",
    prep: "",
    override: [false, false, false, false, false],
  },
];

export function getState(cronExp) {
  const state = {
    selectedPeriod: "minute",
    selectedHourOption: {
      min: 0,
    },
    selectedDayOption: {
      hour: 0,
      min: 0,
    },
    selectedWeekOption: {
      day: 0,
      hour: 0,
      min: 0,
    },
    selectedMonthOption: {
      day: 1,
      hour: 0,
      min: 0,
    },
    selectedYearOption: {
      day: 1,
      mon: 1,
      hour: 0,
      min: 0,
    },
    selectedCustomOption: {
      // @Matti: Ved ikke om vi skal bruge det her
      cron: cronExp,
    },
  };

  if (!cronExp) {
    return state;
  }

  const cronArray = cronExp.split(" ");

  if (cronArray.length !== 5) return state;

  cronArray[0] = cronArray[0] === "*" ? "*" : cronArray[0];
  cronArray[1] = cronArray[1] === "*" ? "*" : cronArray[1];
  cronArray[2] = cronArray[2] === "*" ? "*" : cronArray[2];
  cronArray[3] = cronArray[3] === "*" ? "*" : cronArray[3];
  cronArray[4] = cronArray[4] === "*" ? "*" : cronArray[4];

  // Checker om et af specialChars dukker op fordi s� er vi 100% sikre p� at det er custom cron statement
  var specialChars = ["/", "-", ","];

  for (let i = 0; i < cronArray.length; i++) {
    if (typeof cronArray[i] === "string") {
      for (let j = 0; j < specialChars.length; j++) {
        if (cronArray[i].indexOf(specialChars[j]) > -1) {
          state.selectedPeriod = "custom";
          return state;
        }
      }
    }
  }

  if (cronArray[4] !== "*") {
    state.selectedPeriod = "week";
    state.selectedWeekOption.min = cronArray[0] === "*" ? 0 : Number(cronArray[0]);
    state.selectedWeekOption.hour = cronArray[1] === "*" ? 0 : Number(cronArray[1]);
    state.selectedWeekOption.day = Number(cronArray[4]);
    // Hvis position 3 og 4 er sat s� er det custom
    if (cronArray[2] !== "*" || cronArray[3] !== "*") {
      state.selectedPeriod = "custom";
    }
    return state;
  } else if (cronArray[3] !== "*") {
    state.selectedPeriod = "year";
    state.selectedYearOption.min = cronArray[0] === "*" ? 0 : Number(cronArray[0]);
    state.selectedYearOption.hour = cronArray[1] === "*" ? 0 : Number(cronArray[1]);
    state.selectedYearOption.day = cronArray[2] === "*" ? 1 : Number(cronArray[2]);
    state.selectedYearOption.mon = Number(cronArray[3]);
    if (cronArray[2] === "*" || cronArray[1] === "*" || cronArray[0] === "*") {
      state.selectedPeriod = "custom";
    }
    return state;
  } else if (cronArray[2] !== "*") {
    state.selectedPeriod = "month";
    state.selectedMonthOption.min = cronArray[0] === "*" ? 0 : Number(cronArray[0]);
    state.selectedMonthOption.hour = cronArray[1] === "*" ? 0 : Number(cronArray[1]);
    state.selectedMonthOption.day = Number(cronArray[2]);
    if (cronArray[1] === "*" || cronArray[0] === "*") {
      state.selectedPeriod = "custom";
    }
    return state;
  } else if (cronArray[1] !== "*") {
    state.selectedPeriod = "day";
    state.selectedDayOption.min = cronArray[0] === "*" ? 0 : Number(cronArray[0]);
    state.selectedDayOption.hour = cronArray[1] === "*" ? 0 : Number(cronArray[1]);
    if (cronArray[0] === "*") {
      state.selectedPeriod = "custom";
    }
    return state;
  } else if (cronArray[0] !== "*") {
    state.selectedPeriod = "hour";
    state.selectedHourOption.min = cronArray[0] === "*" ? 0 : Number(cronArray[0]);
    return state;
  } else if (cronArray[0] === "*") {
    state.selectedPeriod = "custom";
    return state;
  } else {
    return state;
  }
}
