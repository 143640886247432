import React from "react";
import { Input } from "antd";
import FormRowWrapper from "./../formRowWrapper";

const Password = (props) => {
  const {
    formProps,
    onBlur,
    allowNulls,
    dataIndex,
    type = "password",
    isUnlockedForEditing,
    fieldMetaData,
    autoFocus,
    fieldRenderData,
  } = props;

  const { disabled } = formProps;
  const { min = 8, max, pattern, enumCheck } = fieldMetaData || {};
  const { placeholder = "input password", infoAdd, addRight, autoComplete, visibilityToggle } =
    fieldRenderData || {};

  const getInputComponent = () => {
    switch (type) {
      case "password":
        return (
          <Input.Password
            autoFocus={!disabled && autoFocus}
            autoComplete={autoComplete}
            placeholder={placeholder}
            disabled={disabled}
            onBlur={(e) => onBlur(e.target.value, dataIndex)}
          />
        );
    }
  };

  return (
    <FormRowWrapper
      {...props}
      effectiveRules={[{ required: !allowNulls }, { min }, { whitespace: true }]}
    >
      {getInputComponent()}
    </FormRowWrapper>
  );
};

export default Password;
