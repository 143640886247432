import React, { useContext } from "react";
import { useHttp } from "./../../hooks/useHttp";

import { Typography } from "antd";
import { UserDB } from "./../../contexts/userDB";
import AForm from "../common/aForm";
import { SmileOutlined } from "@ant-design/icons";
import { getPrimaryPalette } from "./../../utils/colorpalette";
import { ChangeFullName } from "../../services/userProfileService";

import Loadscreen from "./../common/layout/defaultPages/loadscreen";

const NeededInformation = (props) => {
  const { dispatchModal, getStateModal } = props;
  const { refetchCurrentUser, refetchDB } = useContext(UserDB);

  const [{}, executeNameChange] = useHttp(ChangeFullName, { manual: true });

  const palette = getPrimaryPalette();
  const currentState = getStateModal("NeededInfo");

  const handleChanges = async (values, reset) => {
    if (values.fullName) {
      await executeNameChange({ data: { newValue: values.fullName } });
      dispatchModal({
        type: "TOGGLE_VISIBILITY",
        visible: { NeededInfo: false },
      });
      refetchCurrentUser();
      refetchDB();
    }
  };

  return (
    <div style={{ padding: 48 }}>
      <Loadscreen loading={!currentState}>
        <div style={{ textAlign: "center" }}>
          <Typography.Title>
            {" "}
            <SmileOutlined style={{ fontSize: 24, color: palette.main }} /> Hello there
          </Typography.Title>
          <Typography.Text>We need a bit of information</Typography.Text>
        </div>
        <div style={{ marginTop: 40 }}>
          <AForm onFinish={handleChanges}>
            {currentState.info.includes("fullName") && (
              <AForm.Text
                autoFocus={true}
                dataIndex="fullName"
                fieldName="Full name"
                description="Please type in your full name so your colleagues can see who you are"
              />
            )}
            <AForm.ApproveButton />
          </AForm>
        </div>
      </Loadscreen>
    </div>
  );
};

export default NeededInformation;
