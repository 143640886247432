import React, { useState, useCallback, useContext, useEffect, useMemo } from "react";
import { useTaskBasket } from "../../../hooks/useTaskBasket";
import { useSuccessTimer } from "./../../../hooks/useSuccessTimer";

import { Utils } from "./../../../contexts/utils";

import moment from "moment";
import _ from "lodash";

import { Typography, Button, Row, Col, Form, Divider } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { getPrimaryPalette } from "./../../../utils/colorpalette";

import AForm from "./../../common/aForm/index";
import BasketList from "./basketList";
//import MassCreaterTour from "./../../tour/massCreaterTour";

import HeadlineBox from "./../../common/layout/viewBoxes/headlineBox";
import Loadscreen from "./../../common/layout/defaultPages/loadscreen";
//import TourButton from "./../../tour/commonTour/tourButton";

const titleSpan = { xs: 24, sm: 24, md: 24, lg: 20, xl: 20, xxl: 20 };
const formSpan = { xs: 24, sm: 24, md: 24, lg: 14, xl: 14, xxl: 14 };
const basketSpan = { xs: 24, sm: 24, md: 24, lg: 10, xl: 10, xxl: 10 };
const palette = getPrimaryPalette();

const NewBatchTasksContainer = (props) => {
  //Using a container to stop forms from updating when using the dispatches form hook
  const {
    taskTemplateNew,
    setNewTaskPrompt,
    setShowNewTaskPrompt,
    loadingTemplate,
    updateWhenNewTasks,
  } = props;
  const { keyFields, fieldValueList, processId, allowOptionForInitialNote, activationDate } =
    taskTemplateNew || {};

  const {
    getTaskBasket,
    getCurrentData,
    getCurrentKeys,
    currentKeyBatchSize,
    dispatchCurrentKeys,
    dispatchCurrentData,
    dispatchTaskBasket,
    formType,
    setFormType,
  } = useTaskBasket(keyFields, fieldValueList, processId);

  const basket = getTaskBasket();
  const basketSize = basket.basket.length;

  useEffect(() => {
    setNewTaskPrompt(basketSize > 0 ? () => true : () => false);
  }, [basketSize]);

  return (
    <NewBatchTasks
      templateLoading={loadingTemplate}
      dispatchCurrentKeys={dispatchCurrentKeys}
      dispatchCurrentData={dispatchCurrentData}
      dispatchTaskBasket={dispatchTaskBasket}
      updateWhenNewTasks={updateWhenNewTasks}
      keyFields={keyFields}
      processId={processId}
      getCurrentData={getCurrentData}
      getCurrentKeys={getCurrentKeys}
      getTaskBasket={getTaskBasket}
      fieldValueList={fieldValueList}
      currentKeyBatchSize={currentKeyBatchSize}
      allowOptionForInitialNote={allowOptionForInitialNote}
      setShowNewTaskPrompt={setShowNewTaskPrompt}
      activationDate={activationDate}
      formType={formType}
      setFormType={setFormType}
    />
  );
};

const NewBatchTasks = React.memo(
  ({
    updateWhenNewTasks,
    dispatchCurrentKeys,
    dispatchTaskBasket,
    dispatchCurrentData,
    getCurrentData,
    getCurrentKeys,
    getTaskBasket,
    keyFields,
    formType,
    setFormType,
    templateLoading,
    activationDate,
    setShowNewTaskPrompt,
    fieldValueList,
    currentKeyBatchSize,
    allowOptionForInitialNote,
  }) => {
    const [dataApproved, setDataApproved] = useState(false);

    //hack to force checkboxes in nullbox to be updated on change
    const [update, setUpdate] = useState(1);

    const { toggle: toggleSuccessAdd, stateBool: successAdd } = useSuccessTimer(1000);
    const { toggle: toggleSuccessChange, state: successChangeBatch } = useSuccessTimer(
      1000,
      "",
      null
    );

    const touchBeforeOk = () => {
      let effectiveTouchList = [];
      for (let y in fieldValueList) {
        if (!fieldValueList[y].allowNulls && !fieldValueList[y].defaultValue) {
          effectiveTouchList.push(fieldValueList[y].customFieldId);
        }
      }
      return effectiveTouchList;
    };

    const initialActivationDate = useMemo(
      () => (moment(activationDate).isSame(moment(), "day") ? null : moment(activationDate)),
      [activationDate]
    );

    //this throttles the update of data to better performance
    const throttleReadyChange = useCallback(
      _.throttle((value, dataIndex) => {
        dispatchCurrentData({
          type: "change",
          value,
          dataIndex,
        });
      }, 1000),
      []
    );

    const updateAndCloseModal = () => {
      updateWhenNewTasks();
      setShowNewTaskPrompt(false);
    };

    const handleFormChange = (name, props) => {
      switch (name) {
        case "data":
          let errorCount = props.forms.data
            .getFieldsError()
            .filter(({ errors }) => errors.length).length;
          let allTouched = props.forms.data.isFieldsTouched(touchBeforeOk(), true);
          if (errorCount === 0 && allTouched) {
            setDataApproved(true);
          } else if (dataApproved) {
            setDataApproved(false);
          }
          if (props.changedFields.length > 0) {
            switch (formType) {
              case "new":
              case "fresh":
                throttleReadyChange(props.changedFields[0].value, props.changedFields[0].name[0]);
                break;
            }
          }
          break;

        case "keys":
          if (props.changedFields.length > 0) {
            if (touchBeforeOk().length === 0) {
              setDataApproved(true);
            }

            switch (formType) {
              case "new":
                props.forms.data.validateFields();
              //break intentionally left out
              case "fresh":
                dispatchCurrentKeys({
                  type: "change",
                  value: props.changedFields[0].value,
                  dataIndex: props.changedFields[0].name[0],
                });

                break;
              case "change":
                setDataApproved(true);
            }
          }
          break;

        case "basket":
          //recognize a move to a new basket tab
          if (props.changedFields.length > 0) {
            let batchToChangeInto = getTaskBasket().basket.find(
              (batch) => batch.batchId === props.changedFields[0].value
            );

            if (props.changedFields[0].value === "new" || !batchToChangeInto) {
              getTaskBasket().basket.length > 0 && setFormType("new");

              props.forms.keys.setFieldsValue({
                ...getCurrentKeys(),
              });
              props.forms.data.setFieldsValue({
                ...getCurrentData(),
              });
            } else {
              //recognize a move to an existing tab
              setFormType("change");
              props.forms.keys.setFieldsValue({
                ...batchToChangeInto.keyFields,
              });
              props.forms.data.setFieldsValue({
                ...batchToChangeInto.fieldValueList,
              });

              setUpdate((u) => u + 1);
            }
          }
          break;
      }
    };

    const handleFormFinish = (name, { values, forms }) => {
      switch (name) {
        case "addbutton":
          if (formType === "new" || formType === "fresh") {
            dispatchTaskBasket({
              type: "addtobasket",
              keyFields: forms.keys.getFieldsValue(),
              fieldValueList: forms.data.getFieldsValue(),
            });
            toggleSuccessAdd();
            dispatchCurrentKeys({ type: "reset" });
            forms.basket.setFieldsValue({ basketselector: "new" });
            forms.keys.resetFields();
          } else if (formType === "change") {
            dispatchTaskBasket({
              type: "changebasket",
              keyFields: forms.keys.getFieldsValue(),
              fieldValueList: forms.data.getFieldsValue(),
              batchId: forms.basket.getFieldsValue().basketselector,
            });
            setDataApproved(false);
            toggleSuccessChange(forms.basket.getFieldsValue().basketselector);
          }
          break;

        case "clearbutton":
          forms.data.resetFields();
          setDataApproved(false);
          break;

        case "basket":
          dispatchTaskBasket({
            type: "CREATE_ALL",
            onSuccess: updateAndCloseModal,
          });
          break;
      }
    };

    const getAddButton = () => {
      const disabled =
        formType === "change" ? !dataApproved : !currentKeyBatchSize || !dataApproved;

      return (
        <Button type="primary" htmlType="submit" disabled={disabled}>
          {formType === "change" ? (
            "Save changes"
          ) : currentKeyBatchSize > 0 ? (
            <span>
              {`Add ${currentKeyBatchSize} to basket`}
              <RightOutlined />
            </span>
          ) : (
            "Add to basket"
          )}
        </Button>
      );
    };

    return (
      <Form.Provider onFormChange={handleFormChange} onFormFinish={handleFormFinish}>
        <Row justify="center" align="top">
          <Col {...titleSpan}>
            <HeadlineBox
              style={{ padding: "8px 0px 20px 0px" }}
              size="small"
              title="Create many tasks with different keys"
              //addRight={<TourButton currentTour="masscreate">Tour: Mass Create</TourButton>}
            />
          </Col>
        </Row>
        <Loadscreen loading={templateLoading}>
          <Row justify="center" align="top">
            <Col {...formSpan}>
              <div
                style={{
                  width: 600,
                  padding: 24,
                  transition: "border-color 0.3s",
                  border:
                    formType === "new" || formType === "fresh"
                      ? `1px dashed ${palette.lightGrey}`
                      : `1px solid ${palette.main}`,
                  backgroundColor:
                    formType === "new" || formType === "fresh" ? "inherit" : palette.mainVeryLight,
                  borderRadius: 4,
                }}
              >
                <Typography.Title level={4}>Tasks to be created</Typography.Title>
                <AForm
                  masscreatetour="masscreate-keys"
                  name="keys"
                  style={{ margin: 24 }}
                  applyDefaultValues={true}
                  showRequired={false}
                  waitForRequiredTouch="all"
                  onFinish={() => null}
                  loading={false}
                >
                  {keyFields.map(
                    (field, index) =>
                      field && (
                        <AForm.MultipleInput
                          key={`key${field.keyId}`}
                          fieldRenderData={{
                            type: "key",
                            placeholder: `add keys here to mass create`,
                          }}
                          style={{ borderColor: successAdd ? "green" : null }}
                          enterBehaviour="addonly"
                          allowNulls={true}
                          dataIndex={`key${field.keyId}`}
                          fieldName={field.fieldName}
                          description={
                            index > 0
                              ? "every combination of keys will create a task. You can paste columns from excel or separate using comma."
                              : "every key will be created as a task. You can paste columns from excel or separate using comma."
                          }
                        />
                      )
                  )}
                </AForm>
                <Divider />
                <Typography.Title level={4}>Shared data for all the tasks</Typography.Title>
                <AForm
                  updateKey={update}
                  masscreatetour="masscreate-data"
                  name="data"
                  style={{ margin: 24 }}
                  applyDefaultValues={true}
                  waitForRequiredTouch="all"
                  fields={fieldValueList}
                >
                  {allowOptionForInitialNote && (
                    <AForm.Text
                      fieldRenderData={{ collapsible: true }}
                      dataIndex="noteText"
                      fieldName="Add comment"
                      allowNulls={true}
                    />
                  )}
                  <AForm.DatePicker
                    fieldRenderData={{
                      collapsible: true,
                      infoAdd: "The Mate will wait untill this date to solve the task.",
                    }}
                    fieldMetaData={{
                      picker: "datetime",
                      disablePast: true,
                      defaultShowTime: false,
                    }}
                    nullBox={true}
                    dataIndex="activationDate"
                    fieldName="add activation date"
                    allowNulls={true}
                    fieldValue={initialActivationDate}
                  />
                </AForm>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: 24,
                  }}
                >
                  <Form name="clearbutton">
                    <Form.Item>
                      <Button type="text" htmlType="submit">
                        clear data
                      </Button>
                    </Form.Item>
                  </Form>

                  <Form name="addbutton">
                    <Form.Item>{getAddButton()}</Form.Item>
                  </Form>
                </div>
              </div>
            </Col>
            <Col {...basketSpan} style={{ marginTop: 40 }}>
              <Form name="basket">
                <Form.Item name="basketselector" noStyle>
                  <BasketList
                    taskBasket={getTaskBasket()}
                    successAdd={successAdd}
                    successChangeBatch={successChangeBatch}
                    dispatchTaskBasket={dispatchTaskBasket}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Loadscreen>
      </Form.Provider>
    );
  }
);

function areEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  return false;
}

export default NewBatchTasksContainer;

//removed for debug purposes
//   <MassCreaterTour keyFields={keyFields} fieldValueList={fieldValueList} />
