import React, { Component } from "react";
import AuthService from "./services/authService";

import { AuthContext } from "./contexts/authContext";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./components/auth/routes";
import { getFeatures } from "./utils/featureToggle";
import { FeaturesProvider } from "feature-toggle-jsx";

class AuthLoader extends Component {
  authService;
  features;

  constructor(props) {
    super(props);
    this.authService = new AuthService();
    this.features = getFeatures();
  }

  render() {
    return (
      <FeaturesProvider features={this.features}>
        <AuthContext.Provider value={this.authService}>
          <BrowserRouter children={<Routes />} />
        </AuthContext.Provider>
      </FeaturesProvider>
    );
  }
}

export default AuthLoader;
