import config from "./config.json";

export function GetProcessQueueCounts() {
  return {
    url: `${config.apiUrl}/Overview/GetProcessQueueCounts/`,
    method: "POST",
  };
}

export function GetMyOverview() {
  return { url: `${config.apiUrl}/Overview/GetMyOverview/`, method: "POST" };
}
