import React, { useContext } from "react";

import { AuthContext } from "../../contexts/authContext";
import Loadscreen from "./../common/layout/defaultPages/loadscreen";

export const Logout = () => {
  const user = useContext(AuthContext);

  user.logout();
  return <Loadscreen loading={true} message="Logging out" />;
};
