import http from "./httpService";
import config from "./config.json";

export function GetHeadersQueue(processKey, queue) {
  return {
    url: `${config.apiUrl}/QueueList/GetHeader/${processKey}/${queue}`,
    method: "GET",
  };
}

export function getQueueData(queueObject) {
  const { processKey, queue } = queueObject;
  const queueSelected = queue == null ? "" : "/" + queue;
  return http.get(
    config.apiUrl + "/QueueList/GetTasks/" + processKey + queueSelected
  );
}

export function GetTasksClosed() {
  return { url: `${config.apiUrl}/QueueList/GetTasksClosed`, method: "POST" };
}

export function GetTasksActive() {
  return { url: `${config.apiUrl}/QueueList/GetTasksActive`, method: "POST" };
}

export function getQueueLink(processKey, queue, taskId) {
  const staticLink = "/tasks/queue";
  const processAdd = !!processKey ? "/" + processKey : "";
  const queueAdd = !!queue ? "/" + queue : "";
  const taskIdAdd = !!taskId ? "?taskId=" + taskId : "";
  return staticLink + processAdd + queueAdd + taskIdAdd;
}
