import http from "./httpService";
import config from "./config.json";

export function GetUserLibrary() {
  return { url: config.apiUrl + "/UserInfo/GetUserLibrary", method: "GET" };
}

export function GetUserDetails() {
  return { url: `${config.apiUrl}/UserInfo/GetUserDetails`, method: "GET" };
}

export function GetAllowed() {
  return { url: `${config.apiUrl}/Permission/GetAllowed`, method: "GET" };
}

export function SetCurrentDepartment(departmentId) {
  return http.put(`${config.apiUrl}/UserProfile/SetCurrentDepartment/${departmentId}`);
}
export function GetRolePermissions(userRole) {
  return { url: `${config.apiUrl}/Permission/GetRolePermissions/${userRole}`, method: "GET" };
}
