import React from "react";
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";

const ExcelUploader = (props) => {
  const { onChange, value, disabled } = props;

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Upload.Dragger name="files" disabled={disabled} action="/upload.do">
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">Support for a single or bulk upload.</p>
    </Upload.Dragger>
  );
};

export default ExcelUploader;
