export const shallowCheck = (keys, prev, next) => {
  if (!prev || !next) {
    return false;
  }
  for (let i = 0; i < keys.length; i++) {
    if (prev[keys[i]] !== next[keys[i]]) {
      return false;
    } else {
      //pass
    }
  }
  return true;
};
