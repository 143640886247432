import React from "react";
import { Avatar, Tooltip, Typography } from "antd";
import BotStaticAvatar from "./../../../img/mates/bot/botStaticAvatar";

const UserAvatar = (props) => {
  const {
    user,
    type = "default",
    size = "large",
    style = {},
    onClick = null,
    defaultColor = null,
    tooltip = true,
    children,
  } = props;

  const {
    fullName = "user",
    userType = "user",
    avatarTextColor = "#000",
    avatarBackgroundColor = defaultColor ? defaultColor : "#6b818c",
    userInitials = "user",
    userRoles = [],
  } = user || {};

  const userRole = userRoles.length > 0 ? userRoles[0].roleName : "";

  return (
    <div>
      {userType !== "Bot" && (
        <Tooltip
          color="#fff"
          title={
            <Typography.Text>
              {fullName} <br />{" "}
              <Typography.Text type="secondary">{userRole}</Typography.Text>
            </Typography.Text>
          }
        >
          {type === "tooltip" ? (
            children
          ) : (
            <Avatar
              className={onClick !== null ? "clickable" : ""}
              onClick={onClick}
              style={{
                color: avatarTextColor,
                backgroundColor: avatarBackgroundColor,
                verticalAlign: "middle",
                ...style,
              }}
              size={size}
            >
              {userInitials}
            </Avatar>
          )}
        </Tooltip>
      )}

      {userType === "Bot" && (
        <div
          style={{
            width: size === "small" ? 20 : 40,
            height: size === "small" ? 20 : 40,
          }}
        >
          <Tooltip
            color="#fff"
            title={
              <Typography.Text>
                {fullName} <br />{" "}
                <Typography.Text type="secondary">{userRole}</Typography.Text>
              </Typography.Text>
            }
          >
            {type === "tooltip" ? (
              children
            ) : (
              <div>
                <BotStaticAvatar name={fullName} />
              </div>
            )}
          </Tooltip>
        </div>
      )}
    </div>
  );
};
export default UserAvatar;
