import React, { useEffect, useContext } from "react";

import { GetUserGuide } from "./../../services/processService";
import AForm from "./../common/aForm/index";
import { Typography } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useHttp } from "./../../hooks/useHttp";
import { ProcessTeams } from "./../../contexts/processTeams";

const OverviewDescription = (props) => {
  const { style = {}, onClose = null, processId } = props;
  const { getProcess } = useContext(ProcessTeams);
  const [{ data = { description: null, notLoadedYet: true }, loading: isLoading }, executeGet] =
    useHttp(GetUserGuide, { manual: true });

  useEffect(() => {
    if (processId) {
      executeGet({ url: GetUserGuide(processId).url });
    }
  }, [processId]);
  const process = getProcess(processId);

  return (
    <div style={{ minHeight: 100, ...style }}>
      <div
        style={{
          marginBottom: 12,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography.Text>Description</Typography.Text>

          {false && (
            <Link
              to={`/process/${process.processKey}/description`}
              style={{ marginLeft: 8, fontSize: 12 }}
              onClick={() => console.log("DAWN")}
            >
              {data.userGuide ? "Edit" : "Create new"}
            </Link>
          )}
        </div>
        {onClose && (
          <div>
            <CloseOutlined onClick={onClose} />
          </div>
        )}
      </div>
      {!data.notLoadedYet ? (
        data.userGuide ? (
          <AForm
            updateKey={data.modifiedDate}
            loading={isLoading}
            disabled={true}
            initialValues={{
              processDescription: data.userGuide,
            }}
          >
            <AForm.RichText
              dataIndex="processDescription"
              style={{
                maxHeight: 500,
                overflow: "auto",
                backgroundColor: "inherit",
              }}
            />
          </AForm>
        ) : (
          <div style={{ margin: 24, paddingBottom: 24 }}>
            <Typography.Text type="secondary" style={{ fontType: "italic" }}>
              Create a user guide and a description to help the team remember how the robot is
              working and how they should work with it.
            </Typography.Text>
          </div>
        )
      ) : (
        <span />
      )}
    </div>
  );
};

export default OverviewDescription;
