import React from "react";
import { Typography, Button } from "antd";
import { getPrimaryPalette } from "./../../../utils/colorpalette";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";

const BasketList = (props) => {
  const {
    onChange,
    value,
    taskBasket = {},
    successAdd,
    dispatchTaskBasket,
    successChangeBatch,
  } = props;
  const palette = getPrimaryPalette();

  const handleBatchClick = (newBatchId) => {
    onChange(newBatchId);
  };

  const getBatchStyle = (batchId) => {
    if (successAdd && taskBasket.basket[taskBasket.basket.length - 1].batchId === batchId) {
      return { backgroundColor: palette.green, color: "#fff" };
    }
    if (successChangeBatch && successChangeBatch === batchId) {
      return { backgroundColor: palette.green, color: "#fff" };
    }

    if (batchId === value) {
      return { backgroundColor: palette.mainVeryLight };
    }
    return {};
  };

  const getNewStyle = () => {
    if (value === "new") {
      return { color: palette.main, border: `1px dashed ${palette.main}` };
    }
    return {
      color: palette.lightGrey,
      border: `1px dashed ${palette.lightGrey}`,
    };
  };

  return (
    <div
      masscreatetour="masscreate-basket"
      style={{
        border: "1px solid lightgrey",
        borderRadius: 2,
        textAlign: "center",
        backgroundColor: "#fff",
        paddingTop: 12,
        width: 260,
        marginLeft: -32,
      }}
    >
      <Typography.Text strong>Basket</Typography.Text>
      {taskBasket.basket.map((batch, index) => (
        <div
          style={{
            position: "relative",
            transition: "background-color 0.3s ease, color 0.3s ease",
            padding: "0px 24px 0px 24px",
            borderWidth: 1,
            borderColor: "lightgrey",
            borderBottomStyle: "solid",
            borderTopStyle: index === 0 ? "solid" : "inherit",
            ...getBatchStyle(batch.batchId),
          }}
          key={batch.batchId}
          onClick={() => handleBatchClick(batch.batchId)}
        >
          <div style={{ paddingTop: 12 }}>{batch.taskCount} Tasks </div>
          <div style={{ height: 32, margin: 12 }}>
            {batch.batchId === value && (
              <React.Fragment>
                <div style={{ position: "absolute", top: 6, right: 6 }}>
                  <CloseOutlined
                    onClick={() =>
                      dispatchTaskBasket({
                        type: "removefrombasket",
                        batchId: batch.batchId,
                      })
                    }
                  />
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      ))}
      <div
        style={{
          padding: 12,
          margin: "12px 24px 24px 24px",
          height: 60,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...getNewStyle(),
        }}
        className="clickable"
        onClick={() => handleBatchClick("new")}
      >
        {taskBasket.basket.length === 0 ? (
          <Typography.Text type="secondary" style={{ fontStyle: "italic" }}>
            no tasks in basket yet
          </Typography.Text>
        ) : (
          <PlusOutlined />
        )}
      </div>
      <div style={{ textAlign: "center", margin: 24 }}>
        <Button
          masscreatetour="masscreate-create"
          block
          type={"primary"}
          disabled={taskBasket.basket.length > 0 ? false : true}
          htmlType="submit"
          loading={taskBasket.loading}
        >
          Create all tasks
        </Button>
      </div>
    </div>
  );
};

export default BasketList;
