import { IDENTITY_CONFIG, METADATA_OIDC } from "../utils/authConst";
import { UserManager, WebStorageStateStore /*Log */ } from "oidc-client";

export default class AuthService {
  UserManager;
  _accessToken;
  _callbacks = [];
  _nextSubscriptionId = 0;
  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      metadata: {
        ...METADATA_OIDC,
      },
    });

    //Log.logger = console;
    //Log.level = Log.DEBUG;

    this.UserManager.events.addUserLoaded((user) => {
      this._accessToken = user.access_token;

      if (window.location.href.indexOf("callback") !== -1) {
        this.navigateToScreen();
      }
      this.notifySubscribers();
    });

    this.UserManager.events.addUserSignedOut(() => {});

    this.UserManager.events.addUserUnloaded(() => {});

    this.UserManager.events.addSilentRenewError((e) => {
      console.error("silent renew error", e.message);
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      this.signinSilent();
    });

    this.UserManager.events.addAccessTokenExpiring(() => {
      this.signinSilent();
    });
  }

  async getAccessToken() {
    const user = await this.UserManager.getUser();
    return user && user.access_token;
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback();
  };

  async getAccessToken() {
    const user = await this.UserManager.getUser();
    return user && user.access_token;
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback();
  };

  getUser = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      return undefined;
    }
    return user;
  };

  parseJwt = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = () => {
    localStorage.setItem("redirectUri", window.location.pathname);
    this.UserManager.signinRedirect({});
  };

  navigateToScreen = () => {
    const redirectUri = !!localStorage.getItem("redirectUri")
      ? localStorage.getItem("redirectUri")
      : "/";
    window.location.replace(redirectUri);
  };

  async isAuthenticated() {
    const user = await this.getUser();
    const { access_token } = user || {};
    if (!access_token) {
      return false;
    }
    try {
      const { exp, client_id, aud } = this.parseJwt(access_token);
      if (client_id != "js") {
        return false;
      }
      if (!aud.includes("clientmate")) {
        return false;
      }
      if (Date.now() >= exp * 1000) {
        return false;
      }
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
    //fallback, never happens
    return false;
  }

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then((user) => {})
      .catch((err) => {
        console.log(err);
      });
  };
  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = async () => {
    const user = await this.getUser();

    if (!user) {
      //pass dont know how we ever get here
    } else {
      this.UserManager.signoutRedirect({
        id_token_hint: user.id_token,
      });
    }

    localStorage.clear();
    this.UserManager.clearStaleState(new WebStorageStateStore({ store: window.localStorage }));
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.location.replace(process.env.REACT_APP_PUBLIC_URL);
    });
    this.UserManager.clearStaleState(new WebStorageStateStore({ store: window.localStorage }));
  };

  subscribe(callback) {
    this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });

    return this._nextSubscriptionId - 1;
  }

  unsubscribe(subscriptionId) {
    const subscriptionIndex = this._callbacks
      .map((element, index) =>
        element.subscription === subscriptionId ? { found: true, index } : { found: false }
      )
      .filter((element) => element.found === true);
    if (subscriptionIndex.length > 0) {
      this._callbacks.splice(subscriptionIndex[0].index, 1);
    } else {
      console.error("trying to unsubscripe with an id that is not in the subscription list");
    }
  }

  notifySubscribers() {
    for (let i = 0; i < this._callbacks.length; i++) {
      const callback = this._callbacks[i].callback;
      callback();
    }
  }
}
