import { useEnhancedReducer } from "./useEnhancedReducer";
import { GetMyOverview } from "../services/overviewService";
import http from "../services/httpService";
const notInProduction = ["PhasingOut", "Inactive", "Development"];

const setup = {
  data: [],
  loading: true,
  firstLoad: false,
  error: null,
  pinOnTop: true,
  sortBy: "active",
  onlyProduction: false,
  department: "all",
  layout: "card",
  sortOrder: [],
  //used to trigger updates on the flipped component
  flipKey: "noData",
};

export const getCount = (allQueues, queues) => {
  return queues.reduce((total, current) => {
    return total + allQueues.find((q) => q.queue === current).queueCount;
  }, 0);
};

export const getQueue = (allQueues, queue) => {
  return allQueues.find((q) => q.queue === queue);
};

const getFlipKey = (data) => (data.length > 0 ? data.map((process) => process).join("") : "noData");

const sortAndFilter = (state) => {
  const filtered = [...state.data].filter((process) => {
    if (state.onlyProduction === true) {
      if (state.department === "all") {
        return !notInProduction.includes(process.lifecycleState);
      } else {
        return (
          !notInProduction.includes(process.lifecycleState) &&
          process.departmentKey === state.department
        );
      }
    } else {
      if (state.department === "all") {
        return true;
      } else {
        return process.departmentKey === state.department;
      }
    }
  });
  let sortMap = filtered.map((process) => {
    return {
      processId: process.processId,
      processKey: process.processKey,
      mateStateInt: process.avatar.mateStateInt,
      processHasTasks: process.processHasTasks,
      processGroupId: process.processGroupId,
      isFavorite: process.isFavorite,
      solvedCount: getCount(process.queueData, ["Solved"]),
      activeCount: getCount(process.queueData, ["Ready", "NotReady", "NeedsApproval", "Manual"]),
    };
  });

  switch (state.sortBy) {
    case "new":
      sortMap.sort((a, b) => {
        return a.processId < b.processId ? 1 : -1;
      });
      break;
    case "old":
      sortMap.sort((a, b) => {
        return a.processId > b.processId ? 1 : -1;
      });

      break;
    case "groups":
      sortMap.sort((a, b) => {
        return a.processGroupId < b.processGroupId ? 1 : -1;
      });
      break;
    case "active":
      sortMap.sort((a, b) => {
        return a.activeCount < b.activeCount
          ? 1
          : a.activeCount === 0 && b.processHasTasks && !a.processHasTasks
          ? 1
          : -1;
      });
      break;
    case "solved":
      sortMap.sort((a, b) => {
        return a.solvedCount < b.solvedCount
          ? 1
          : a.solvedCount === 0 && b.processHasTasks && !a.processHasTasks
          ? 1
          : -1;
      });
      break;
    case "warnings":
      sortMap.sort((a, b) => {
        return a.mateStateInt < b.mateStateInt ? 1 : -1;
      });
      break;
    default:
      break;
  }

  if (state.pinOnTop) {
    sortMap.sort((a, b) => {
      return a.isFavorite === b.isFavorite ? 0 : a.isFavorite ? -1 : 1;
    });
  }

  const newSortOrder = sortMap.map((process) => process.processKey);
  return newSortOrder;
};

export function useOverview({ currentTimeFrame }) {
  function reducer(state, action) {
    let proposedNewState;
    let newSortOrder;
    let flipKey;
    switch (action.type) {
      case "START_LOAD":
        return { ...state, loading: true };
      case "FINISH_FETCH":
        proposedNewState = {
          ...state,
          data: action.data,
          loading: false,
          error: null,
          firstLoad: true,
        };
        newSortOrder = sortAndFilter(proposedNewState);
        flipKey = getFlipKey(newSortOrder);

        return { ...proposedNewState, sortOrder: newSortOrder, flipKey };
      case "SHOW_ERROR":
        return { ...state, loading: false, error: action.error };
      case "SORT":
        const newSort = { ...action };
        delete newSort.type;
        proposedNewState = { ...state, ...newSort };
        newSortOrder = sortAndFilter(proposedNewState);
        flipKey = getFlipKey(newSortOrder);
        return { ...proposedNewState, sortOrder: newSortOrder, flipKey };

      case "CHANGE_LAYOUT":
        return { ...state, layout: action.layout };
      default:
        console.error("unhandled actiontype", action.type);
        return { ...state };
    }
  }

  const asyncActionHandlers = {
    FETCH_OVERVIEW: ({ dispatch, getState, signal }) => async (action) => {
      if (currentTimeFrame.fromDate && currentTimeFrame.toDate) {
        !action.hideLoader && dispatch({ type: "START_LOAD" });
        try {
          const answer = await http.do({
            ...GetMyOverview(),
            data: {
              fromDate: currentTimeFrame.fromDate.format(),
              toDate: currentTimeFrame.toDate.format(),
            },
          });
          dispatch({
            type: "FINISH_FETCH",
            data: [...answer.data],
          });
        } catch {
          dispatch({ type: "SHOW_ERROR", error: "Refresh failed" });
        }
      }
    },
  };

  const [[state, dispatch], getState] = useEnhancedReducer(
    reducer,
    setup,
    undefined,
    asyncActionHandlers
  );

  return [state, dispatch];
}
