import React, { useContext, Suspense } from "react";
import { withFeature } from "feature-toggle-jsx";
import { Modal, Spin } from "antd";

import { Utils } from "./../../../contexts/utils";

import SuspenseLoader from "./../../common/loaders/suspenseLoader";
import { getLazyComponent } from "./../../../utils/routerHelpers";

const IntroAgentContent = getLazyComponent(() => import("./introAgentContent"));
const IntroDeveloperContent = getLazyComponent(() => import("./introDeveloperContent"));

const IntroModal = (props) => {
  const { zIndex, visibleModal, dispatchModal } = props;

  const { permittor, permissionsLoading } = useContext(Utils);

  const isDeveloper = permittor("DeveloperPermissions");

  const closeModal = () => {
    dispatchModal({
      type: "TOGGLE_VISIBILITY",
      visible: { Intro: false },
    });
  };

  return (
    <Modal
      zIndex={zIndex}
      width={1000}
      style={{ top: 60 }}
      visible={visibleModal("Intro")}
      onCancel={closeModal}
      footer={null}
      destroyOnClose={true}
      maskClosable={true}
    >
      <Spin spinning={permissionsLoading}>
        <Suspense fallback={<SuspenseLoader />}>
          {isDeveloper ? (
            <IntroDeveloperContent closeModal={closeModal} />
          ) : (
            <IntroAgentContent closeModal={closeModal} />
          )}
        </Suspense>
      </Spin>
    </Modal>
  );
};

export default withFeature(IntroModal, "introModal", (_) => _.isEnabled);
