import React from "react";

import FormRowWrapper from "./../formRowWrapper";
import DateInput from "./../inputComponents/dates/dateInput";

import moment from "moment";

const DatePicker = (props) => {
  const {
    onBlur,
    masterType,
    style,
    apiCheck,
    allowNulls,
    dataIndex,
    fieldName,
    autoFocus = false,
    isUnlockedForEditing,
    formProps,
    description,
    fieldMetaData,
    fieldRenderData,
  } = props;

  const { disabled } = formProps;
  const { disablePast, picker = "date", defaultShowTime } = fieldMetaData || {};
  const { collapsible, infoAdd, addRight, showAs, showSeconds = false } = fieldRenderData || {};

  let getRules = () => {
    let rules = [{ required: !allowNulls }];
    disablePast &&
      rules.push((formProps) => ({
        validator: async (rule, value) => {
          if (value && moment(value).isBefore(moment().startOf("day"))) {
            return Promise.reject("Date cannot be in the past");
          } else {
            return Promise.resolve("Field is Good");
          }
        },
      }));
    return rules;
  };

  const getInputComponent = () => {
    return (
      <DateInput
        showAs={showAs}
        defaultShowTime={defaultShowTime}
        picker={picker}
        disablePast={disablePast}
        allowClear={allowNulls}
        disabled={disabled}
        onBlur={(e) => onBlur(e, dataIndex)}
        showSeconds={showSeconds}
        style={style}
        fieldName={fieldName}
        description={description}
      />
    );
  };

  const infoDatePropAdd = showAs === "infodate" ? { noStyle: true, overrideAllowCopy: false } : {};

  return (
    <FormRowWrapper {...props} effectiveRules={getRules()} {...infoDatePropAdd}>
      {getInputComponent()}
    </FormRowWrapper>
  );
};

export default DatePicker;
