import React from "react";

import ColorPicker from "../inputComponents/color/colorPicker";
import FormRowWrapper from "./../formRowWrapper";

const Color = (props) => {
  const {
    formProps,
    onBlur,
    masterType,
    apiCheck,
    allowNulls,
    dataIndex,
    fieldName,
    type = "circles",
    autoFocus = false,
    isUnlockedForEditing,
    fieldMetaData,
    fieldRenderData,
  } = props;

  const { disabled } = formProps;

  const {} = fieldMetaData || {};
  const {} = fieldRenderData || {};

  let effectiveRules = [{ required: !allowNulls }];

  const handleBlur = (newValue) => {
    onBlur(newValue, dataIndex);
  };
  const getInputComponent = () => {
    switch (type) {
      case "circles":
        return <ColorPicker disabled={disabled} onBlur={handleBlur} dataIndex={dataIndex} />;
    }
  };

  return (
    <FormRowWrapper {...props} effectiveRules={effectiveRules} overrideAllowCopy={false}>
      {getInputComponent()}
    </FormRowWrapper>
  );
};

export default Color;
