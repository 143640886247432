import React from "react";
import { default as TableComponent } from "../inputComponents/table/index";
import FormRowWrapper from "./../formRowWrapper";

const Table = (props) => {
  const {
    formProps,
    onBlur,
    allowNulls,
    dataIndex,
    type = "default",
    autoFocus = false,
    isUnlockedForEditing,
    fieldMetaData,
    fieldRenderData,
    handleMetaField,
    canAddColums,
    canDeleteColumns,
  } = props;

  const { disabled } = formProps;

  const effectiveRules = [
    (formProps) => ({
      validator: async (rule, value) => {
        if (value && value.length > 0) {
          return Promise.resolve("Link is available");
        } else {
          return Promise.reject("Field is Required");
        }
      },
    }),
  ];

  switch (type) {
    case "default":
      return (
        <FormRowWrapper
          {...props}
          overrideAllowCopy={false}
          effectiveRules={allowNulls ? [] : [...effectiveRules]}
        >
          <TableComponent
            dataIndex={dataIndex}
            fieldMetaData={fieldMetaData}
            disabled={disabled}
            canAddColums={canAddColums}
            canDeleteColumns={canDeleteColumns}
            allowNulls={allowNulls}
            handleMetaField={handleMetaField}
          />
        </FormRowWrapper>
      );
      break;
  }
};
export default Table;
