import React from "react";
import { Transfer, ConfigProvider } from "antd";

const SelectTransfer = (props) => {
  const {
    onChange,
    value,
    disabled,
    allowClear,
    onBlur,
    options = [],
    renderNoOptions,
    customRender,
    keyName = "selectValue",
    titles = ["not selected", "selected"],
    searchFunction,
  } = props;

  const handleChange = (newValue) => {
    onChange(newValue);
    onBlur(newValue);
  };

  const optionsDataSource = options
    ? options.map((option) => ({
        ...option,
        key: option[keyName],
      }))
    : [];

  return (
    <Transfer
      filterOption={
        typeof searchFunction === "function"
          ? searchFunction
          : (inputValue, item) => {
              return item.title.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
            }
      }
      titles={titles}
      targetKeys={value || []}
      showSearch
      allowClear={allowClear}
      dataSource={optionsDataSource}
      disabled={disabled}
      style={{ width: "100%" }}
      onChange={(e) => handleChange(e)}
      listStyle={{
        width: "50%",
        minHeight: 500,
      }}
      render={(record) => {
        if (typeof customRender === "function") {
          return customRender(record);
        } else {
          return <div style={{ display: "inline-block" }}>{record.title}</div>;
        }
      }}
    ></Transfer>
  );
};

export default SelectTransfer;
