import React from "react";

const NotFound = props => {
  return (
    <div style={{ marginTop: 30, textAlign: "center" }}>
      Nothing found on this link, Sorry :( <br /> Please try again and report to us if you are certain this link should
      work
    </div>
  );
};

export default NotFound;
