import React from "react";
import { queueTypes } from "./../../../utils/queueTypes";
import { Tag, Dropdown } from "antd";
import dayjs from "dayjs";

const QueueTag = (props) => {
  const { children = null, activeQueue, style = {}, activationDate } = props;
  const queueLabel = queueTypes.find((option) => option.key === activeQueue);

  activationDate && console.log(activationDate, queueLabel);

  const getCorrectLabel = () => {
    if (!activationDate) {
      return queueLabel.label;
    } else if (dayjs(activationDate).isAfter(dayjs())) {
      return "Not active yet";
    } else {
      return queueLabel.label;
    }
  };

  if (!!children) {
    return (
      <Dropdown overlay={children} trigger={["click"]}>
        <a href="#">
          <Tag className="clickable" color={queueLabel.color}>
            {queueLabel ? queueLabel.label : "queue"}
          </Tag>
        </a>
      </Dropdown>
    );
  } else {
    return (
      <span>
        {queueLabel !== undefined && (
          <Tag
            color={queueLabel.color}
            id="queuetag"
            style={{ ...style }}
            onClick={(e) => e.stopPropagation()}
          >
            {getCorrectLabel()}
          </Tag>
        )}
      </span>
    );
  }
};

export default QueueTag;
