export function getGrid(totalWidth, columns, addButton = 0) {
  const widthForInputBars = totalWidth - addButton;
  const standardWidth = Math.floor(widthForInputBars / columns);
  const lastWidth = standardWidth + widthForInputBars - columns * standardWidth;
  return [standardWidth, lastWidth, addButton];
}

export const drawToken = (token) => {
  if (token) {
    return token.substring(token.length - 10);
  }
};
