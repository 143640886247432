import React from "react";
import FormRowWrapper from "./../formRowWrapper";
import InputMultipleComponent from "./../inputComponents/inputMultiple/inputMultipleComponent";
import { KeyOutlined } from "@ant-design/icons";

const MultipleInput = (props) => {
  const {
    formProps,
    onBlur,
    masterType,
    apiCheck,
    allowNulls,
    dataIndex,
    fieldName,

    autoFocus = false,
    isUnlockedForEditing,
    specificFormItemProps,
    fieldMetaData,
    fieldRenderData,
    enterBehaviour,
    style = {},
  } = props;

  const { disabled } = formProps;
  const { min, max, pattern, enumCheck } = fieldMetaData || {};
  const { placeholder, type = "key" } = fieldRenderData || {};

  const getInputComponent = () => {
    switch (type) {
      case "key":
        return (
          <InputMultipleComponent
            enterBehaviour={enterBehaviour}
            placeholder={placeholder}
            allowNulls={allowNulls}
            disabled={disabled}
            style={{ width: "100%", ...style }}
            onBlur={(newValue) => {
              onBlur(newValue, dataIndex);
            }}
          />
        );
    }
  };

  return (
    <FormRowWrapper
      {...props}
      title={
        <span>
          {fieldName} {type === "key" && <KeyOutlined />}
        </span>
      }
      effectiveRules={[{ required: !allowNulls }]}
    >
      {getInputComponent()}
    </FormRowWrapper>
  );
};

export default MultipleInput;
