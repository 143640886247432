import React, { useState } from "react";
import { Typography } from "antd";
import { getPrimaryPalette } from "../../../../../../utils/colorpalette";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const SelectIconOption = (props) => {
  const { option, value, disabled, onClick, allowClear } = props;
  const {
    selectValue,
    alreadySelected,
    icon,
    title,
    description,
    focusDescription,
    disabled: optionDisabled,
  } = option;
  const [hovered, setHovered] = useState(false);
  const palette = getPrimaryPalette();

  const currentlySelected = value === selectValue;

  const effectiveDisabled = disabled || optionDisabled;

  const handleHovered = (newValue) => {
    if (!effectiveDisabled) {
      setHovered(newValue);
    }
  };

  const handleClick = (e) => {
    if (!effectiveDisabled && e.target.id !== "preventselect") {
      onClick(selectValue);
    }
  };

  return (
    <div
      className={effectiveDisabled ? "" : "clickable"}
      style={{
        position: "relative",
        marginRight: 6,
        width: 200,
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        border: currentlySelected
          ? `1px solid ${palette.main}`
          : hovered
          ? `1px solid ${palette.lightGrey}`
          : `1px solid transparent`,
        borderRadius: 4,
      }}
      onMouseOver={() => handleHovered(true)}
      onMouseLeave={() => handleHovered(false)}
      onClick={handleClick}
    >
      <div>
        <Typography.Text
          style={{ fontWeight: 800, fontSize: 20, color: currentlySelected ? palette.main : "" }}
        >
          {title}
        </Typography.Text>
      </div>
      <div>
        <Typography.Paragraph>{description}</Typography.Paragraph>
      </div>
      <div style={{ width: "100%", textAlign: "center" }}>{icon}</div>
      <div style={{ padding: 12 }}>
        <Typography.Paragraph>{focusDescription}</Typography.Paragraph>
      </div>
      {currentlySelected && (
        <div style={{ position: "absolute", right: 6, bottom: 6 }}>
          <CheckOutlined style={{ fontSize: 20, color: palette.main }} />
        </div>
      )}
      {allowClear && currentlySelected && (
        <div style={{ position: "absolute", right: 6, top: 6 }}>
          <CloseOutlined onClick={() => onClick()} />
        </div>
      )}
    </div>
  );
};

export default SelectIconOption;
