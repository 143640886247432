import moment from "moment";

export const defaultShortRanges = [
  {
    key: "this1Day",
    title: "Today",
    description: "today",
    toDate: moment().endOf("day"),
    fromDate: moment().startOf("day"),
    cycleResolution: { value: 1, resolution: "day" },
  },
  /* {
    key: "last1Day",
    title: "Yesterday",
    description: "yesterday",
    toDate: moment().endOf("day").subtract(1, "day"),
    fromDate: moment().startOf("day").subtract(1, "day"),
    cycleResolution: { value: 1, resolution: "day" },
  },*/
  {
    key: "last7Days",
    title: "7 Days",
    description: "last 7 days",
    toDate: moment().endOf("day"),
    fromDate: moment().subtract(7, "day").startOf("day"),
    cycleResolution: { value: 7, resolution: "day" },
  },
  {
    key: "this1Week",
    title: "This Week",
    description: "this week",
    toDate: moment().endOf("week"),
    fromDate: moment().startOf("week"),
    cycleResolution: { value: 7, resolution: "day" },
  },
  {
    key: "this1Month",
    title: "This Month",
    description: "this month",
    toDate: moment().endOf("month"),
    fromDate: moment().startOf("month"),
    cycleResolution: { value: 1, resolution: "month" },
  },
  {
    key: "last1Month",
    title: "1 Month",
    description: "last 1 month",
    toDate: moment().endOf("day"),
    fromDate: moment().subtract(1, "month").startOf("day"),
    cycleResolution: { value: 1, resolution: "month" },
  },
  {
    key: "last2Months",
    title: "2 Months",
    description: "last 2 Months",
    toDate: moment().endOf("day"),
    fromDate: moment().subtract(2, "month").startOf("day"),
    cycleResolution: { value: 1, resolution: "month" },
  },
];

export const defaultLongRanges = [
  {
    key: "last7Days",
    title: "7 Days",
    description: "last 7 days",
    toDate: moment().endOf("day"),
    fromDate: moment().subtract(7, "day").startOf("day"),
    cycleResolution: { value: 7, resolution: "day" },
  },
  {
    key: "last1Month",
    title: "1 Month",
    description: "last 1 month",
    toDate: moment().endOf("day"),
    fromDate: moment().subtract(1, "month").startOf("day"),
    cycleResolution: { value: 1, resolution: "month" },
  },
  {
    key: "last3Months",
    title: "3 Months",
    description: "last 3 months",
    toDate: moment().endOf("day"),
    fromDate: moment().subtract(3, "month").startOf("month"),
    cycleResolution: { value: 1, resolution: "quarter" },
  },
  {
    key: "last6Months",
    title: "6 Months",
    description: "last 6 months",
    toDate: moment().endOf("day"),
    fromDate: moment().subtract(6, "month").startOf("month"),
    cycleResolution: { value: 1, resolution: "quarter" },
  },
  {
    key: "yeartodate",
    title: "YTD",
    description: "year to date",
    toDate: moment().endOf("year"),
    fromDate: moment().startOf("year"),
    cycleResolution: { value: 1, resolution: "year" },
  },
  {
    key: "1Year",
    title: "1 Year",
    description: "last 1 year",
    toDate: moment().endOf("day"),
    fromDate: moment().subtract(1, "year").startOf("month"),
    cycleResolution: { value: 1, resolution: "year" },
  },
];

export const defaultReportRanges = [
  {
    key: "reportWeek",
    title: "Week",
    description: "Work week",
    toDate: moment().endOf("week"),
    fromDate: moment().startOf("week"),
    cycleResolution: { value: 1, resolution: "week" },
    strict: true,
  },
  {
    key: "reportMonth",
    title: "Month",
    description: "Calendar month",
    toDate: moment().endOf("Month"),
    fromDate: moment().startOf("Month"),
    cycleResolution: { value: 1, resolution: "Month" },
    strict: true,
  },
  {
    key: "reportYear",
    title: "Year",
    description: "Fiscal year",
    toDate: moment().endOf("year"),
    fromDate: moment().startOf("year"),
    cycleResolution: { value: 1, resolution: "year" },
    strict: true,
  },
];
