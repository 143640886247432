import React from "react";
import { notification, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { setTwoToneColor } from "@ant-design/icons";
import { getPrimaryPalette } from "./colorpalette";
import "moment/locale/da";
import "moment/locale/en-gb";
import moment from "moment";

export function antdConfig() {
  const palette = getPrimaryPalette();
  notification.config({
    placement: "bottomLeft",
    top: 50,
    duration: 7,
  });

  moment.locale("da");

  Spin.setDefaultIndicator(getSpinner());

  setTwoToneColor(palette.main);
}

export function getSpinner() {
  return <LoadingOutlined style={{ fontSize: 24 }} spin />;
}

export const formConfig = {
  validateMessages: {
    required: "Field is required",
    whitespace: "Field cannot be empty",
    string: {
      len: "Field must be exactly ${len} characters",
      min: "Field must be at least ${min} characters",
      max: "Field cannot be longer than ${max} characters",
    },
    pattern: {
      mismatch: "Invalid characters",
    },
  },
};
