import React, { useState, useEffect } from "react";
import { Select, TimePicker, Input, Button } from "antd";
import { getState, periodOptions } from "./cron-helpers";
import moment from "moment";
import { SaveOutlined, CheckOutlined } from "@ant-design/icons";
import { useSuccessTimer } from "../../../../../hooks/useSuccessTimer";
import { getPrimaryPalette } from "../../../../../utils/colorpalette";

const palette = getPrimaryPalette();

const weekOptions = [...Array(7).keys()].map((day) => ({
  day: day.toString(),
  title: moment().days(day).format("dddd"),
}));
const dateOptions = [...Array(31).keys()].map((date) => ({
  date: (date + 1).toString(),
  title: (date + 1).toString() + ".",
}));

const monthOptions = [...Array(12).keys()].map((month) => ({
  month: month.toString(),
  title: moment().month(month).format("MMMM"),
}));

const CronInput = (props) => {
  const timer = useSuccessTimer();
  const { value, onChange, disabled, onBlur } = props;
  const [selectedPeriod, setSelectedPeriod] = useState(getState(value).selectedPeriod);
  const [localValue, setLocalValue] = useState(value || "* * * * *");

  const getValue = (selectedPeriod, placement) => {
    let cronArray = localValue.split(" ");
    let cronArrayNull = cronArray.map((node) => (node === "*" ? null : node));

    switch (placement) {
      case "time":
        if (selectedPeriod === "hour") {
          if (cronArrayNull[0]) {
            return moment(cronArrayNull[0], "mm");
          } else return null;
        } else {
          if (cronArrayNull[1] && cronArrayNull[0]) {
            return moment(`${cronArrayNull[1] || "00"}:${cronArrayNull[0]}`, "HH:mm");
          } else {
            return null;
          }
        }
      case "weekday":
        return cronArrayNull[4] || null;
      case "date":
        return cronArrayNull[2] || null;
      case "month":
        return cronArrayNull[3] || null;
    }
  };

  const handleSelectedPeriod = (newPeriod) => {
    const periodObject = periodOptions.find((period) => period.value === newPeriod);
    const localValueSplit = localValue.split(" ");
    const newValue = localValueSplit.map((node, index) => {
      if (periodObject.override[index]) {
        return "*";
      } else {
        return node;
      }
    });
    setLocalValue(newValue.join(" "));
    setSelectedPeriod(newPeriod);
  };

  const setValue = (values) => {
    let cronArray = localValue.split(" ");
    for (let k in values) {
      cronArray[Number(k)] = values[k];
    }
    let newArray = cronArray.join(" ");
    setLocalValue(newArray);
  };

  const handleOnClick = () => {
    timer.toggle();
    onChange(localValue);
    onBlur(localValue);
  };

  return (
    <div style={{ width: "100%" }}>
      <Input.Group compact>
        <Select
          value={selectedPeriod}
          onChange={handleSelectedPeriod}
          style={{ width: 180 }}
          disabled={disabled}
          optionLabelProp="title"
        >
          {periodOptions.map((period) => (
            <Select.Option key={period.value} value={period.value} title={period.title}>
              {period.label}
            </Select.Option>
          ))}
        </Select>
        {["custom"].includes(selectedPeriod) && (
          <Input
            style={{ width: 140 }}
            defaultValue={value}
            placeholder="* * * * *"
            onChange={(e) => setLocalValue(e.target.value)}
          />
        )}
        {["hour"].includes(selectedPeriod) && (
          <TimePicker
            disabled={disabled}
            format={"[minute] mm"}
            style={{ width: 140 }}
            value={getValue(selectedPeriod, "time")}
            onSelect={(time) => {
              setValue({ 0: time.format("mm") });
            }}
          />
        )}
        {["month", "year"].includes(selectedPeriod) && (
          <Select
            disabled={disabled}
            style={{ width: 80 }}
            value={getValue(selectedPeriod, "date")}
            onChange={(value) => setValue({ 2: value })}
            optionLabelProp="title"
          >
            {dateOptions.map((dateObject) => (
              <Select.Option value={dateObject.date} key={dateObject.date} title={dateObject.title}>
                {dateObject.title}
              </Select.Option>
            ))}
          </Select>
        )}
        {["week"].includes(selectedPeriod) && (
          <Select
            disabled={disabled}
            style={{ width: 140 }}
            value={getValue(selectedPeriod, "weekday")}
            onSelect={(value) => setValue({ 4: value })}
            optionLabelProp="title"
          >
            {weekOptions.map((dayObject) => (
              <Select.Option value={dayObject.day} key={dayObject.day} title={dayObject.title}>
                {dayObject.title}
              </Select.Option>
            ))}
          </Select>
        )}
        {["year"].includes(selectedPeriod) && (
          <Select
            disabled={disabled}
            style={{ width: 200 }}
            value={getValue(selectedPeriod, "month")}
            onSelect={(value) => {
              setValue({ 3: value });
            }}
          >
            {monthOptions.map((monthObject) => (
              <Select.Option
                value={monthObject.month}
                key={monthObject.month}
                title={monthObject.title}
              >
                {monthObject.title}
              </Select.Option>
            ))}
          </Select>
        )}
        {["day", "week", "month", "year"].includes(selectedPeriod) && (
          <TimePicker
            disabled={disabled}
            format={"HH:mm"}
            style={{ width: 140 }}
            value={getValue(selectedPeriod, "time")}
            onSelect={(time) => {
              setValue({ 0: time.format("m"), 1: time.format("H") });
            }}
          />
        )}
        <Button onClick={handleOnClick} disabled={localValue === value && !timer.stateBool}>
          {timer.stateBool ? (
            <CheckOutlined style={{ color: palette.green }} />
          ) : (
            <span>
              <SaveOutlined /> {!(localValue === value && !timer.stateBool) && "Save"}
            </span>
          )}
        </Button>
      </Input.Group>
    </div>
  );
};

export default CronInput;
