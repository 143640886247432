import { v4 as uuidv4 } from "uuid";

export const datePickerTypes = (version) => {
  const types = [
    {
      type: "time",
      selectValue: "time",
      title: "Time",
      version: "version1",
    },
    {
      type: "datetime",
      selectValue: "datetime",
      title: "Date and time",
      version: "version1",
    },
    {
      type: "date",
      selectValue: "date",
      title: "Date",
      version: "version1",
    },
    {
      type: "week",
      selectValue: "week",
      title: "Week",
      version: "version2",
    },
    {
      type: "month",
      selectValue: "month",
      title: "Month",
      version: "version2",
    },
    {
      type: "quarter",
      selectValue: "quarter",
      title: "Quarter",
      version: "version2",
    },
    {
      type: "year",
      selectValue: "year",
      title: "Year",
      version: "version2",
    },
  ];

  return types.filter((type) => version.includes(type.version));
};

export const getDateTimeFormat = (picker, dateFormat, timeFormat, showSeconds) => {
  const fullFormat = showSeconds ? "HH:mm:ss" : "HH:mm";
  const halfFormat = showSeconds ? "h:mm:ss a" : "h:mm a";
  const effectiveTimeFormat = timeFormat === "24H" ? fullFormat : halfFormat;

  switch (picker) {
    case "time":
      return effectiveTimeFormat;
    case "datetime":
      return `${dateFormat} ${effectiveTimeFormat}`;
    case "date":
      return `${dateFormat}`;
    default:
      //default is no change to format
      return null;
  }
};

export const shouldRenderCheck = (shouldRender, values) => {
  if (typeof shouldRender === "object") {
    const shouldRenderWithGroups = shouldRender.map((rule) => ({
      ...rule,
      conditionGroup: rule.conditionGroup || uuidv4(),
    }));
    const conditionGroupIds = getConditionGroups(shouldRenderWithGroups);
    const decision = conditionGroupIds.map((conditionGroupId) => {
      const conditionGroupArray = shouldRenderWithGroups.filter(
        (rule) => rule.conditionGroup === conditionGroupId
      );
      return checkOneConditionGroup(conditionGroupArray, values);
    });
    return !decision.some((ruleDecision) => ruleDecision === false);
  } else {
    return !!shouldRender;
  }
};

const getConditionGroups = (rules) => {
  const allGroups = rules.map((rule) => rule.conditionGroup);
  return Array.from(new Set([...allGroups]));
};

const checkOneConditionGroup = (group, values) => {
  const decision = group.map((rule) => checkOneRule(rule, values));

  return !decision.some((ruleDecision) => ruleDecision === false);
};

const checkOneRule = (rule, values) => {
  //returns the true/false of the single rule
  const { operator, compare, dependencies } = rule || {};
  switch (operator) {
    case "equal":
      return compare === values[dependencies[0]];
    case "notequal":
      return !(compare === values[dependencies[0]]);
    case "filled":
      return !!values[dependencies[0]];
    case "empty":
      return !values[dependencies[0]];
    case "inlist":
      return compare.includes(values[dependencies[0]]);
    default:
      return true;
  }
};
