import React from "react";
import { Typography, Checkbox } from "antd";
import dayjs from "dayjs";
import { LockFilled, FileExcelOutlined, CheckOutlined } from "@ant-design/icons";
import { getPrimaryPalette } from "../../utils/colorpalette";
import { queueTypes, closedQueues, allTypes } from "../../utils/queueTypes";
import TaskQueueTag from "./taskQueueTag";
const palette = getPrimaryPalette();

export const getTagObject = (row) => {
    const { taskIsLocked, healthState, dueDate, urgentDate, activationDate, queue, reason, deadlineBehavior } = row;

  let effectiveTag = null;
  if (closedQueues.includes(queue)) {
    //handling of closed queues
    if (queue === "Obsolete") {
      effectiveTag = reason;
    } else {
      effectiveTag = queue;
    }
  } else {
    //handling of active queues

    if (taskIsLocked) {
      effectiveTag = "Locked";
    } else if (queue === "NeedsApproval") {
      effectiveTag = "NeedsApproval";
    } else if (healthState === "Error") {
      effectiveTag = "Error";
    } else if (dueDate.substring(0, 4) !== "9999" && dayjs(dueDate).isBefore(dayjs())) {
        //Ser om den skal v�re overdue eller expired
        if (deadlineBehavior.toUpperCase() === "GoOverdue".toUpperCase()) {
            effectiveTag = "Overdue";
        }
        else if (deadlineBehavior.toUpperCase() === "Expire".toUpperCase()) {
            effectiveTag = "Expired";
        }
    } else if (urgentDate && dayjs(urgentDate).isBefore(dayjs())) {
      effectiveTag = "Urgent";
    } else if (activationDate && dayjs(activationDate).isAfter(dayjs())) {
      effectiveTag = "NotActive";
    } else {
      effectiveTag = queue;
    }
  }
  return allTypes.find((queues) => queues.key === effectiveTag);
};

export const getRenderCellFunc = (concatFieldType, taskId, dateFormat, timeFormat) => {
  switch (concatFieldType) {
    case "Queue":
      return (text, record) => {
        const queueName = queueTypes.find((q) => q.key === text);
        return <div>{queueName.label}</div>;
      };
    case "LongText":
      return (text, record) => (
        <div
          style={{
            minHeight: 45,
            display: "flex",
            alignItems: "center",
          }}
        >
          {text && (
            <Typography.Paragraph
              ellipsis={{ rows: 2, expandable: false }}
              style={{ margin: 0, fontSize: 12 }}
            >
              {text + ""}
            </Typography.Paragraph>
          )}
        </div>
      );
    case "Date":
      return (text, record) => {
        //console.log(text, dataIndex);
        if (text && text.length > 4 && text.substring(0, 4) === "9999") {
          return null;
        }
        const hasTime = text && text.length > 18 ? text.slice(11, 19) !== "00:00:00" : false;

        const getEffectiveFormat = () => {
          if (dateFormat) {
            if (hasTime) {
              return `${dateFormat}${timeFormat === "24H" ? " HH:mm" : " hh:mm A"}`;
            } else {
              return dateFormat;
            }
          } else {
            if (hasTime) {
              return "DD/MM/YYYY HH:mm";
            } else {
              return "DD/MM/YYYY";
            }
          }
        };
        return text ? dayjs(text).format(getEffectiveFormat()) : null;
      };
    case "Checkbox":
      return (text, record) => (
        <span className="unclickable">
          <Checkbox
            checked={text}
            className="unclickable"
            onClick={(e) => e.stopPropagation()}
            disabled
          >
            <span style={text ? { color: palette.darkGrey } : {}}>
              {text === true ? "selected" : "not selected"}
            </span>
          </Checkbox>
        </span>
      );
    case "ExcelFile":
      return (text, record) =>
        text ? (
          <span>
            <FileExcelOutlined /> <CheckOutlined style={{ marginLeft: 4, color: palette.green }} />
          </span>
        ) : (
          <span> </span>
        );
    case "JsonTable":
      return (text, record) => {
        return text ? "table" : null;
      };
    case "Id":
      return (text, record) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {record.taskIsLocked ? (
              record.queue === "Solved" ? (
                <CheckOutlined />
              ) : (
                <LockFilled />
              )
            ) : null}{" "}
            {text}
          </div>
        );
      };

    case "Status":
      return (text, record) => {
        //colors the fixed cell if task is active
        const selectedAdd = record.taskId === Number(taskId) ? { className: "rowactive" } : {};
        return {
          props: {
            ...selectedAdd,
          },
          children: (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TaskQueueTag typeObject={text} queue={record.queue} reason={record.reason} />
            </div>
          ),
        };
      };
    default:
      return (text, record) => text;
  }
};

/*

{text === "Locked" && !["Solved", "Obsolete"].includes(record.queue) && (
                <LockedTag
                  style={{ margin: "0px" }}
                  taskId={record.taskId}
                  taskLockedBy={record.taskLockedBy}
                  taskIsLocked={record.taskIsLocked}
                  taskLockedDate={record.taskLockedDate || record.modifiedDate}
                />
              )}
              {text === "Error" && (
                <ErrorTag visible={true} reason={record.reason} style={{ margin: "0px" }} />
              )}

              {["Urgent", "Overdue"].includes(text) && (
                <SlaTag
                  style={{ margin: "0px" }}
                  queue={record.queue}
                  dueDate={record.dueDate}
                  createdDate={record.createdDate}
                  urgentDate={record.urgentDate}
                  activationDate={record.activationDate}
                />
              )}

              {[...closedQueues, ...activeQueues].includes(text) && (
                <QueueTag
                  activationDate={record.activationDate}
                  activeQueue={record.queue}
                  style={{ margin: "0px" }}
                />
              )}

*/
