import React, { useRef } from "react";
import { getPrimaryPalette } from "../../../../../utils/colorpalette";
import useHover from "@react-hook/hover";
import { mergeRefs } from "../../../../../utils/reactHelpers";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Typography, Tooltip } from "antd";
import ConditionalWrapper from "../../../conditionalWrapper";
const palette = getPrimaryPalette();

function getStyle({ provided, style, isDragging, isHovering }) {
  const draggingProps = isDragging
    ? { border: "1px solid lightgrey", backgroundColor: palette.mainVeryLight }
    : {};

  const combined = {
    backgroundColor: isHovering ? palette.mainVeryLight : "#fff",
    ...draggingProps,
    ...style,
    ...provided.draggableProps.style,
  };
  return combined;
}

const RowInList = (props) => {
  const {
    provided,
    item,
    style,
    isDragging,
    dispatch,
    index,
    disabled,
    showNotes,
    highlightRule,
    highlightRender = "default",
    noHighlightRender = "select",
    width,
  } = props;
  const target = useRef(null);
  const isHovering = useHover(target);

  const cellWidthValue = showNotes ? (width - 150) / 3 : width - 150;
  const cellWidthNote = ((width - 150) / 3) * 2;

  return (
    <div
      {...provided.draggableProps}
      ref={mergeRefs(provided.innerRef, target)}
      style={getStyle({ provided, style, isDragging, isHovering })}
    >
      <div style={{ display: "flex", justifyContent: "space-between", padding: "3px 10px 0 10px" }}>
        <div style={{ display: "flex" }} {...provided.dragHandleProps}>
          <div style={{ fontSize: 10, color: palette.grey, width: 30, marginTop: 2 }}>
            {index + 1}.
          </div>
          <Typography.Text
            style={{ width: cellWidthValue }}
            editable={
              !disabled
                ? {
                    maxLength: 100,
                    autoSize: { maxRows: 1 },
                    icon: isHovering ? <EditOutlined /> : <div style={{ width: 14 }} />,
                    tooltip: "change value",
                    onChange: (value) => dispatch({ type: "CHANGE_VALUE", value, index }),
                  }
                : null
            }
          >
            {item.value}
          </Typography.Text>
          {showNotes && (
            <ConditionalWrapper
              condition={!!(isHovering && item.note)}
              wrapper={(children) => (
                <Tooltip title={item.note} mouseEnterDelay={0.6}>
                  {children}
                </Tooltip>
              )}
            >
              <Typography.Text
                style={{
                  overflow: "hidden",
                  width: cellWidthNote,
                }}
                type="secondary"
                ellipsis={true}
                editable={
                  !disabled
                    ? {
                        maxLength: 100,
                        autoSize: { maxRows: 1 },
                        icon: isHovering ? <EditOutlined /> : <div style={{ width: 14 }} />,
                        tooltip: item.note ? null : "add note",
                        onChange: (newNote) => dispatch({ type: "CHANGE_NOTE", newNote, index }),
                      }
                    : null
                }
              >
                {item.note}
              </Typography.Text>
            </ConditionalWrapper>
          )}
        </div>
        {highlightRule &&
          (item.highlighted ? (
            <div
              onClick={
                !disabled
                  ? () => dispatch({ type: "REMOVE_HIGHLIGHT_ROWS", indexes: [index] })
                  : null
              }
              className={disabled ? "" : "clickable"}
              style={{ color: palette.grey }}
            >
              {highlightRender}
            </div>
          ) : (
            <div
              onClick={
                !disabled
                  ? () =>
                      dispatch({ type: "HIGHLIGHT_ROWS", indexes: [index], rule: highlightRule })
                  : null
              }
              className={disabled ? "" : "clickable"}
              style={{ color: palette.lightGrey }}
            >
              {noHighlightRender}
            </div>
          ))}
        <div>
          {!disabled ? (
            <DeleteOutlined
              style={{ padding: "4px", margin: "0px 10px" }}
              onClick={
                disabled ? null : () => dispatch({ type: "DELETE_ROWS", row: { index, ...item } })
              }
            />
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
};

export default RowInList;
