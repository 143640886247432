import React, { useState, useContext } from "react";
import { Tooltip, Comment, Button, Input, message, Typography } from "antd";
import moment from "moment";
import UserAvatar from "../../common/typography/userAvatar";

import { activeQueues } from "./../../../utils/queueTypes";

import { UserDB } from "./../../../contexts/userDB";

const TaskCardLog = (props) => {
  const { taskLog, queue, taskId, dispatchQueue } = props;

  const { getUser, currentUser } = useContext(UserDB);

  const [comment, setComment] = useState("");

  const onCommentSuccess = () => {
    setComment("");
  };

  const onCommentError = () => {
    message.error("Comment failed");
  };

  const commentArray = taskLog
    ? taskLog.filter((action) => action.actionType === "Comment")
    : [];

  return (
    <div style={{ margin: "20px 24px 20px 20px" }}>
      {commentArray.length > 0 ? (
        <div>
          {commentArray.map((action, index) => {
            const user = getUser(action.actionUserId);

            return (
              <Comment
                key={index}
                author={user.fullName}
                avatar={<UserAvatar user={user} />}
                content={<p>{`${action.noteText}`}</p>}
                datetime={
                  <Tooltip title={moment(action.actionDateTime).format("LL")}>
                    <span>{moment(action.actionDateTime).fromNow()}</span>
                  </Tooltip>
                }
              />
            );
          })}
        </div>
      ) : (
        <div style={{ textAlign: "center", marginTop: 40 }}>
          <Typography.Text type="secondary">
            There are no comments on this task
          </Typography.Text>
        </div>
      )}
      {activeQueues.includes(queue) && (
        <div style={{ marginTop: 30 }}>
          <div style={{ display: "flex" }}>
            <div style={{ margin: "0px 12px 0 0" }}>
              <UserAvatar user={currentUser} />
            </div>
            <Input.TextArea
              value={comment}
              onChange={(e) => setComment(e.currentTarget.value)}
              rows={2}
              autoSize={{ minRows: 1, maxRows: 4 }}
            />
          </div>
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button
              loading={false}
              disabled={!comment}
              type="primary"
              style={{ margin: "4px 0 0 0" }}
              onClick={() =>
                dispatchQueue({
                  type: "CREATE_NEW_COMMENTS",
                  comment,
                  userId: currentUser.userId,
                  taskIds: [taskId],
                  onSuccess: onCommentSuccess,
                  onError: onCommentError,
                })
              }
            >
              Post Comment
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskCardLog;
