import { useCallback, useEffect } from "react";
import { useEnhancedReducer } from "./useEnhancedReducer";
import http from "../services/httpService";
import { GetAllowed, GetRolePermissions } from "./../services/userService";

const setup = {
  permissions: [],
  override: false,
  loading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_PERMISSIONS":
      return { permissions: action.permissions, loading: false, override: action.override };
    case "STOP_LOAD":
      return { ...state, loading: false };
    case "START_LOAD":
      return { ...state, loading: true };
    default:
      return state;
  }
};

export function usePermissions() {
  const asyncActionHandlers = {
    GET_ALLOWED: ({ dispatch, getState, signal }) => async (action) => {
      try {
        const answer = await http.do({ ...GetAllowed() });
        dispatch({
          type: "SET_PERMISSIONS",
          permissions: answer.data.allowedPermissions,
          loading: false,
          override: false,
        });
      } catch (err) {
        dispatch({ type: "STOP_LOAD" });
      }
    },
    SET_TEMP: ({ dispatch, getState, signal }) => async (action) => {
      try {
        dispatch({ type: "START_LOAD" });
        const answer = await http.do({ ...GetRolePermissions(action.userRole) });
        dispatch({
          type: "SET_PERMISSIONS",
          permissions: answer.data.allowedPermissions,
          loading: false,
          override: true,
        });
      } catch (err) {
        dispatch({ type: "STOP_LOAD" });
      }
    },
  };

  const [[state, dispatch], getState] = useEnhancedReducer(
    reducer,
    setup,
    undefined,
    asyncActionHandlers
  );

  const permittor = useCallback(
    (...arr) => {
      //treats arrays as "or gate" and each string as an and-gate
      //so (["1","2"], "3") means 3 and either 1 or 2 has to be true to get the permission

      let permittedArray = [];
      arr.forEach((rule, index) => {
        if (typeof rule === "string" || rule instanceof String) {
          permittedArray[index] = state.permissions.includes(rule);
        } else if (Array.isArray(rule)) {
          permittedArray[index] = rule.some((item) => {
            return state.permissions.includes(item);
          });
        }
      });

      const permitted = permittedArray.every((permission) => {
        return permission;
      });
      return permitted;
    },
    [state.permissions]
  );

  const override = useCallback((userRole) => {
    dispatch({ type: "SET_TEMP", userRole });
  }, []);

  useEffect(() => {
    dispatch({ type: "GET_ALLOWED" });
  }, []);

  return [permittor, state.loading, override, state.permissions];
}
