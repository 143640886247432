import React from "react";
import { Switch as SwitchAntd, Typography } from "antd";
const Switch = (props) => {
  const { fieldName, description, type, titleSize, ...rest } = props;

  const DescriptionWrapper = ({ children }) => {
    switch (type) {
      case "switch":
      case "titleswitch":
        return <Typography.Text type="secondary">{children}</Typography.Text>;
      case "inlineswitch":
        return <Typography.Text strong>{children}</Typography.Text>;

      default:
        return null;
    }
  };

  const marginBottomAdd = ["inlineswitch", "titleswitch"].includes(type)
    ? { marginBottom: 12 }
    : {};

  const titleswitchAdd = ["titleswitch"].includes(type) ? { justifyContent: "space-between" } : {};

  return (
    <div style={{ width: "100%" }}>
      {type === "titleswitch" && (
        <div>
          <Typography.Title level={4}>{fieldName}</Typography.Title>
        </div>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          ...marginBottomAdd,
          ...titleswitchAdd,
        }}
      >
        <DescriptionWrapper>
          {["switch", "titleswitch"].includes(type) && description}
          {type === "inlineswitch" && fieldName}
        </DescriptionWrapper>
        <div style={{ padding: "0px 6px 0px 12px" }}>
          <SwitchAntd {...rest} />
        </div>
      </div>
    </div>
  );
};

export default Switch;
