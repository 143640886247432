import React from "react";
import { Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

const InfoIcon = (props) => {
  const { description, color = null, icon = "question" } = props;

  return (
    <span style={{ marginLeft: "4px" }}>
      <Tooltip title={description}>
        <QuestionCircleOutlined style={{ color }} />
      </Tooltip>
    </span>
  );
};

export default InfoIcon;
