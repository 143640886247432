import React, { useState } from "react";

import { Input } from "antd";
import FormRowWrapper from "./../formRowWrapper";
import ParagraphInput from "../inputComponents/text/paragraphInput";
import TitleInput from "./../inputComponents/text/titleInput";
import { MailOutlined, LoadingOutlined, KeyOutlined } from "@ant-design/icons";

const Text = (props) => {
  const {
    formProps,
    onBlur,
    masterType,
    apiCheck,
    allowNulls,
    dataIndex,
    fieldName,
    autoFocus = false,
    isUnlockedForEditing = true,
    fieldMetaData,
    fieldRenderData,
  } = props;

  const { disabled, lockKeys = false } = formProps;
  const { min, max, pattern, enumCheck } = fieldMetaData || {};
  const { showAs = "input", suffix, prefix, placeholder, infoAdd, addRight, autoComplete } =
    fieldRenderData || {};

  const [validationLoading, setValidationLoading] = useState(false);

  const effectiveType = masterType || showAs || "input";

  const effectiveDisabled =
    disabled || isUnlockedForEditing === false || (effectiveType === "key" && lockKeys);

  const handleBlur = (newValue) => {
    onBlur(newValue, dataIndex);
  };

  const checkApi = () => {
    if (apiCheck) {
      return () => ({
        validator: async (rule, value) => {
          if (value) {
            setValidationLoading(true);
            const { answer, message } = await apiCheck(value);
            setValidationLoading(false);
            if (answer) {
              return Promise.resolve();
            } else {
              return Promise.reject(message || "Taken");
            }
          }
          return Promise.resolve();
        },
      });
    }
  };

  const getEnumCheck = () => {
    if (enumCheck && enumCheck.length > 1) {
      switch (enumCheck[1].toLowerCase().replace(/\s/g, "")) {
        case "notinlist":
          return () => ({
            validator(rule, value) {
              if (!enumCheck[0].includes(value && value.toLowerCase().replace(/\s/g, ""))) {
                return Promise.resolve();
              }
              return Promise.reject("Name Taken");
            },
          });
          break;
        case "inlist":
          return () => ({
            validator(rule, value) {
              if (enumCheck[0].includes(value && value.toLowerCase().replace(/\s/g, ""))) {
                return Promise.resolve();
              }
              return Promise.reject("Not on the list");
            },
          });
          break;
      }
    }
  };

  const getPattern = (requestedPattern) => {
    let patternRule = {};
    if (requestedPattern) {
      switch (requestedPattern.toLowerCase()) {
        case "email":
          patternRule.pattern = new RegExp(
            /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
          );
          patternRule.message = "This does not look like an email";
          break;
        case "nospecial":
          patternRule.pattern = new RegExp(/^[a-z0-9 ]+$/i);
          break;
        case "nospecialnowhitespace":
          patternRule.pattern = new RegExp(/^[a-z0-9]+$/i);
          break;
        case "numberandsymbols":
          patternRule.pattern = new RegExp(/^[0-9 ()+-]+$/);
          break;
        case "nowhitespace":
          patternRule.pattern = new RegExp(/^[^\s-]+$/i);
          break;
      }
    } else {
      return null;
    }
    return patternRule;
  };

  const getRules = () => {
    let effectiveRules = [{ required: !allowNulls }, { whitespace: !allowNulls }];

    if (max) effectiveRules.push({ max });
    if (min) effectiveRules.push({ min });
    if (enumCheck) effectiveRules.push(getEnumCheck());
    if (apiCheck) effectiveRules.push(checkApi());
    if (pattern) effectiveRules.push(getPattern(pattern));
    if (effectiveType === "email") effectiveRules.push(getPattern("email"));

    return effectiveRules;
  };

  const getSuffix = () => {
    if (validationLoading) {
      return <LoadingOutlined />;
    } else if (suffix) {
      return suffix;
    } else if (effectiveType.toLowerCase() === "email") {
      return <MailOutlined />;
    } else return <span />;
  };

  const getInputComponent = () => {
    switch (effectiveType.toLowerCase()) {
      case "input":
      case "key":
      case "email":
        return (
          <Input
            autoComplete={autoComplete}
            autoFocus={!effectiveDisabled && autoFocus}
            disabled={effectiveDisabled}
            onBlur={(e) => handleBlur(e.target.value)}
            placeholder={placeholder}
            suffix={getSuffix()}
            prefix={prefix}
          />
        );
      case "paragraph":
        return <ParagraphInput disabled={disabled} onBlur={handleBlur} />;
      case "title":
        return <TitleInput disabled={disabled} onBlur={handleBlur} />;
    }
  };

  return (
    <FormRowWrapper
      {...props}
      title={
        <span>
          {fieldName} {effectiveType === "key" && <KeyOutlined />}
        </span>
      }
      effectiveRules={getRules()}
    >
      {getInputComponent()}
    </FormRowWrapper>
  );
};

export default Text;
