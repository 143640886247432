import React from "react";
import { ValidateCron } from "../../../../services/processService";
import http from "../../../../services/httpService";

import FormRowWrapper from "./../formRowWrapper";
import CronInput from "./../inputComponents/cron/cron";
const CronPicker = (props) => {
  const {
    formProps,
    onBlur,
    allowNulls,
    dataIndex,
    type = "default",
    autoFocus = false,
    isUnlockedForEditing,
    fieldMetaData,
    fieldRenderData,
  } = props;

  const { disabled } = formProps;
  const {} = fieldMetaData || {};
  const {} = fieldRenderData || {};

  const effectiveRules = [
    (formProps) => ({
      validator: async (rule, value) => {
        if (!value) {
          return Promise.resolve("Field is empty (handled in other rules");
        } else {
          const answer = await http.do({
            ...ValidateCron(),
            data: { newValue: value },
          });

          if (answer && answer.data && answer.data.object && answer.data.object.cronValid) {
            return Promise.resolve();
          } else {
            return Promise.reject("Not a valid Cron");
          }
        }
      },
    }),
  ];

  const getInputComponent = (shouldRender = false) => {
    switch (type) {
      case "default":
        return (
          <CronInput
            allowNulls={allowNulls}
            autoFocus={autoFocus}
            disabled={disabled}
            onBlur={(newValue) => onBlur(newValue, dataIndex)}
          />
        );
    }
  };

  return (
    <FormRowWrapper {...props} effectiveRules={effectiveRules} overrideAllowCopy={false}>
      {getInputComponent()}
    </FormRowWrapper>
  );
};

export default CronPicker;
