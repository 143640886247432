import React, { memo } from "react";
import { Button, Form, message, Tooltip } from "antd";
import { CopyToClipboard as ReactCopyToClipboard } from "react-copy-to-clipboard";
import { CheckOutlined } from "@ant-design/icons";
import { getPrimaryPalette } from "./../../../utils/colorpalette";
import { useSuccessTimer } from "./../../../hooks/useSuccessTimer";
import { shallowCheck } from "../../../utils/reactCompares";

const palette = getPrimaryPalette();

const CopyToClipboard = (props) => {
  const {
    allowCopy,
    getText,
    dataIndex,
    hovered,
    noAnimation,
    text,
    copyMessage: copyMessageOverwrite,
  } = props;
  const copySuccess = useSuccessTimer();

  const copyMessage = () => {
    if (text) {
      const effectiveMessage = copyMessageOverwrite
        ? copyMessageOverwrite
        : `${text} is copied to clipboard`;
      message.success({
        content: effectiveMessage,
        key: text,
      });
    } else {
      message.info({
        content: `There is nothing to copy`,
        key: "nothingtocopy",
      });
    }
  };

  const handleCopy = () => {
    copyMessage();
    if (text) {
      copySuccess.toggle();
    }
  };

  const borderAdd = () => {
    if (copySuccess.state) {
      return { color: "#fff", borderColor: "#fff" };
    } else return {};
  };
  const shouldShow = (noAnimation || hovered) && text;

  if (allowCopy) {
    return (
      <Form.Item
        noStyle
        shouldUpdate={(prevValue, nextValue) => prevValue[dataIndex] !== nextValue[dataIndex]}
      >
        {() => (
          <div
            style={{
              width: shouldShow ? 100 : 0,
              transition: "width 0.2s, margin-left 0.2s",
              overflow: "hidden",
              marginLeft: shouldShow ? 4 : 0,
            }}
          >
            <ReactCopyToClipboard text={text || ""} onCopy={handleCopy} disabled={true}>
              <Tooltip title={text ? "Copy to Clipboard" : "Nothing to Copy"}>
                <Button
                  style={{
                    width: "100%",
                    backgroundColor: copySuccess.state ? palette.green : null,
                    ...borderAdd(),
                  }}
                  icon={copySuccess.state && <CheckOutlined />}
                  disabled={!text}
                >
                  Copy
                </Button>
              </Tooltip>
            </ReactCopyToClipboard>
          </div>
        )}
      </Form.Item>
    );
  } else if (!allowCopy) {
    return null;
  }
};

function areEqual(prevProps, nextProps) {
  return shallowCheck(
    ["copyMessage", "dataIndex", "allowCopy", "text", "hovered"],
    prevProps,
    nextProps
  );
}

export default memo(CopyToClipboard, areEqual);
