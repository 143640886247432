import React, { useMemo } from "react";

import { Button, Typography, notification, Space } from "antd";
import AForm from "../../common/aForm";
import { CreateSingleTask } from "../../../services/taskService";

import moment from "moment";
import { useHistory } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import HeadlineBox from "./../../common/layout/viewBoxes/headlineBox";
import { useHttp } from "./../../../hooks/useHttp";
import { getQueueLink } from "./../../../services/queueService";

const { Text } = Typography;

const NewSingleTask = (props) => {
  const { updateWhenNewTasks, fields, taskTemplate, getStateModal, dispatchModal } = props;
  const { allowOptionForInitialNote, activationDate, processKey, processId } = taskTemplate || {};
  const [{ loading: loadingCreate }, executeCreate] = useHttp(CreateSingleTask, { manual: true });

  const effectiveActivationDate = useMemo(
    () => (moment(activationDate).isAfter(moment(), "day") ? activationDate : null),
    [activationDate]
  );

  const newTaskState = getStateModal("NewTask") || {};
  const { copyOf = null, note = null } = newTaskState;
  const { push } = useHistory();

  const handleModifyTaskData = async (values, reset) => {
    //moves keyfields into its own object instead of in the customfieldsobject
    let keyFields = [];
    let fieldValueList = [];

    for (var k in values) {
      if (!values.hasOwnProperty(k)) continue;
      let foundKey = k.indexOf("key");
      if (foundKey === 0) {
        keyFields.push({ customKeyId: k.slice(3), value: values[k] });
      } else if (["noteText", "activationDate"].includes(k)) {
        //pass
      } else {
        fieldValueList.push({ customFieldId: k, value: values[k] });
      }
    }

    const task = {
      processId,
      noteText: values.noteText ? values.noteText : null,
      fieldValueList: fieldValueList,
      keyFields: keyFields,
      //sends "now" if user deselected the defualt activationdate
      activationDate: values.activationDate || moment().format(),
    };
    //these 2 are moved outside of the list
    delete task.fieldValueList.noteText;
    delete task.fieldValueList.activationDate;

    console.log(task);

    try {
      const answer = await executeCreate({ data: task });
      onNewTaskSuccess(answer);
    } catch (ex) {
      console.error(ex);
    }
  };

  const onNewTaskSuccess = (answer) => {
    switch (answer.data.response.responseCode) {
      case "ActionNotPossible":
        notification.warning({
          message: "Cannot create this task",
          description: answer.data.response.message,
        });
        break;
      case "Success":
        notification.success({
          message: "Task created succesfully",
          description: (
            <div>
              <Text>
                Reason: <Text strong>{answer.data.createdTask.reason}</Text>
              </Text>
              {answer.data && answer.data.createdTask && (
                <Button
                  style={{ margin: "20px 0px" }}
                  block
                  onClick={() => {
                    push(
                      getQueueLink(
                        processKey,
                        answer.data.createdTask.queue,
                        answer.data.createdTask.taskId
                      )
                    );
                  }}
                  type="primary"
                >
                  Show in queue <RightOutlined />
                </Button>
              )}
            </div>
          ),
        });
        updateWhenNewTasks();
        break;
    }
  };

  return (
    <AForm
      applyDefaultValues={copyOf ? false : true}
      showRequired={"optional"}
      waitForRequiredTouch={copyOf ? "never" : "all"}
      onFinish={handleModifyTaskData}
      fields={fields}
      loading={loadingCreate}
    >
      {allowOptionForInitialNote && (
        <AForm.Text
          fieldValue={note}
          nullBox={true}
          fieldRenderData={{ collapsible: true }}
          dataIndex="noteText"
          fieldName="Add comment"
          allowNulls={true}
        />
      )}
      <AForm.DatePicker
        fieldValue={effectiveActivationDate}
        fieldMetaData={{ picker: "datetime", disablePast: true, defaultShowTime: false }}
        fieldRenderData={{
          collapsible: true,
          infoAdd: "The Mate will wait untill this date to solve the task.",
        }}
        dataIndex="activationDate"
        fieldName="add activation date"
        allowNulls={true}
      />
      <AForm.ApproveButton
        fieldRenderData={{ allowClear: true }}
        fieldMetaData={{ okText: "Create", clearText: "Clear" }}
      />
    </AForm>
  );
};

export default NewSingleTask;
