import React, { useContext, useMemo } from "react";
import TaskCardLog from "./taskCardLog";
import moment from "moment";
import { Tabs, Badge, Alert, message, Col, Row } from "antd";

import TaskCardSkeleton from "./taskCardSkeleton";

import MainCard from "../../common/layout/mainCard/mainCard";
import LockedByMeTag from "../../common/tags/lockedByMeTag";
import SlaTag from "../../common/tags/slaTag";
import InfoTag from "../../common/tags/infoTag";
import ErrorTag from "../../common/tags/errorTag";
import AuditTrail from "./auditTrail";
import TaskButton from "./taskButton";
import InfoRibbon from "./infoRibbon";

import { Utils } from "../../../contexts/utils";
import { UserDB } from "./../../../contexts/userDB";
import { QueueContext } from "./../../../contexts/queueContext";

import AForm from "../../common/aForm";

import { activeQueues, closedQueues, queueTypes } from "./../../../utils/queueTypes";
import { useHistory } from "react-router-dom";

import { ProcessTeams } from "./../../../contexts/processTeams";

import { getPrimaryPalette } from "./../../../utils/colorpalette";

const palette = getPrimaryPalette();

const renderDescription = (date) => {
  const dateObject = moment(date);
  return <span style={{ color: palette.darkGrey }}>({dateObject.fromNow()})</span>;
};

const TaskCard = (props) => {
  const { onClose, drawBorder = true } = props;

  const { state: stateQueue, dispatch: dispatchQueue, refreshQueue, refreshTaskCard } = useContext(
    QueueContext
  );

  const { dispatchModal } = useContext(Utils);
  const { push } = useHistory();
  const { userLibrary, currentUser } = useContext(UserDB);
  const { getProcess } = useContext(ProcessTeams);

  const { taskCard: data, taskCardLoading } = stateQueue;

  const {
    dueDate,
    modifiedDate,
    activationDate,
    createdDate,
    approvedBy,
    approvedState,
    urgentDate,
    taskIsLockedForUserToEdit,
    taskId,
    processId,
    taskLog,
    taskLockedBy,
    reason,
    queue,
    healthState,
    taskLockedDate,
    taskHasSla,
    keyFields = [],
    customFields = [],
  } = data || {};

  const process = getProcess(processId);

  const handleModifyTaskFields = (newValues, reset, closeAfter) => {
    dispatchQueue({
      type: "MODIFY_TASKCARD_FIELDS",
      newValues,
      onChange: closeAfter ? handleClose : reset,
      onSuccess: closeAfter ? handleSuccessClose : refreshQueue,
      taskId,
    });
  };

  const handleSuccessClose = () => {
    message.success("Field updated");
    refreshQueue();
  };

  const handleClose = () => {
    push("#");
    dispatchModal({
      type: "TOGGLE_VISIBILITY",
      visible: { TaskCard: false },
    });
  };

  const taskLockedByMe =
    data && !taskIsLockedForUserToEdit && currentUser && currentUser.userId === taskLockedBy;

  const currentQueueObject = data ? queueTypes.find((queueType) => queueType.key === queue) : null;
  const isSelectedForManualControl = data ? reason === "Manual Control" : false;

  const fields = [...keyFields, ...customFields].sort((a, b) => (a.sortKey > b.sortKey ? 1 : -1));

  const getShownDates = () => {
    let dateArray = [];
    createdDate && dateArray.push("createdDate");
    activeQueues.includes(queue) && dateArray.push("activationDate");
    dueDate && dueDate.substring(0, 4) !== "9999" && dateArray.push("dueDate");
    modifiedDate && closedQueues.includes(queue) && dateArray.push("modifiedDate");
    return dateArray;
  };

  const shownDates = useMemo(getShownDates, [taskId, activationDate, dueDate, modifiedDate, queue]);

  return (
    <React.Fragment>
      {taskCardLoading === true || !data ? (
        <TaskCardSkeleton />
      ) : (
        <MainCard
          style={drawBorder ? {} : { border: "none" }}
          title={`Task: ${process && process.processName}`}
          onClose={handleClose}
          addRight={
            <InfoTag>
              <InfoTag value={`#${data && taskId}`} />
              <ErrorTag visible={healthState === "Error"} reason={reason} />
              {taskLockedByMe && <LockedByMeTag modifiedDate={taskLockedDate} />}
              {taskHasSla && (
                <SlaTag
                  empty={true}
                  queue={queue}
                  dueDate={moment(dueDate)}
                  taskHasSla={taskHasSla}
                  urgentDate={moment(urgentDate)}
                  createdDate={moment(createdDate)}
                  activationDate={moment(activationDate)}
                />
              )}
            </InfoTag>
          }
        >
          <InfoRibbon
            data={data}
            isSelectedForManualControl={isSelectedForManualControl}
            currentQueueObject={currentQueueObject}
          />

          <div style={{ padding: 20 }}>
            <TaskButton
              style={{ marginBottom: 20 }}
              process={process}
              isSelectedForManualControl={isSelectedForManualControl}
              data={data}
              dispatchQueue={dispatchQueue}
              taskLockedByAnother={taskIsLockedForUserToEdit}
              refreshQueue={refreshQueue}
              refreshTaskCard={refreshTaskCard}
            />

            {healthState === "NotClosedRight" && (
              <Alert
                style={{ margin: 20 }}
                message="Warning: This task has not been closed right. This is usually a Mate that is taking tasks and not handing them back."
                type="error"
              />
            )}
            <Tabs
              defaultActiveKey="Data"
              animated={{ inkBar: true, tabPane: false }}
              centered={true}
            >
              <Tabs.TabPane tab={"Data"} key="Data">
                {approvedState == "Approved" && (
                  <AForm disabled allowCopy={true}>
                    <AForm.User
                      dataIndex="approver"
                      fieldName="Approved by"
                      fieldValue={approvedBy}
                      fieldMetaData={{ option: userLibrary, filter: "users" }}
                    />
                  </AForm>
                )}
                <Row wrap={false} gutter={8} style={{ marginBottom: 12 }}>
                  {shownDates.includes("createdDate") && (
                    <Col flex={1}>
                      <AForm disabled allowCopy={true}>
                        <AForm.DatePicker
                          dataIndex="createdDate"
                          fieldName="Created"
                          fieldMetaData={{ picker: "datetime" }}
                          fieldValue={createdDate}
                          fieldRenderData={{
                            showAs: "infodate",
                            infoAdd: "Task was created on this date",
                          }}
                          description={renderDescription(createdDate)}
                        />
                      </AForm>
                    </Col>
                  )}
                  {shownDates.includes("activationDate") && (
                    <Col flex={1}>
                      <AForm
                        allowCopy={true}
                        onBlur={(values, dataIndexes) =>
                          dispatchQueue({
                            type: "UPDATE_ACTIVATION_DATES",
                            taskIds: [taskId],
                            newActivationDate: values.activationDate.format(),
                            onSuccess: () => {
                              refreshQueue();
                              refreshTaskCard();
                            },
                          })
                        }
                        disabled={closedQueues.includes(queue)}
                      >
                        <AForm.DatePicker
                          dataIndex="activationDate"
                          fieldName="Activation"
                          fieldMetaData={{
                            picker: "datetime",
                            defaultShowTime: true,
                            disablePast: true,
                          }}
                          fieldValue={activationDate}
                          fieldRenderData={{
                            showAs: "infodate",
                            infoAdd: "Task can be solved from this date and forward",
                          }}
                          description={renderDescription(activationDate)}
                        />
                      </AForm>
                    </Col>
                  )}
                  {shownDates.includes("dueDate") && (
                    <Col flex={1}>
                      <AForm disabled allowCopy={true}>
                        <AForm.DatePicker
                          dataIndex="dueDate"
                          fieldName="Due"
                          fieldValue={dueDate}
                          fieldMetaData={{ picker: "datetime" }}
                          fieldRenderData={{
                            showAs: "infodate",
                            infoAdd: "Task should be solved before this date",
                          }}
                          description={renderDescription(dueDate)}
                        />
                      </AForm>
                    </Col>
                  )}

                  {shownDates.includes("modifiedDate") && (
                    <Col flex={1}>
                      <AForm disabled allowCopy={true}>
                        <AForm.DatePicker
                          dataIndex="modifiedDate"
                          fieldValue={modifiedDate}
                          fieldName={queue === "Solved" ? "Solved" : "Cancelled"}
                          fieldMetaData={{ picker: "datetime" }}
                          fieldRenderData={{
                            showAs: "infodate",
                          }}
                          description={renderDescription(modifiedDate)}
                        />
                      </AForm>
                    </Col>
                  )}
                </Row>
                <AForm
                  loading={false}
                  allowCopy={true}
                  fields={fields}
                  lockKeys={true}
                  waitForRequiredTouch="one"
                  onFinish={handleModifyTaskFields}
                  disabled={
                    taskIsLockedForUserToEdit ||
                    isSelectedForManualControl ||
                    closedQueues.includes(queue)
                  }
                >
                  <AForm.ApproveButton fieldRenderData={{ allowClear: true, okAndClose: true }} />
                </AForm>
              </Tabs.TabPane>
              <Tabs.TabPane
                key="Comments"
                tab={
                  <Badge
                    style={{ backgroundColor: "lightgrey" }}
                    offset={[10, 0]}
                    count={
                      taskLog && taskLog.filter((task) => task.actionType === "Comment").length
                    }
                  >
                    Comments
                  </Badge>
                }
              >
                <TaskCardLog
                  taskLog={taskLog}
                  queue={queue}
                  dispatchQueue={dispatchQueue}
                  taskId={taskId}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <Badge
                    style={{ backgroundColor: "lightgrey" }}
                    offset={[10, 0]}
                    count={taskLog && taskLog.length}
                  >
                    History
                  </Badge>
                }
                key="Activity"
              >
                <AuditTrail taskLog={taskLog} style={{ padding: 20 }} />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </MainCard>
      )}
    </React.Fragment>
  );
};

export default TaskCard;
