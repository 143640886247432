import React, { useState, useEffect } from "react";
import { v1 as uuid } from "uuid";
import { useEnhancedReducer } from "./useEnhancedReducer";
import { notification } from "antd";
import { CreateManyTasks } from "../services/taskService";
import { convertToSendableValues } from "../components/common/aForm/index";
import { useHttp } from "./useHttp";

export function getKeyBatchSize(keyObject) {
  let effectiveBatchSize = null;
  Object.keys(keyObject).forEach((key) => {
    effectiveBatchSize = effectiveBatchSize
      ? effectiveBatchSize * keyObject[key].length
      : keyObject[key].length;
  });
  return effectiveBatchSize;
}

const concatArrays = (...arrays) => {
  return [].concat(...arrays.filter(Array.isArray));
};

const initialValueKey = {};
const initialValueData = {};
const initialValueTaskBasket = { basket: [], loading: false };

export function useTaskBasket(keyFields, inputFields, processId) {
  const [currentKeyBatchSize, setCurrentKeyBatchSize] = useState(null);

  const [{}, executeCreate] = useHttp(CreateManyTasks, { manual: true });

  const [formType, setFormType] = useState("fresh"); //can be fresh(just rebooted) new (selected) and change(batchid selected)

  const fields = concatArrays(keyFields, inputFields);

  const keyFieldKey = fields
    ? fields.map((field) => (field && field.FieldId) || (field && field.customFieldId)).join("-")
    : "noData";

  function currentDataReducer(state, action) {
    switch (action.type) {
      case "change":
        return { ...state, [action.dataIndex]: action.value };
      case "reset":
        return {};
    }
  }

  function currentKeysReducer(state, action) {
    switch (action.type) {
      case "change":
        let newKeyObject = { ...state, [action.dataIndex]: action.value };
        setCurrentKeyBatchSize(getKeyBatchSize(newKeyObject));
        return newKeyObject;
      case "reset":
        let resetValues = {};
        [...keyFields].forEach((item) => {
          resetValues[`key${item.keyId}`] = [];
        });
        setCurrentKeyBatchSize(0);
        return { ...resetValues };
    }
  }

  function taskBasketReducer(state, action) {
    switch (action.type) {
      case "addtobasket":
        return {
          basket: [
            ...state.basket,
            {
              taskCount: getKeyBatchSize(action.keyFields),
              batchId: uuid(),
              keyFields: action.keyFields,
              fieldValueList: action.fieldValueList,
            },
          ],
          loading: false,
        };
      case "removefrombasket":
        let newState = state.basket.filter((batch) => batch.batchId !== action.batchId);
        return { basket: [...newState], loading: false };

      case "reset":
        return { ...initialValueTaskBasket };
      case "START_LOAD":
        return { ...state, loading: true };
      case "FINISH_LOAD":
        return { ...state, loading: false };
      case "changebasket":
        let changedState = [...state.basket];

        let batchIndex = changedState.findIndex((obj) => obj.batchId == action.batchId);

        let changedId = changedState.find((batch) => batch.batchId === action.batchId);
        changedId = {
          taskCount: getKeyBatchSize(action.keyFields),
          batchId: action.batchId,
          keyFields: { ...action.keyFields },
          fieldValueList: { ...action.fieldValueList },
        };
        changedState[batchIndex] = changedId;
        return { basket: changedState, loading: false };
    }
  }

  const onCreateSuccess = (answer, onSuccess) => {
    if (answer.data) {
      let answerObject = {};
      const multipleResponses =
        answer.data.multipleResponses && answer.data.multipleResponses.length > 0
          ? answer.data.multipleResponses
          : null;
      answerObject.success = answer.data.successCounter;

      if (multipleResponses) {
        answerObject.duplicate = multipleResponses.reduce(
          (total, specific) =>
            specific.response.responseCode === "ActionNotPossible" &&
            specific.response.message === "Duplicate key found in active tasks"
              ? total + 1
              : total,
          0
        );
      }
      if (answerObject.success > 0) {
        notification.success({
          message: "Tasks created",
          description: (
            <div>
              success: {answerObject.success} <br /> duplicates: {answerObject.duplicate}
            </div>
          ),
        });
        typeof onSuccess === "function" && onSuccess();
      } else if (answerObject.duplicate > 0) {
        notification.warning({
          message: "No tasks created",
          description: (
            <div>
              success: {answerObject.success} <br /> duplicates: {answerObject.duplicate}
            </div>
          ),
        });
      }
    }
  };

  const asyncTaskBasketHandlers = {
    CREATE_ALL: ({ dispatch, getState, signal }) => async (action) => {
      dispatch({ type: "START_LOAD" });
      const taskArray = mapBasketToMassCreate(getState().basket);

      try {
        const answer = await executeCreate({
          data: taskArray,
          url: CreateManyTasks(processId).url,
        });
        dispatch({ type: "reset" });
        onCreateSuccess(answer, action.onSuccess);
      } catch (ex) {
        console.error(ex);
      }
    },
  };

  const [[currentData, dispatchCurrentData], getCurrentData] = useEnhancedReducer(
    currentDataReducer,
    initialValueData,
    undefined,
    {}
  );
  const [[currentKeys, dispatchCurrentKeys], getCurrentKeys] = useEnhancedReducer(
    currentKeysReducer,
    initialValueKey,
    undefined,
    {}
  );
  const [[taskBasket, dispatchTaskBasket], getTaskBasket] = useEnhancedReducer(
    taskBasketReducer,
    initialValueTaskBasket,
    undefined,
    asyncTaskBasketHandlers
  );

  useEffect(() => {
    dispatchCurrentData({ type: "reset" });
    dispatchCurrentKeys({ type: "reset" });
    dispatchTaskBasket({ type: "reset" });
    setFormType("fresh");
  }, [keyFieldKey]);

  const mapBasketToMassCreate = (basket) => {
    let sendArray = [];
    basket.forEach((batch) => {
      //moves activation and notetext one level up in json
      let noteText = batch.fieldValueList.noteText;
      let activationDate = batch.fieldValueList.activationDate
        ? batch.fieldValueList.activationDate.format()
        : null;
      delete batch.fieldValueList.noteText;
      delete batch.fieldValueList.activationDate;

      let sendableValues = convertToSendableValues({
        ...batch.fieldValueList,
      });
      let keyFieldsInArrays = [];
      let keyHeaders = [];
      let fieldValueList = [];

      for (let k in sendableValues) {
        if (!sendableValues.hasOwnProperty(k)) continue;
        fieldValueList.push({ customFieldId: Number(k), value: sendableValues[k] });
      }

      for (const property in batch.keyFields) {
        keyFieldsInArrays.push(batch.keyFields[property]);
        keyHeaders.push(property.replace("key", ""));
      }

      const f = (a, b) => [].concat(...a.map((d) => b.map((e) => [].concat(d, e))));
      const cartesian = (a, b, ...c) => (b ? cartesian(f(a, b), ...c) : a);

      let tasksKeyFieldNotation = cartesian(...keyFieldsInArrays);
      tasksKeyFieldNotation.forEach((taskKeyFields) => {
        let effectiveKeyFields = [];
        keyHeaders.forEach((keyHeader, index) => {
          if (Array.isArray(taskKeyFields)) {
            effectiveKeyFields.push({
              customKeyId: Number(keyHeader),
              value: taskKeyFields[index],
            });
          } else {
            effectiveKeyFields.push({ customKeyId: Number(keyHeader), value: taskKeyFields });
          }
        });
        let taskToPush = {
          processId,
          noteText,
          activationDate,
          keyFields: effectiveKeyFields,
          fieldValueList: fieldValueList,
        };
        sendArray.push(taskToPush);
      });
    });
    return sendArray;
  };

  return {
    getTaskBasket,
    getCurrentData,
    getCurrentKeys,
    currentKeyBatchSize,
    dispatchCurrentData,
    dispatchCurrentKeys,
    dispatchTaskBasket,
    formType,
    setFormType,
  };
}
