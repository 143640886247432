import React, { useContext, useState } from "react";
import { Tag, Popover, Button, Typography } from "antd";
import { LockFilled, CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import UserPresentationTag from "../typography/userPresentationTag";
import { getPrimaryPalette } from "../../../utils/colorpalette";
import { ForceUnlockTask } from "../../../services/taskService";

import { UserDB } from "./../../../contexts/userDB";
import { QueueContext } from "./../../../contexts/queueContext";
import { useHttp } from "./../../../hooks/useHttp";

const LockedTag = (props) => {
  const {
    taskId = null,
    taskLockedBy = null,
    style = {},
    taskLockedDate = null,
    size = "default",
    type = "tag",
  } = props;

  const { refreshQueue, refreshTaskCard } = useContext(QueueContext);
  const palette = getPrimaryPalette();
  const { getUser } = useContext(UserDB);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const [{ loading: unlockLoading }, executeUnlock] = useHttp(ForceUnlockTask, { manual: true });

  const user = taskLockedBy ? getUser(taskLockedBy) : null;

  const forceUnlock = async (e) => {
    e.stopPropagation();
    const taskIdObject = { taskIds: [taskId] };
    await executeUnlock({ data: taskIdObject });
    onUnlockSuccess();
  };

  const onUnlockSuccess = () => {
    setPopoverVisible(false);
    refreshTaskCard();
    refreshQueue();
  };

  const handlePopoverClose = (e) => {
    e.stopPropagation();
    setPopoverVisible(false);
  };
  const popover = (
    <div>
      <div style={{ marginBottom: "6px" }}>
        <Typography.Text type="secondary">Locked by </Typography.Text>{" "}
        <UserPresentationTag type="small" user={user} />
        <CloseOutlined onClick={handlePopoverClose} style={{ marginLeft: 10 }} />
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Typography.Text type="secondary">Date </Typography.Text>{" "}
        <Typography.Text>{moment(taskLockedDate).format("l")}</Typography.Text>
        <Typography.Text type="secondary">{` (${moment(
          taskLockedDate
        ).fromNow()})`}</Typography.Text>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={forceUnlock}>{unlockLoading && <LoadingOutlined />} Force unlock</Button>
      </div>
    </div>
  );

  const renderInsideTrigger = () => {
    return size === "large" ? (
      <span style={{ color: "#fff" }}>
        <LockFilled id="locktag" /> {`Locked${user ? ` by ${user.fullName}` : ""}`}
      </span>
    ) : size === "default" ? (
      <span style={{ color: "#fff" }}>
        <LockFilled id="locktag" /> {`Locked`}
      </span>
    ) : (
      <LockFilled style={{ color: "#fff" }} id="locktag" />
    );
  };

  return (
    <Popover
      visible={popoverVisible}
      onVisibleChange={setPopoverVisible}
      placement={size === "large" ? "bottom" : "bottomRight"}
      content={popover}
      arrowPointAtCenter={true}
      trigger={["click"]}
      overlayStyle={{ color: "red" }}
      onClick={(e) => e.stopPropagation()}
    >
      {type === "tag" && (
        <Tag color={palette.heavy} className="clickable" id="locktag" style={{ ...style }}>
          {renderInsideTrigger()}
        </Tag>
      )}
      {type === "ghost" && <Button ghost> {renderInsideTrigger()}</Button>}
    </Popover>
  );
};

export default LockedTag;

//      align={{ offset: [0, -5] }}
