import React, { useContext } from "react";

import { AuthContext } from "../../contexts/authContext";
import Loadscreen from "./../common/layout/defaultPages/loadscreen";

export const Callback = () => {
  const user = useContext(AuthContext);
  user.signinRedirectCallback();
  return (
    <Loadscreen loading={true} showanimation={true} description="loading" />
  );
};
