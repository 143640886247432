export function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

export function move(source, destination, droppableSource, droppableDestination) {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
}

//copy function is not really reusable yet.
export function copy(
  source,
  destination,
  droppableSource,
  droppableDestination,
  getFieldNumber,
  fieldItems
) {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const item = sourceClone[droppableSource.index];
  let itemName = typeof item === "string" ? item : "CustomKey";
  let itemObject =
    typeof item === "string"
      ? {}
      : { customFieldId: item.customKeyId, fieldName: item.customKeyName };

  destClone.splice(droppableDestination.index, 0, {
    sortKey: getFieldNumber(),
    ...fieldItems.find((obj) => {
      return obj.customFieldType === itemName;
    }),
    ...itemObject,
  });
  return destClone;
}

export function swap(source, destination, droppableSource, droppableDestination) {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [sourceRemoved] = sourceClone.splice(droppableSource.index, 1);

  const [destRemoved] = destClone.splice(droppableDestination.index, 1);

  destClone.splice(droppableDestination.index, 0, sourceRemoved);
  sourceClone.splice(droppableSource.index, 0, destRemoved);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
}
