import React, { useEffect } from "react";
import { message } from "antd";
import { v4 as uuidv4 } from "uuid";

const LoaderMessage = () => {
  //used to provide a loadermessage through the antd message component.
  useEffect(() => {
    const key = uuidv4();
    //duration set to actually stop if something breaks
    message.loading({ content: "loading", key, duration: 10 });
    return () => {
      message.destroy(key);
    };
  }, []);

  return <span />;
};

export default LoaderMessage;
