import React from "react";
import { Typography, Input } from "antd";
const ParagraphInput = (props) => {
  const {
    onChange,
    value = "",
    disabled,
    onBlur,
    placeholder,
    ...rest
  } = props;

  const handleChange = (newValue) => {
    onChange(newValue);
    typeof onBlur === "function" && onBlur(newValue);
  };
  return value ? (
    <Typography.Paragraph
      {...rest}
      style={{ width: "100%" }}
      editable={disabled ? null : { onChange: handleChange }}
      className="dont-break-out"
    >
      {value ? value : ""}
    </Typography.Paragraph>
  ) : (
    <Input
      {...rest}
      disabled={disabled}
      onBlur={(e) => handleChange(e.target.value)}
      onPressEnter={(e) => handleChange(e.target.value)}
      placeholder={placeholder}
    />
  );
};

export default ParagraphInput;
