import React, { useState, useEffect } from "react";
import { renderChild } from "./children/renderChildren";
import { Form } from "antd";
import moment from "moment";

const Text = () => null;
Text.displayName = "Text";

const NumberInput = () => null;
NumberInput.displayName = "NumberInput";

const Bool = () => null;
Bool.displayName = "Bool";

const Select = () => null;
Select.displayName = "Select";

const Password = () => null;
Password.displayName = "Password";

const Table = () => null;
Table.displayName = "Table";

const List = () => null;
List.displayName = "List";

const ApproveButton = () => null;
ApproveButton.displayName = "ApproveButton";

const FileUploader = () => null;
FileUploader.displayName = "FileUploader";

const DatePicker = () => null;
DatePicker.displayName = "DatePicker";

const RichText = () => null;
RichText.displayName = "RichText";

const Cron = () => null;
Cron.displayName = "Cron";

const Color = () => null;
Color.displayName = "Color";

const Email = () => null;
Email.displayName = "Email";

const Country = () => null;
Country.displayName = "Country";

const User = () => null;
User.displayName = "User";

const MultipleInput = () => null;
MultipleInput.displayName = "MultipleInput";

const CustomKey = () => null;
CustomKey.displayName = "CustomKey";

export const convertToSendableValues = (rawValues) => {
  let edit = {};

  Object.keys(rawValues).forEach((item) => {
    if (typeof rawValues[item] === "object" && rawValues[item]) {
      if (moment.isMoment(rawValues[item])) {
        //format to string datetime
        edit[item] = rawValues[item].format();
      }
    }
  });
  const effectiveValues = { ...rawValues, ...edit };
  return effectiveValues;
};

//START

const AForm = (props) => {
  const {
    name,
    margin = "0px",
    customFormHook,
    updateKey,
    validateKey,
    initialValues: initialValuesParent = null,
    applyDefaultValues = false,
    fields,
    onFinish,
    onBlur = false,
    onValuesChange,
    onMetaChange = () => null,
    onClose,
    style = {},
    children,
    disabled = false,
    loading,
    allowCopy = false,
    waitForRequiredTouch = "one",
    lockKeys = false,
    showRequired = "optional",
    resetOnFieldsChange = true,
  } = props;

  const [standardFormHook] = Form.useForm();

  const form = customFormHook ? customFormHook : standardFormHook;

  const [closeAfter, setCloseAfter] = useState(false);

  const onFinishFailed = (failProps) => {
    const { errorFields } = failProps;
    form.scrollToField(errorFields[0].name);
  };

  const handleSubmit = (closeAfter = false, onSuccess) => {
    setCloseAfter(closeAfter);
    form.submit();
  };

  const reset = () => {
    form.resetFields();
  };

  //resets if the fields change
  const combinedFieldsKey = fields
    ? fields.map((field) => field.fieldId || field.customFieldId).join("-")
    : "noData";

  useEffect(() => {
    reset();
  }, [resetOnFieldsChange && combinedFieldsKey, updateKey]);

  useEffect(() => {
    if (validateKey) {
      form.validateFields();
    }
  }, [validateKey]);

  const handleMetaField = (dataIndex, newMetaField) => {
    onMetaChange(dataIndex, newMetaField);
  };

  const handleOnBlur = (value, dataIndex) => {
    const fieldArray = typeof dataIndex === "object" ? dataIndex : [dataIndex];
    if (onBlur) {
      form
        .validateFields(fieldArray)
        .then((values) => {
          let newValues = {};
          fieldArray.forEach((element) => {
            newValues[element] = values[element];
          });

          onBlur(newValues, fieldArray);
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    }
  };

  const handleFinish = (values) => {
    const effectiveValues = convertToSendableValues({ ...values });

    const onFinishSuccess = () => reset();

    if (typeof onClose === "function" && closeAfter) {
      onClose();
    }
    onFinish(effectiveValues, onFinishSuccess, closeAfter);
  };

  const mapFields = () => {
    if (!fields) {
      return [];
    }
    const oldFields = [...fields];

    let newFields = oldFields.map((field) => {
      const { fieldType, isUnlockedForEditing, description, customFieldType } = field;
      const customFieldId = field.customFieldId || `${fieldType}${field.fieldId}`;

      if (fieldType === "CustomKey" || field.keyId) {
        let effectiveKey = field.keyId || field.fieldId;
        return {
          ...field,
          allowNulls: false,
          dataIndex: "key" + effectiveKey,
          displayName: "CustomKey",
          isUnlockedForEditing: isUnlockedForEditing ? isUnlockedForEditing : true,
        };
      } else if (fieldType === "CustomField") {
        const fieldMetaData = field.customFieldMetaData || field.fieldMetaData;
        let add = { fieldMetaData, description };

        if (customFieldType === "ExcelFile") {
          add.displayName = "FileUploader";
          add.type = "ExcelBase64";
        } else if (customFieldType === "Date") {
          add.fieldValue = field.fieldValue ? moment(field.fieldValue) : null;
        }

        return {
          ...field,
          dataIndex: customFieldId,
          displayName: customFieldType,
          ...add,
        };
      } else return field;
    });

    return newFields;
  };

  const mapTouch = () => {
    //makes a list of fields that needs to be touched before submit becomes available
    const oldFields = fields ? [...fields] : [];
    let touchBeforeOk = [];

    //fields
    oldFields.map((field) => {
      const { fieldType } = field;
      if (fieldType === "CustomKey") {
        !lockKeys && touchBeforeOk.push("key" + field.keyId);
      }
      //dont add if optional or already default
      if (
        !field.allowNulls &&
        fieldType === "CustomField" &&
        !(field.defaultValue && applyDefaultValues)
      ) {
        touchBeforeOk.push(field.customFieldId);
      }
    });

    //passed fields
    React.Children.map(children, (child) => {
      if (child && child.props && !child.props.allowNulls && child.props.dataIndex) {
        touchBeforeOk.push(child.props && child.props.dataIndex);
      }
    });
    return touchBeforeOk;
  };

  const newFields = mapFields();
  const touchBeforeOk = mapTouch();

  const sharedProps = {
    formProps: {
      lockKeys,
      margin,
      updateKey,
      showRequired,
      disabled,
      loading,
      allowCopy,
      form,
      touchBeforeOk,
      waitForRequiredTouch,
      applyDefaultValues,
    },
    onBlur: handleOnBlur,
    handleSubmit,
    handleMetaField,
  };

  return (
    <div style={{ ...style }}>
      <Form
        name={name}
        form={form}
        requiredMark={showRequired}
        layout="vertical"
        initialValues={initialValuesParent ? { ...initialValuesParent } : null}
        onFinish={handleFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={typeof onValuesChange === "function" ? onValuesChange : null}
      >
        {
          //render from passed fields
          newFields &&
            newFields.map((field) => {
              const inputProps = {
                ...sharedProps,
                key: field.dataIndex,
                ...field,
              };
              return renderChild(field.displayName, inputProps);
            })
        }

        {
          //render from pass children
          React.Children.map(children, (child) => {
            if (!child) {
              return null;
            }

            const inputProps = {
              ...sharedProps,
              key: child.props.dataIndex,
              ...child.props,
              fieldType: child.type && child.type.displayName,
            };
            if (child.type && child.type.displayName) {
              return renderChild(child.type.displayName, inputProps);
            } else {
              return child;
            }
          })
        }
      </Form>
    </div>
  );
};

AForm.Text = Text;
AForm.NumberInput = NumberInput;
AForm.Select = Select;
AForm.Bool = Bool;
AForm.Table = Table;
AForm.List = List;
AForm.Password = Password;
AForm.ApproveButton = ApproveButton;
AForm.FileUploader = FileUploader;
AForm.DatePicker = DatePicker;
AForm.RichText = RichText;
AForm.Cron = Cron;
AForm.Color = Color;
AForm.Email = Email;
AForm.Country = Country;
AForm.User = User;
AForm.MultipleInput = MultipleInput;
AForm.CustomKey = CustomKey;

export default AForm;
