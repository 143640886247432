import config from "./config.json";

export function GetProcessCustomKeys() {
  return {
    url: `${config.apiUrl}/ProcessCustomKey/GetProcessCustomKeys`,
    method: "GET",
  };
}

export function CreateProcessCustomKey() {
  return {
    url: `${config.apiUrl}/ProcessCustomKey/CreateProcessCustomKey`,
    method: "POST",
  };
}

export function ChangeKeyActiveStatus() {
  return {
    url: `${config.apiUrl}/ProcessCustomKey/ChangeKeyActiveStatus`,
    method: "POST",
  };
}

export function ChangeKeyDescription() {
  return {
    url: `${config.apiUrl}/ProcessCustomKey/ChangeKeyDescription`,
    method: "POST",
  };
}

export function DeleteKey() {
  return {
    url: `${config.apiUrl}/ProcessCustomKey/DeleteKey`,
    method: "DELETE",
  };
}
