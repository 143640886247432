import { useCallback, useEffect } from "react";
import { useHttp } from "./useHttp";

import { GetMyLists, GetProcessSelector } from "../services/processService";
import { GetAllDepartmentsForCustomer } from "../services/adminService";

const myListsInitialValue = {
  myProcesses: [],
  myProcessGroups: [],
  myDepartments: [],
};

export function useProcessTeams(isAdmin) {
  const [{ data: myLists = myListsInitialValue, loadingLists, errorLists }, refetchLists] = useHttp(
    GetMyLists
  );

  const [{ data: processSelector = { all: [], withTasks: [] } }, refetchSelector] = useHttp(
    GetProcessSelector
  );

  const [
    { data: allDepartments = [] },
    executeAllDepartments,
  ] = useHttp(GetAllDepartmentsForCustomer, { manual: true });

  useEffect(() => {
    if (isAdmin) {
      executeAllDepartments();
    }
  }, [isAdmin]);

  const getProcess = useCallback(
    (identifier, condition) => {
      const process = myLists.myProcesses.find((process) => {
        if (!identifier) {
          return null;
        } else if (!condition || condition === "processId") {
          return process.processId === identifier;
        } else if (condition === "processKey") {
          return process[condition].toLowerCase() === identifier.toLowerCase();
        } else {
          return process[condition].toLowerCase() === identifier.toLowerCase();
        }
      });

      return process !== undefined ? process : null;
    },
    [myLists.myProcesses]
  );

  const getProcessGroup = useCallback(
    (processGroupId) => {
      const group = myLists.myProcessGroups.find(
        (group) => group.processGroupId === processGroupId
      );
      return group !== undefined ? group : null;
    },
    [myLists.myProcessGroups]
  );

  const getDepartmentAdmin = useCallback(
    (identifier, condition) => {
      const department = allDepartments.find((dep) =>
        !condition
          ? dep.departmentId === identifier
          : dep[condition].toLowerCase() === identifier.toLowerCase()
      );
      return department !== undefined ? department : null;
    },
    [allDepartments]
  );

  const getDepartment = useCallback(
    (identifier, condition) => {
      const department = myLists.myDepartments.find((dep) =>
        !condition ? dep.departmentId === identifier : dep.departmentKey === identifier
      );
      return department !== undefined ? department : null;
    },
    [myLists.myDepartments]
  );

  const handleRefetch = () => {
    refetchLists();
    if (isAdmin) {
      executeAllDepartments();
    }
  };

  return {
    myLists,
    processSelector,
    allDepartments,
    getProcess,
    getDepartment,
    getProcessGroup,
    getDepartmentAdmin,
    refetchLists: handleRefetch,
    refetchSelector,
    executeAllDepartments,
  };
}
