import React, { useContext } from "react";

import { writeStorage } from "@rehooks/local-storage";
import { Menu, Dropdown, Avatar, Typography } from "antd";
import { UserOutlined, ReadOutlined, LogoutOutlined, FundOutlined } from "@ant-design/icons";

import { Utils } from "../../contexts/utils";
import { UserDB } from "./../../contexts/userDB";

import { AuthContext } from "./../../contexts/authContext";
import { FaPlaneDeparture } from "react-icons/fa";
import UserPresentationTag from "../common/typography/userPresentationTag";

const ProfileMenu = (props) => {
  const { dispatchModal, tour } = useContext(Utils);
  const { getCurrentUser } = useContext(UserDB);

  const authShow = useContext(AuthContext);

  const { avatarBackgroundColor, userInitials } = getCurrentUser() || {};

  return (
    <Dropdown
      placement="bottomRight"
      trigger={["click"]}
      overlay={
        <Menu>
          <UserPresentationTag user={getCurrentUser()} style={{ margin: "4px 26px" }} />
          <Menu.Divider />
          <Menu.Item
            icon={<UserOutlined />}
            onClick={() =>
              dispatchModal({
                type: "TOGGLE_VISIBILITY",
                visible: { Profile: true },
              })
            }
            key="1"
          >
            My Profile
          </Menu.Item>
          <Menu.Item
            icon={<FaPlaneDeparture style={{ marginRight: 4 }} />}
            onClick={() => tour.dispatch({ type: "start", tour: "main" })}
          >
            10-Second-Tour: Anymate
          </Menu.Item>

          <Menu.Item icon={<ReadOutlined />}>
            <a href="https://docs.anymate.io" target="_blank" rel="noopener noreferrer">
              Help Center
            </a>
          </Menu.Item>
          <Menu.Item
            icon={<FundOutlined />}
            onClick={() => {
              writeStorage("frozenModalAccepted", null);
              dispatchModal({ type: "TOGGLE_VISIBILITY", visible: { Intro: true } });
            }}
          >
            Get started
          </Menu.Item>
          <Menu.Item onClick={() => authShow.logout()} icon={<LogoutOutlined />}>
            <Typography.Text>Log Out</Typography.Text>
          </Menu.Item>
        </Menu>
      }
    >
      <span maintour="profile-menu">
        <Avatar
          style={{
            backgroundColor: avatarBackgroundColor,
            verticalAlign: "middle",
          }}
          size="default"
          className="clickable"
        >
          {userInitials || "user"}
        </Avatar>
      </span>
    </Dropdown>
  );
};

export default ProfileMenu;
