import React, { useContext } from "react";
import { Button, Menu, Dropdown } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Utils } from "./../../contexts/utils";

const AddNew = (props) => {
  const { permittor } = props;
  const { dispatchModal } = useContext(Utils);

  const toggle = (id) => {
    dispatchModal({ type: "TOGGLE_VISIBILITY", visible: { [id]: true } });
  };

  return (
    <Dropdown
      placement="bottomRight"
      trigger={["click"]}
      overlay={
        <Menu>
          {permittor("CreateTasks") && (
            <Menu.Item
              key="NewTask"
              onClick={() => {
                toggle("NewTask");
              }}
            >
              New Task
            </Menu.Item>
          )}
          <Menu.Divider />
          {permittor(["ProcessCreateAndDelete"]) && (
            <Menu.Item
              key="NewProcess"
              onClick={() => {
                toggle("NewProcess");
              }}
            >
              New Process
            </Menu.Item>
          )}
          {permittor("OrganisationAdmin") && (
            <Menu.Item
              key="NewUser"
              onClick={() => {
                toggle("NewUser");
              }}
            >
              New User
            </Menu.Item>
          )}
          {permittor("ProcessCreateAndDelete") && (
            <Menu.Item
              key="NewGroup"
              onClick={() => {
                toggle("NewGroup");
              }}
            >
              New Group
            </Menu.Item>
          )}
          {permittor("OrganisationAdmin") && (
            <Menu.Item
              key="NewDepartment"
              onClick={() => {
                toggle("NewDepartment");
              }}
            >
              New Team
            </Menu.Item>
          )}
        </Menu>
      }
    >
      <div>
        <Button shape="circle" icon={<PlusOutlined />} />
      </div>
    </Dropdown>
  );
};

export default AddNew;
