import React, { useState, useEffect } from "react";
import { Popover, Typography, Input } from "antd";
import { EditOutlined, EnterOutlined } from "@ant-design/icons";

const NoteChanger = (props) => {
  const {
    onChange,
    children,
    type = "normal",
    disabled = false,
    showEdits = false,
    title = "Note",
  } = props;
  const { Paragraph, Text } = Typography;
  const stringChild = typeof children === "number" ? children + "" : children;
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [newNoteText, setNewNoteText] = useState(stringChild ? stringChild : null);

  useEffect(() => {
    if (!popoverVisible && newNoteText !== null && newNoteText !== stringChild) {
      onChange(newNoteText);
    }
  }, [popoverVisible]);

  const popup = (
    <div>
      <Text type="secondary">{title}</Text>
      <Input
        autoFocus={true}
        onChange={(e) => setNewNoteText(e.target.value)}
        value={newNoteText}
        onPressEnter={() => setPopoverVisible(false)}
        suffix={<EnterOutlined onClick={() => setPopoverVisible(false)} />}
      />
    </div>
  );
  return (
    <div
      style={{ display: "flex" }}
      onMouseEnter={() => setEditVisible(true)}
      onMouseLeave={() => setEditVisible(false)}
    >
      <div style={{ overflow: "hidden" }}>
        <Paragraph type={type === "secondary" ? type : null} style={{ margin: 0 }}>
          {stringChild}
        </Paragraph>
      </div>
      {!disabled && (editVisible || showEdits || popoverVisible) ? (
        <div className={"clickable"}>
          <Popover
            destroyTooltipOnHide={true}
            overlayStyle={{ position: "fixed" }}
            placement="bottom"
            content={popup}
            onVisibleChange={(e) => setPopoverVisible(e)}
            trigger={["click"]}
            visible={popoverVisible}
          >
            <span>
              <EditOutlined style={{ marginLeft: 4 }} />
            </span>
          </Popover>
        </div>
      ) : (
        <div style={{ marginLeft: 4, width: 14 }} />
      )}
    </div>
  );
};

export default NoteChanger;
