import React from "react";
import { Route, Switch } from "react-router-dom";
import { useFeature } from "feature-toggle-jsx";

import { Callback } from "./callback";
import { Logout } from "./logout";
import { LogoutCallback } from "./logoutCallback";
import { PrivateRoute } from "./privateRoute";
import { SilentRenew } from "./silentRenew";

import PublicPageMock from "../public/publicPageMock";
import App from "../../App";

export const Routes = () => {
  const [_, configPublic] = useFeature("publicPage");

  return (
    <Switch>
      <Route exact={true} path="/callback" component={Callback} />
      <Route exact={true} path="/logout" component={Logout} />
      <Route exact={true} path="/logout/callback" component={LogoutCallback} />
      <Route exact={true} path="/silentcallback" component={SilentRenew} />
      {configPublic.isEnabled && (
        <Route path="/public" component={PublicPageMock} />
      )}
      <PrivateRoute path="/" component={App} />
    </Switch>
  );
};
