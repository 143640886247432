const onPremisesMode = process.env.REACT_APP_ONPREMISES_MODE
  ? process.env.REACT_APP_ONPREMISES_MODE.toLowerCase()
  : false;
const protocol = window.location.protocol;
const hostname = window.location.hostname;
var full = window.location.host;
//window.location.host is subdomain.domain.com
var parts = full.split(".");
var sub = parts[0];
var tld = parts[parts.length - 1];
const environment = process.env.NODE_ENV;

function getCallback() {
  if (onPremisesMode === "true") return `${process.env.REACT_APP_CLIENTURL}/callback`;

  return `https://${hostname}/callback`;
}

function getSilentRedirect() {
  if (onPremisesMode === "true") return `${process.env.REACT_APP_CLIENTURL}/silentcallback`;

  return `https://${hostname}/silentcallback`;
}

function getAuthority() {
  if (onPremisesMode === "true") {
    let authUrl = `${process.env.REACT_APP_AUTHORITY}`;
    authUrl = authUrl.endsWith("/") ? authUrl.substring(0, authUrl.length - 1) : authUrl;
    return authUrl;
  }
  return `https://${sub}.auth.anymate.${tld}`;
}

function getIssuer() {
  if (onPremisesMode === "true") return `${process.env.REACT_APP_ISSUER}`;

  return `https://identity.auth.anymate.${tld}`;
}

function getPostLogoutRedirectUri() {
  if (onPremisesMode === "true") return `${process.env.REACT_APP_CLIENTURL}/`;

  return `https://${hostname}/`;
}
const issuer = getIssuer();
const authority = getAuthority();

export const IDENTITY_CONFIG = {
  authority: authority,
  client_id: "js",
  redirect_uri: getCallback(),
  silent_redirect_uri: getSilentRedirect(),
  post_logout_redirect_uri: getPostLogoutRedirectUri(),
  response_type: "code",
  automaticSilentRenew: false, //refresher 180 sec før.. se accessTokenExpiringNotificationTime
  //includeIdTokenInSilentRenew: false, // dunno..

  loadUserInfo: false,
  filterProtocolClaims: true, // ?? dunno hvad den her gør - prøver den men den kan nok bare fjernes

  scope: "clientmate",
  //scope: "openid profile clientmate", //hvis silentrefresh begynder at faile så switch tilbage til den her

  clockSkew: 180,
  accessTokenExpiringNotificationTime: 180,
  silentRequestTimeout: 10000,
  revokeAccessTokenOnSignout: true,
  webAuthResponseType: "id_token token",

  //, monitorSession: false //https://stackoverflow.com/questions/61817737/silent-renew-infinite-looping-in-vue-application
};

export const METADATA_OIDC = {
  issuer: issuer,
  jwks_uri: authority + "/.well-known/openid-configuration/jwks",
  authorization_endpoint: authority + "/connect/authorize",
  token_endpoint: authority + "/connect/token",
  userinfo_endpoint: authority + "/connect/userinfo",
  end_session_endpoint: authority + "/connect/endsession",
  check_session_iframe: authority + "/connect/checksession",
  revocation_endpoint: authority + "/connect/revocation",
  introspection_endpoint: authority + "/connect/introspect",
};
