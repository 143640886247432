import React from "react";
import { Checkbox, Typography } from "antd";
import FormRowWrapper from "./../formRowWrapper";
import Switch from "../inputComponents/bool/switch";

const Bool = (props) => {
  const {
    formProps,
    onBlur,
    masterType,
    dataIndex,
    description,
    fieldName,
    autoFocus = false,
    isUnlockedForEditing = true,
    fieldMetaData,
    fieldRenderData,
  } = props;

  const { disabled, form } = formProps;
  const { showAs, title: titleSize } = fieldRenderData || {};
  const { toggleOtherField } = fieldMetaData || {};

  const handleBlur = (newValue) => {
    if (toggleOtherField) {
      const newSideValue = newValue ? toggleOtherField.trueValue : toggleOtherField.falseValue;
      form.setFieldsValue({
        [toggleOtherField.fieldName]: newSideValue,
      });
      onBlur({ [toggleOtherField.fieldName]: newSideValue, [dataIndex]: newValue }, [
        toggleOtherField.fieldName,
        [dataIndex],
      ]);
    } else {
      onBlur({ [dataIndex]: newValue }, [dataIndex]);
    }
  };

  let effectiveType = (showAs ? showAs : masterType ? masterType : "switch").toLowerCase();

  if (effectiveType === "checkbox" && !description) {
    effectiveType = "inlinecheckbox";
  }
  if (effectiveType === "switch" && !description) {
    effectiveType = "inlineswitch";
  }

  const getInputComponent = () => {
    switch (effectiveType.toLowerCase()) {
      case "switch":
      case "inlineswitch":
      case "titleswitch":
        return (
          <Switch
            fieldName={fieldName}
            disabled={disabled || !isUnlockedForEditing}
            onChange={handleBlur}
            description={description}
            type={effectiveType}
            titleSize={titleSize}
          />
        );

      case "checkbox":
        return (
          <Checkbox
            disabled={disabled || !isUnlockedForEditing}
            onChange={(e) => handleBlur(e.target.checked)}
          >
            {description}
          </Checkbox>
        );

      case "inlinecheckbox":
        return (
          <Checkbox
            disabled={disabled || !isUnlockedForEditing}
            onChange={(e) => handleBlur(e.target.checked)}
            style={{ marginBottom: 12 }}
          >
            <Typography.Text
              style={{
                fontWeight: "bold",
              }}
            >
              {fieldName}
            </Typography.Text>
          </Checkbox>
        );
    }
  };

  const specificTypePropAdds = () => {
    let shared = { valuePropName: "checked", validateTrigger: "onChange" };
    switch (effectiveType.toLowerCase()) {
      case "checkbox":
      case "switch":
        return shared;
      case "titleswitch":
      case "inlineswitch":
      case "inlinecheckbox":
      case "titleswitch":
        return { ...shared, noStyle: true };
    }
  };

  let effectiveProps = { ...props };
  delete effectiveProps.description;

  return (
    <FormRowWrapper
      {...effectiveProps}
      effectiveRules={[]}
      {...specificTypePropAdds()}
      overrideAllowCopy={false}
    >
      {getInputComponent()}
    </FormRowWrapper>
  );
};

export default Bool;
