import React, { useState } from "react";
import MainCardHeader from "./mainCardHeader";
import { Spin, Typography } from "antd";
import { getPrimaryPalette } from "./../../../../utils/colorpalette";
import ConditionalWrapper from "./../../conditionalWrapper";
const palette = getPrimaryPalette();

const MainCard = (props) => {
  const {
    disabled = false,
    disabledText,
    id,
    appendix = null,
    underline = true,
    icon = null,
    title = null,
    description = null,
    minimizable = false,
    headerColor = "inherit",
    contentStyle = {},
    style = {},
    addLeft = null,
    addRight = null,
    onClose = null,
    children = null,
    buttonMenu = null,
    buttonMenuIconAdd,
    spinning,
    iconLeft,
    ...rest
  } = props;

  const [isMinimized, setIsMinimized] = useState(false);

  const effectiveSpin = spinning === undefined ? null : !!spinning ? true : false;

  const borderOptions = {
    border: "1px solid lightgrey",
    borderRadius: "4px",
  };

  const iconLeftWrapper = (children) => (
    <div style={{ display: "flex" }}>
      <div
        style={{
          width: 60,
          color: palette.darkGrey,
          fontSize: 30,
          padding: "24px 0px 0px 24px",
          textAlign: "center",
        }}
      >
        {iconLeft}
      </div>
      <div style={{ width: "100%" }}>{children}</div>
    </div>
  );

  return (
    <Spin spinning={effectiveSpin}>
      <div
        id={id}
        style={{
          ...borderOptions,
          position: "relative",
          backgroundColor: "#fff",
          display: appendix ? "flex" : "block",
          ...style,
        }}
        {...rest}
      >
        <ConditionalWrapper condition={!!iconLeft} wrapper={iconLeftWrapper}>
          <MainCardHeader
            underline={underline}
            minimizable={minimizable}
            setIsMinimized={setIsMinimized}
            isMinimized={isMinimized}
            title={title}
            description={description}
            icon={icon}
            onClose={onClose}
            buttonMenu={buttonMenu}
            buttonMenuIconAdd={buttonMenuIconAdd}
            addLeft={addLeft}
            addRight={addRight}
            headerColor={headerColor}
          />
          {!isMinimized && <div style={{ ...contentStyle }}>{children}</div>}
        </ConditionalWrapper>
      </div>
      {appendix && <div style={{ borderLeft: "1px solid lightgrey" }}>{appendix}</div>}
      {disabled && (
        <div
          style={{
            ...borderOptions,
            cursor: "no-drop",
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            opacity: 0.9,
            zIndex: 12,
            backgroundColor: palette.bgLight,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              borderRadius: "4px",
              border: `1px dashed ${palette.main}`,
              padding: 12,
            }}
          >
            {disabledText && <Typography.Text strong>{disabledText}</Typography.Text>}
          </div>
        </div>
      )}
    </Spin>
  );
};

export default MainCard;
