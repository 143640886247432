import { loadStripe } from "@stripe/stripe-js";

export const frozen = ["unpaid", "canceled"];
export const trial = ["trialing", "incomplete", "incomplete_expired"];
export const normal = ["active", "past_due"];
export const alert = ["past_due", "incomplete", "incomplete_expired"];
export const newSub = ["trialing", "unpaid", "canceled"];
export const changeSub = ["active", "incomplete", "incomplete_expired", "past_due"];

export const billingOptions = [
  {
    selectValue: "Month",
    title: "Monthly",
    description: "Billed monthly from the start of each month",
  },
  {
    selectValue: "Year",
    title: "Annually",
    description: "Billed yearly from the start of each year",
  },
];

export const getStripePromise = () => {
  const full = window.location.host;
  const parts = full.split(".");
  const tld = parts[parts.length - 1];

  const stripeKey =
    tld === "app"
      ? "pk_live_0pWqbTarO2VYt9CGzwM6EMLw00B2hoKgGe"
      : "pk_test_Av2WGr0mFMSPWObgykZgVbwE00GYYC5xDr";
  const stripePromise = loadStripe(stripeKey);

  return stripePromise;
};
