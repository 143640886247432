import React from "react";
import SelectUser from "../inputComponents/select/selectUser";
import FormRowWrapper from "./../formRowWrapper";

const UserPicker = (props) => {
  const {
    formProps,
    onBlur,
    masterType,
    allowNulls,
    dataIndex,
    type,
    autoFocus = false,
    isUnlockedForEditing,
    fieldMetaData,
    fieldRenderData,
  } = props;

  const { disabled } = formProps;
  const { collapsible, placeholder, infoAdd, addRight } = fieldRenderData || {};
  const { options = [], filter = "all" } = fieldMetaData || {};
  let effectiveType = type ? type : masterType ? masterType : "small";

  const filterFunction = (option) => {
    if (filter.toLowerCase() === "all") {
      return option.userType !== "System";
    } else if (filter.toLowerCase() === "users") {
      return option.userType === "Employee";
    } else if (filter.toLowerCase() === "mates") {
      return option.userType === "Bot";
    } else {
      return false;
    }
  };

  const effectiveOptions = options.filter(filterFunction);

  const getInputComponent = () => {
    switch (effectiveType.toLowerCase()) {
      case "small":
        return (
          <SelectUser
            autoFocus={autoFocus}
            disabled={disabled}
            onBlur={(e) => onBlur(e, dataIndex)}
            placeholder={placeholder}
            options={effectiveOptions}
            allowClear={allowNulls}
          />
        );
    }
  };

  return (
    <FormRowWrapper {...props} effectiveRules={[{ required: !allowNulls }]}>
      {getInputComponent()}
    </FormRowWrapper>
  );
};

export default UserPicker;
