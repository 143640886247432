import { useState, useEffect } from "react";

export function useSuccessTimer(
  customTimer = 1500,
  stateOn = true,
  stateOff = false,
  onSuccess = null
) {
  const [isActive, setIsActive] = useState(false);
  const [toggleFlag, setToggleFlag] = useState(false);
  const [effectiveStateOn, setEffectiveStateOn] = useState(stateOn);

  useEffect(() => {
    if (toggleFlag) {
      setIsActive(true);

      const timer1 = setTimeout(() => {
        setToggleFlag(false);
        setIsActive(false);
        if (typeof onSuccess === "function") {
          onSuccess();
        }
      }, customTimer);

      return () => {
        clearTimeout(timer1);
      };
    }
  }, [toggleFlag]);

  const toggle = (newStateOn) => {
    setToggleFlag(true);
    if (newStateOn) {
      setEffectiveStateOn(newStateOn);
    }
  };

  const state = isActive ? effectiveStateOn : stateOff;
  const stateBool = isActive;

  return { toggle, state, stateBool };
}
