import React from "react";
import { Typography } from "antd";

const Headline = props => {
  const {
    title = null,
    subtitle = "",
    width = null,
    style = {},
    className = "",
    onClick = null,
    clickable = false
  } = props;

  const styleProp = { width: width, ...style };
  const headClass = `${className} ${clickable ? " clickable" : ""}`;
  return (
    <div className={headClass} onClick={onClick} style={{ display: "flex", flexDirection: "column" }}>
      <Typography.Text ellipsis={true} style={{ fontSize: "18px", ...styleProp }}>
        {title}
      </Typography.Text>
      {subtitle && (
        <Typography.Text ellipsis={true} type="secondary" style={{ ...styleProp }}>
          {subtitle}
        </Typography.Text>
      )}
    </div>
  );
};

export default Headline;
