import { generate, grey, cyan } from "@ant-design/colors";

const primaryPalette = {
  main: "#5e72ca",
  green: "#4dd169",
  yellow: "#f7bf3d",
  orange: "#f78e3d",
  red: "#f46e65",
  heavy: "#7f95a8",
  purple: "#7E66C4",
  white: "#fff",
  //
  //
  mainLight: "#d5daf0",
  mainVeryLight: "#fafbff",
  greenLight: "#def6e3",
  lightGrey: "lightgrey",
  grey: "grey",
  darkGrey: "#444",
  redPale: "#FCD7D5",
  redPaleGrey: "#FDF0EF",
  heavyFont: "#647784",
  bg: "#eff1f4",
  bgLight: "#FAFBFC",
};

const rangePalette = {
  main: generate(primaryPalette.main),
  green: generate(primaryPalette.green),
  yellow: generate(primaryPalette.yellow),
  orange: generate(primaryPalette.orange),
  red: generate(primaryPalette.red),
  heavy: generate(primaryPalette.heavy),
  purple: generate(primaryPalette.purple),
  grey: grey,
};

export const colorRange = (primary, options) => {
  return generate(primary, options);
};

export function getPrimaryPalette() {
  return primaryPalette;
}
export function getRangePalette() {
  return rangePalette;
}

export function graphColor(setting, overrideMono) {
  switch (setting) {
    case "monochromatic":
      return overrideMono || cyan;
    case "visibility":
      return { scheme: "category10" };
  }
}
