import React from "react";
import { Typography, Input } from "antd";
import { EditOutlined } from "@ant-design/icons";

const TitleInput = (props) => {
  const { onChange, value, disabled, onBlur } = props;

  const handleChange = (newValue) => {
    onChange(newValue);
    onBlur(newValue);
  };

  return value ? (
    <Typography.Title
      style={{ width: "100%", margin: 0 }}
      level={4}
      editable={
        disabled
          ? null
          : {
              onChange: handleChange,
              icon: <EditOutlined style={{ fontSize: 14 }} />,
            }
      }
    >
      {value}
    </Typography.Title>
  ) : (
    <Input disabled={disabled} onBlur={(e) => handleChange(e.target.value)} />
  );
};

export default TitleInput;
