import React, { useContext, useEffect, useState } from "react";
import { FormOutlined, UploadOutlined, ReadOutlined } from "@ant-design/icons";

import { GetCreateTaskTemplate, GetCopyTask } from "../../../services/processService";

import { Tabs, Tooltip, Popover, Button, Spin } from "antd";

import TaskUpload from "./taskUpload";
import ProcessSelector from "../../common/processSelector";
import NewBatchTasksContainer from "./newBatchTasks";
import NewSingleTask from "./newSingleTask";
import OverviewDescription from "./../../overview/overviewDescription";
import { OverviewContext } from "../../../contexts/overviewContext";
import { QueueContext } from "./../../../contexts/queueContext";

import { useHttp } from "./../../../hooks/useHttp";

const NewTask = (props) => {
  const {
    style = {},
    setNewTaskWidth,
    setNewTaskPrompt,
    setShowNewTaskPrompt,
    getStateModal,
    dispatchModal,
  } = props;

  const [descriptionVisible, setDescriptionVisible] = useState(false);

  const [stateOverview, dispatchOverview] = useContext(OverviewContext);
  const { refreshQueue } = useContext(QueueContext);

  const handleDescriptionButton = (e) => {
    if (descriptionVisible) {
      e.stopPropagation();
      setDescriptionVisible(false);
    }
  };

  const newTaskState = getStateModal("NewTask") || {};

  const { activeProcess = null, copyOf = null, note = null } = newTaskState;

  const [
    { loading: loadingTemplate, data: taskTemplateNew = { keyFields: [], fieldValueList: [] } },
    executeGetTemplate,
  ] = useHttp(GetCreateTaskTemplate, { manual: true });

  const [
    {
      loading: loadingTemplateCopy,
      data: taskTemplateCopy = { keyFields: [], fieldValueList: [] },
    },
    executeGetTemplateCopy,
  ] = useHttp(GetCopyTask, { manual: true });

  useEffect(() => {
    if (copyOf) {
      executeGetTemplateCopy({ url: GetCopyTask(copyOf).url });
    }
  }, [copyOf]);

  useEffect(() => {
    if (activeProcess) {
      executeGetTemplate({ url: GetCreateTaskTemplate(activeProcess).url });
    }
  }, [activeProcess]);

  //chose which of the templates to use based on if there is a copy order
  const taskTemplate = copyOf ? taskTemplateCopy : taskTemplateNew;
  const fields = [...taskTemplate.fieldValueList, ...taskTemplate.keyFields].sort((a, b) =>
    a.sortKey > b.sortKey ? 1 : -1
  );

  const updateWhenNewTasks = () => {
    dispatchModal({
      type: "TOGGLE_VISIBILITY",
      visible: { NewTask: false },
    });
    refreshQueue();
    dispatchModal({
      type: "SET_STATE",
      payload: {
        NewTask: {
          activeProcess: null,
          copyOf: null,
          note: null,
        },
      },
    });
    dispatchOverview({ type: "FETCH_OVERVIEW" });
  };

  const handleChangeWidth = (activeKey) => {
    let newWidth = ["form", "upload"].includes(activeKey)
      ? 600
      : ["batch"].includes(activeKey)
      ? 1100
      : 600;
    setNewTaskWidth(newWidth);
  };

  useEffect(() => {
    return () => {
      setNewTaskWidth(600);
    };
  }, []);

  const sharedProps = {
    fields,
    updateWhenNewTasks,
    taskTemplate,
  };

  const disableMassCreate = !taskTemplateNew.keyFields.length > 0;

  return (
    <div style={{ ...style }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        <ProcessSelector
          showOnlyTasks={true}
          showHeaders={true}
          style={{ width: 552 }}
          filterKey="processHasTasks"
          onChange={(active) => {
            dispatchModal({
              type: "SET_STATE",
              payload: {
                NewTask: {
                  activeProcess: active ? active.processId : null,
                },
              },
            });
          }}
          value={activeProcess}
        />
        {activeProcess ? (
          <Popover
            placement="rightTop"
            visible={descriptionVisible}
            onVisibleChange={(newVisible) => newVisible && setDescriptionVisible(true)}
            trigger={["click"]}
            content={
              <OverviewDescription
                onClose={() => setDescriptionVisible(false)}
                style={{ width: 400 }}
                processId={activeProcess}
              />
            }
          >
            <Button
              style={{ marginLeft: 4 }}
              icon={<ReadOutlined />}
              onClick={handleDescriptionButton}
            />
          </Popover>
        ) : (
          <Button
            disabled={!activeProcess}
            style={{ marginLeft: 4 }}
            icon={<ReadOutlined />}
            onClick={handleDescriptionButton}
          />
        )}
      </div>
      <Spin spinning={loadingTemplate || loadingTemplateCopy}>
        <div style={{ minHeight: activeProcess ? 200 : 0 }}>
          {activeProcess && (
            <div style={{ marginTop: 20 }}>
              <Tabs defaultActiveKey="form" centered={true} onChange={handleChangeWidth}>
                <Tabs.TabPane
                  tab={
                    <span>
                      <FormOutlined />
                      Create a task
                    </span>
                  }
                  key="form"
                >
                  <NewSingleTask
                    getStateModal={getStateModal}
                    dispatchModal={dispatchModal}
                    {...sharedProps}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane
                  disabled={disableMassCreate}
                  tab={
                    <Tooltip
                      title={
                        disableMassCreate
                          ? "Mass create lets you create many tasks with different keys and the same data. This process does not have any keys"
                          : null
                      }
                    >
                      <span>
                        <FormOutlined />
                        Mass Create
                      </span>
                    </Tooltip>
                  }
                  key="batch"
                >
                  <NewBatchTasksContainer
                    {...sharedProps}
                    setNewTaskPrompt={setNewTaskPrompt}
                    setShowNewTaskPrompt={setShowNewTaskPrompt}
                    taskTemplateNew={taskTemplateNew}
                    loadingTemplate={loadingTemplate}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <span>
                      <UploadOutlined />
                      Spreadsheet
                    </span>
                  }
                  key="upload"
                >
                  <TaskUpload
                    {...sharedProps}
                    getStateModal={getStateModal}
                    dispatchModal={dispatchModal}
                  />
                </Tabs.TabPane>
              </Tabs>
            </div>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default NewTask;
