import React from "react";
import { Tag, Tooltip } from "antd";
import { getPrimaryPalette } from "./../../../utils/colorpalette";

const ErrorTag = (props) => {
  const { visible, reason, style = {} } = props;
  const palette = getPrimaryPalette();

  if (visible !== true) {
    return <span />;
  } else {
    return (
      <Tooltip placement="bottom" title={`Reason: ${reason}`}>
        <span>
          <Tag
            color={palette.red}
            id="errortag"
            onClick={(e) => e.stopPropagation()}
            style={{ ...style }}
          >
            Error
          </Tag>
        </span>
      </Tooltip>
    );
  }
};

export default ErrorTag;
