import React from "react";

const FullLogo = (props) => {
  const { color = "#08101b" } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 368.5 113.39">
      <defs>
        <clipPath id="clipPath">
          <rect width="368.5" height="113.39" fill="none" />
        </clipPath>
      </defs>
      <title>Logo</title>
      <g clipPath="url(#clipPath)">
        <path
          d="M62.91,82a23.42,23.42,0,0,1-5.38,2.16c-7.1,2-15.83,2.42-21.85-2,.08-.66.16-1.3.22-1.92,2.09-3.75,9.79-1.67,13.33-1.47,2.92.21,11.66-1.66,13.11.43l.21-.63q.16,1.69.36,3.39"
          fill={color}
        />
        <path d="M42.05,50.7c-.21-6.25,14.78-5.34,14.78-1.87S44.75,50.7,42.05,50.7" fill={color} />
        <path
          d="M50.12,34s.28,3.74.28,6.15-.28,6.14-.28,6.14-.58.12-.6,0-.28-3.74-.28-6.14.28-6.15.28-6.15.56-.12.6,0"
          fill={color}
        />
        <path d="M49.79,33.81c-3.54-.27-3.57-6.45.11-6,3.39.42,3.57,6.67-.11,6" fill={color} />
        <path
          d="M49.44,49.2c-14.78,0-26.76,7.3-26.76,16.3s12,16.3,26.76,16.3S76.2,74.5,76.2,65.5s-12-16.3-26.76-16.3m0,25.37c-10,0-18.12-4.64-18.12-10.36s8.11-10.37,18.12-10.37,18.12,4.64,18.12,10.37S59.45,74.57,49.44,74.57"
          fill={color}
        />
        <path
          d="M43.71,65.35s0-.25,0-.65c0-.1,0-.21,0-.33s0-.24-.06-.37a3.76,3.76,0,0,0-.2-.82c-.06-.14-.11-.28-.16-.43a4.23,4.23,0,0,0-.24-.4,3.48,3.48,0,0,0-.58-.69,3,3,0,0,0-1.52-.61,3.14,3.14,0,0,0-2.44.88c-.12.1-.24.2-.35.31s-.19.25-.28.37a3.54,3.54,0,0,0-.44.73,3.69,3.69,0,0,0-.18.33,2.33,2.33,0,0,0-.11.31l-.21.61c0,.12-.8,0-.79-.15,0,0,.05-.27.12-.72,0-.11,0-.23.07-.37l.14-.41a4.26,4.26,0,0,1,.41-.95l.3-.51c.11-.16.26-.32.39-.48a5.51,5.51,0,0,1,1-.86,4.47,4.47,0,0,1,2.5-.63,4,4,0,0,1,2.38,1.11,4.38,4.38,0,0,1,.75,1.1,4.87,4.87,0,0,1,.24.59c.05.19.09.39.14.57a4.3,4.3,0,0,1,.1,1c0,.15,0,.3,0,.44s0,.25,0,.37c-.06.44-.1.71-.1.71s-.75,0-.79-.09"
          fill={color}
        />
        <path
          d="M63,64.48l-.19-.62-.09-.31c0-.11-.11-.22-.17-.34a3.2,3.2,0,0,0-.41-.74l-.27-.37a3.34,3.34,0,0,0-.33-.32,3.43,3.43,0,0,0-.75-.51,3,3,0,0,0-1.63-.17,3.52,3.52,0,0,0-1.52.76,4.6,4.6,0,0,0-.59.73c-.08.14-.18.27-.25.41l-.17.42a3.57,3.57,0,0,0-.24.82,3.41,3.41,0,0,1-.08.37c0,.12,0,.23,0,.32,0,.41,0,.65,0,.65s-.77.21-.8.07,0-.27-.07-.72c0-.11,0-.24,0-.37l0-.44a3.86,3.86,0,0,1,.14-1c0-.18.09-.37.14-.57s.17-.37.26-.57a5.21,5.21,0,0,1,.71-1.09,4.5,4.5,0,0,1,2.24-1.29,4.06,4.06,0,0,1,2.59.43,4.12,4.12,0,0,1,1,.87,4.52,4.52,0,0,1,.4.49c.09.18.19.35.28.52a3.91,3.91,0,0,1,.37,1c.05.15.1.29.13.42s0,.26,0,.37c.06.45.09.72.09.72,0,.16-.71.24-.79.12"
          fill={color}
        />
        <path
          d="M111,81.8l-1.93-7.35H96.32l-2,7.35H82.69L94.21,46.34a5.67,5.67,0,0,1,5.39-3.91h10L122.57,81.8Zm-4.13-16-1.69-6.44c-.4-1.43-.87-3.28-1.44-5.55s-.93-3.9-1.11-4.88c-.16.91-.48,2.41-1,4.51s-1.53,6.21-3.15,12.36Z"
          fill={color}
        />
        <path
          d="M162.15,81.8h-13.9L133.93,54.18h-.24q.51,6.51.51,9.95V81.8h-9.39V42.59h13.84l14.27,27.25h.16c-.25-4-.37-7.13-.37-9.52V42.59h9.44Z"
          fill={color}
        />
        <polygon
          points="182.62 57.13 189.38 42.59 200.86 42.59 187.96 66.48 187.96 81.8 177.29 81.8 177.29 66.81 166.38 42.59 175.92 42.59 182.62 57.13"
          fill={color}
        />
        <path
          d="M220.18,81.8l-8-28.24h-.24q.57,7.21.57,11.21v17H203.1V48.26a5.67,5.67,0,0,1,5.67-5.67h8.44l8.18,27.84h.21l8-27.84h14.14V81.8H238V64.61c0-.9,0-1.89,0-3s.15-3.76.36-8h-.24L230.27,81.8Z"
          fill={color}
        />
        <path
          d="M278.26,81.8l-1.93-7.35H263.59l-2,7.35H250l12.79-39.37H276.9l13,39.37Zm-4.13-16-1.69-6.44q-.58-2.15-1.43-5.55c-.56-2.27-.94-3.9-1.11-4.88-.16.91-.48,2.41-1,4.51s-1.52,6.21-3.15,12.36Z"
          fill={color}
        />
        <polygon
          points="309.39 81.8 298.79 81.8 298.79 51.25 289.22 51.25 289.22 42.59 318.94 42.59 318.94 51.25 309.39 51.25 309.39 81.8"
          fill={color}
        />
        <polygon
          points="345.82 81.8 322.54 81.8 322.54 42.59 345.82 42.59 345.82 51.09 333.13 51.09 333.13 57.26 344.88 57.26 344.88 65.76 333.13 65.76 333.13 73.17 345.82 73.17 345.82 81.8"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default FullLogo;
