import React from "react";
import FormRowWrapper from "./../formRowWrapper";
import NumberComponent from "./../inputComponents/number/number";

const NumberInput = (props) => {
  const {
    onBlur,
    allowNulls,
    dataIndex,
    disabled,
    type = "default",
    autoFocus = false,
    isUnlockedForEditing = true,
    fieldMetaData,
    fieldRenderData,
  } = props;

  const { suffix = null, prefix = null } = fieldRenderData || {};
  const { min, max, integer } = fieldMetaData || {};

  const getMinMaxChangers = () => {
    let minMaxRules = {};
    if (min || min === 0) {
      minMaxRules.min = min;
    } else if (min || min === 0) {
      minMaxRules.min = min;
    }
    if (max || max === 0) {
      minMaxRules.max = max;
    } else if (max || max === 0) {
      minMaxRules.max = max;
    }

    return minMaxRules;
  };

  const getMinCheck = () => {
    if (min || min === 0) {
      const effectiveMin = min;
      return () => ({
        validator(rule, value) {
          if (value >= effectiveMin || (!value && value !== 0 && allowNulls)) {
            return Promise.resolve();
          }
          return Promise.reject(`Number should be at least ${effectiveMin}`);
        },
      });
    }
  };

  const getMaxCheck = () => {
    if (max || max === 0) {
      const effectiveMax = max;
      return () => ({
        validator(rule, value) {
          if (value <= effectiveMax || (!value && value !== 0 && allowNulls)) {
            return Promise.resolve();
          }
          return Promise.reject(`Number should be at most ${effectiveMax}`);
        },
      });
    }
  };

  const getRules = () => {
    let effectiveRules = [{ required: !allowNulls }];
    if (min || min === 0) effectiveRules.push(getMinCheck());
    if (max || max === 0) effectiveRules.push(getMaxCheck());
    return effectiveRules;
  };

  const getInputComponent = () => {
    switch (type) {
      case "default":
        return (
          <NumberComponent
            autoFocus={autoFocus}
            allowNulls={allowNulls}
            onlyInteger={integer}
            {...getMinMaxChangers()}
            disabled={disabled || !isUnlockedForEditing}
            onBlur={(e) => onBlur(e, dataIndex)}
            prefix={prefix}
            suffix={suffix}
          />
        );
    }
  };

  return (
    <FormRowWrapper {...props} effectiveRules={getRules()}>
      {getInputComponent()}
    </FormRowWrapper>
  );
};

export default NumberInput;
