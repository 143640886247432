import http from "./httpService";
import config from "./config.json";

export function GetUsageView() {
  return { url: `${config.apiUrl}/CustomerSettings/GetUsageView`, method: "GET" };
}

export function UpdateStripeSubscription() {
  return { url: `${config.apiUrl}/CustomerSettings/UpdateStripeSubscription`, method: "POST" };
}

export function UpdateSelectedQuantity(newQuantity) {
  return {
    url: `${config.apiUrl}/CustomerSettings/UpdateSelectedQuantity/${newQuantity}`,
    method: "POST",
  };
}

export function GetInvoices() {
  return { url: `${config.apiUrl}/CustomerSettings/GetInvoices`, method: "GET" };
}

export function GetUsageHistory() {
  return { url: `${config.apiUrl}/CustomerSettings/GetUsageHistory`, method: "GET" };
}

export function VatCheck() {
  return { url: `${config.apiUrl}/CustomerSettings/VatCheck`, method: "POST" };
}

export function GetCountrySelector() {
  return { url: `${config.apiUrl}/CustomerSettings/GetCountrySelector`, method: "GET" };
}

export function GetNewSubscriptionSession(requestObject) {
  return http.post(`${config.apiUrl}/CustomerSettings/GetNewSubscriptionSession`, requestObject);
}

export function ChangeSubscription() {
  return { url: `${config.apiUrl}/CustomerSettings/ChangeSubscription`, method: "POST" };
}

export function GetUpdatePaymentDetailsSession() {
  return http.get(`${config.apiUrl}/CustomerSettings/GetUpdatePaymentDetailsSession`);
}

export function UpdateCustomerInfo() {
  return { url: `${config.apiUrl}/CustomerSettings/UpdateCustomerInfo`, method: "POST" };
}

export function UpdateTaxInfo() {
  return { url: `${config.apiUrl}/CustomerSettings/UpdateTaxInfo`, method: "POST" };
}

export function GetCustomerSettingStatus() {
  return {
    url: `${config.apiUrl}/CustomerSettings/GetCustomerSettingStatus`,
    method: "GET",
  };
}

export function CancelSubscription() {
  return { url: `${config.apiUrl}/CustomerSettings/CancelSubscription`, method: "POST" };
}

export function ReactivateSubscription() {
  return { url: `${config.apiUrl}/CustomerSettings/ReactivateSubscription`, method: "POST" };
}
