import React, { useContext, Suspense } from "react";
import { withFeature } from "feature-toggle-jsx";
import { Modal, Spin } from "antd";
import { ProcessTeams } from "./../../../contexts/processTeams";
import { Utils } from "./../../../contexts/utils";

import SuspenseLoader from "./../../common/loaders/suspenseLoader";
import { getLazyComponent } from "./../../../utils/routerHelpers";

const LongSinceAgentContent = getLazyComponent(() => import("./longSinceAgentContent"));
const LongSinceDeveloperContent = getLazyComponent(() => import("./longSinceDeveloperContent"));

const IntroModal = (props) => {
  const { zIndex, visibleModal, dispatchModal } = props;

  const { myLists } = useContext(ProcessTeams);
  const { permittor, permissionsLoading } = useContext(Utils);

  const isDeveloper = permittor("DeveloperPermissions");

  const closeModal = () => {
    dispatchModal({
      type: "TOGGLE_VISIBILITY",
      visible: { LongSince: false },
    });
  };

  return (
    <Modal
      zIndex={zIndex}
      width={1000}
      style={{ top: 60 }}
      visible={visibleModal("LongSince")}
      onCancel={closeModal}
      footer={null}
      destroyOnClose={true}
      maskClosable={true}
    >
      <Spin spinning={permissionsLoading}>
        <Suspense fallback={<SuspenseLoader />}>
          {isDeveloper ? (
            <LongSinceDeveloperContent closeModal={closeModal} />
          ) : (
            <LongSinceAgentContent closeModal={closeModal} />
          )}
        </Suspense>
      </Spin>
    </Modal>
  );
};

export default withFeature(IntroModal, "introModal", (_) => _.isEnabled);
