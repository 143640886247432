import http from "./httpService";
import config from "./config.json";
import axios from "axios";
import { saveAs } from "file-saver";

//DEPRECATED
export function downloadSearchToExcel(searchString, keyId = null) {
  const key = !keyId || keyId === "all" ? "" : "/" + keyId;

  let url = `${config.apiUrl}/Excel/DownloadSearchToExcel${key}/${searchString}`;

  axios
    .get(url, { responseType: "blob" })
    .then((response) => {
      const fileNameHeader = "content-disposition";
      const headerFileName = response.headers[fileNameHeader];
      const res = headerFileName.split("=");
      const suggestedFileName = res[1].split(";")[0];
      const effectiveFileName =
        suggestedFileName === undefined
          ? "Spreadsheet.xls" //det her er en fallback... måske vi skal kalde den noget andet ;D
          : suggestedFileName;

      //SLAMKODE OVER!

      // Let the user save the file.
      saveAs(response.data, effectiveFileName);
    })
    .catch((response) => {
      console.error(
        "Could not Download the Excel report from the backend.",
        response
      );
    });
}

//Returns download of empty excel file with correct headers to create new tasks for the given process
export function GetHeaderSearch(keyAdd) {
  const key = !keyAdd || keyAdd.toLowerCase() === "all" ? "" : keyAdd;
  return { url: `${config.apiUrl}/Search/GetHeader/${key}`, method: "GET" };
}

export function GetResultsSearch(searchWord, keyAdd) {
  const key = !keyAdd || keyAdd.toLowerCase() === "all" ? "" : keyAdd;
  return {
    url: `${config.apiUrl}/Search/GetResults/${searchWord}/${key}`,
    method: "GET",
  };
}

export function getSearchLink(newSearchWord, newKey, taskId) {
  const staticUrl = `/tasks/search/`;
  const newKeyPush = !newKey ? "all" : newKey;
  const taskIdAdd = taskId ? `?taskId=${taskId}` : "";

  if (!newSearchWord && !newKey) {
    return staticUrl + taskIdAdd;
  } else if (!newSearchWord && newKeyPush) {
    return `${staticUrl}${newKeyPush}${taskIdAdd}`;
  } else if (newSearchWord) {
    return `${staticUrl}${newKeyPush}/${newSearchWord}${taskIdAdd}`;
  }
}
