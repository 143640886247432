import React, { useState, useRef } from "react";
import useSize from "@react-hook/size";
import { Table as TableAntd, ConfigProvider, Typography, Button, Modal } from "antd";
import { DeleteOutlined, SettingOutlined } from "@ant-design/icons";

//inputbar & NoteChanger shared with list
import InputBar from "../list/inputBar";
import NoteChanger from "./../list/noteChanger";
import TableColumnsBuilder from "./tableColumnsBuilder";
import { getGrid } from "./../../../../../utils/calc";

const Table = (props) => {
  const {
    value,
    onChange,
    disabled,
    canAddColums = false,
    canDeleteColumns = false,
    fieldMetaData,
    handleMetaField,
    dataIndex,
  } = props;
  const target = useRef(null);
  const [tableSize, _] = useSize(target);
  const { columns = [] } = fieldMetaData || {};

  const showColumnEdit = canAddColums || canDeleteColumns;

  const rowsWithIndex = value ? value.map((row, index) => ({ ...row, index })) : [];

  const [showEditColumn, setShowEditColumn] = useState(false);

  const changeInRowValue = (changedValue, column, index) => {
    const effectiveValue = value ? [...value] : [];
    effectiveValue[index][column] = changedValue.trim();
    onChange(effectiveValue);
  };

  const removeRow = (rowIndex) => {
    const oldValue = value ? [...value] : [];
    const effectiveValue = oldValue.filter((item, index) => index !== rowIndex);
    onChange(effectiveValue);
  };

  const addRows = ({ rows }) => {
    const effectiveValue = value ? [...value] : [];
    const effectiveNewRows = [...effectiveValue, ...rows];
    onChange(effectiveNewRows);
  };

  const [normalWidth, lastWidth, buttonWidth] = getGrid(tableSize, columns.length, 40);

  const onMetaFieldChange = (dataIndex, changes) => {
    let payload = { ...fieldMetaData, ...changes };
    handleMetaField(dataIndex, payload);
  };

  return (
    <ConfigProvider
      renderEmpty={() => (
        <div style={{ textAlign: "center", margin: 30 }}>
          empty table{" "}
          {(!columns || (columns && columns.length === 0)) && (
            <span>
              with no columns
              <br />
              {showColumnEdit && (
                <Button type="primary" onClick={() => setShowEditColumn(true)}>
                  Add columns
                </Button>
              )}
            </span>
          )}
        </div>
      )}
    >
      <div style={{ width: "100%" }} ref={target}>
        <div
          style={{
            backgroundColor: "#fff",
            marginBottom: 4,
            maxHeight: 500,
            overflowY: "auto",
          }}
        >
          <TableAntd
            dataSource={rowsWithIndex}
            pagination={false}
            style={{ minHeight: 106 }}
            rowKey={(record) => {
              return (record.index + 1).toString();
            }}
          >
            {columns.map((column, i) => {
              return (
                <TableAntd.Column
                  width={normalWidth}
                  dataIndex={column.column}
                  key={column.column}
                  render={(text, record, index) => {
                    return (
                      <div
                        style={{
                          padding: "0px 10px",
                          paddingTop: 6,
                          minWidth: 100,
                        }}
                      >
                        <NoteChanger
                          disabled={disabled}
                          onChange={(note) => changeInRowValue(note, column.column, index)}
                          showEdits={!text}
                          title={column.column}
                        >
                          {text}
                        </NoteChanger>
                      </div>
                    );
                  }}
                  title={<Typography.Text strong>{column.column}</Typography.Text>}
                ></TableAntd.Column>
              );
            })}
            <TableAntd.Column
              dataIndex="DELETEROW"
              width={60}
              key="DELETEROW"
              title={
                showColumnEdit ? (
                  <Button
                    type="text"
                    style={{ margin: "4px 0px 4px 0px" }}
                    size="small"
                    onClick={() => setShowEditColumn(true)}
                  >
                    <SettingOutlined />
                  </Button>
                ) : (
                  <span />
                )
              }
              align="right"
              render={(text, record, index) =>
                !disabled ? (
                  <DeleteOutlined onClick={() => removeRow(index)} />
                ) : (
                  <div style={{ width: 14 }}></div>
                )
              }
            />
          </TableAntd>
        </div>
        {!disabled && (
          <InputBar
            width={tableSize}
            showTitle={true}
            columns={columns}
            disabled={disabled}
            style={{ marginTop: 8 }}
            dispatch={addRows}
          />
        )}

        <Modal
          title="Table Settings"
          destroyOnClose={true}
          visible={showEditColumn}
          onCancel={() => setShowEditColumn(false)}
          footer={null}
          width={700}
          closeIcon={<span />}
        >
          <TableColumnsBuilder
            style={{ margin: 24 }}
            dataIndex={dataIndex}
            columns={columns}
            handleMetaField={onMetaFieldChange}
            okButton={{ text: "Done", onClick: () => setShowEditColumn(false) }}
          />
        </Modal>
      </div>
    </ConfigProvider>
  );
};

export default Table;
