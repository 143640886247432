import React, { memo } from "react";
import { Tag } from "antd";
import { queueTypes } from "./../../utils/queueTypes";
const TaskQueueTag = (props) => {
  const { typeObject, queue, reason } = props;

  const getColor = () => {
    if (typeObject.color === "inherit") {
      const parentQueue = queueTypes.find((queueFromTypes) => queueFromTypes.key === queue);

      return parentQueue ? parentQueue.color : null;
    } else return typeObject.color;
  };

  return (
    <Tag color={getColor()} style={{ margin: 0 }} onClick={(e) => e.stopPropagation()}>
      {typeObject.text}
    </Tag>
  );
};

const areEqual = (pre, post) => {
  if (
    pre.queue !== post.queue ||
    pre.reason !== post.reason ||
    pre.typeObject.key !== post.typeObject.key
  ) {
    return false;
  }
  return true;
};

export default memo(TaskQueueTag, areEqual);
