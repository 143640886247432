import React from "react";
import ReactDOM from "react-dom";
import AuthLoader from "./AuthLoader";

//import registerServiceWorker from "./registerServiceWorker";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

ReactDOM.render(<AuthLoader basename={baseUrl} />, rootElement);
//registerServiceWorker();
